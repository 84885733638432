import React from 'react';
import { Controller } from 'react-hook-form';
import { Input } from '@chakra-ui/react';
import propTypes from 'prop-types';

import { inputStyle } from 'theme/commonStyles';

import CustomInput from '../main/CustomInput';

const DateInput = ({
  control, def, name, place, required, rules,
}) => (
  <Controller
    control={control}
    defaultValue={def}
    name={name}
    rules={rules}
    render={({ field }) => (
      <CustomInput label={place} required={required}>
        <Input placeholder={place} {...inputStyle} {...field} type="date" value={field.value || def} />
      </CustomInput>
    )}
  />

);

DateInput.defaultProps = {
  required: true,
  def: '',
  rules: null,
};

DateInput.propTypes = {
  control: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  place: propTypes.string.isRequired,
  def: propTypes.string,
  required: propTypes.bool,
  rules: propTypes.shape({}),
};

export default DateInput;
