import React from 'react';
import propTypes from 'prop-types';
import styles from './ErrorScreen.module.css';

const ErrorScreen = ({ error }) => (
  <div className={styles.error}>
    <h3>We are sorry... something went wrong</h3>
    <p>We cannot process your request at this moment.</p>
    <p>
      ERROR:
      {error.message}
    </p>
  </div>
);

ErrorScreen.propTypes = {
  error: propTypes.shape({
    message: propTypes.string.isRequired,
  }).isRequired,
};

export default ErrorScreen;
