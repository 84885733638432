import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_PROD}/user/`,
});

const login = async (data) => {
  try {
    const response = await api.post('/login', data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const signup = async (data) => {
  try {
    const response = await api.post('/', data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const deleteUser = async (idUser) => {
  try {
    const token = window.localStorage.getItem('userAuth');
    const response = await api.delete(`/${idUser}`, {
      headers: {
        Authorization: `token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const getLoggedUser = async (token) => {
  try {
    const response = await api.get('/perfil/',
      { headers: { authorization: token } });
    return response.data.user;
  } catch (error) {
    return error.response;
  }
};

export {
  login,
  signup,
  deleteUser,
  getLoggedUser,
};
