import React from 'react';
import { useDispatch } from 'react-redux';
import { uiOpenModal } from 'actions/ui';

const UpdateEventFab = () => {
  const dispatch = useDispatch();

  const handleUpdate = () => {
    dispatch(uiOpenModal());
  };

  return (
    <button className="btn btn-danger fabi-update" onClick={handleUpdate} type="button">
      <i className="fas fa-marker" />
      <span> Actualizar / Ver</span>
    </button>
  );
};

export default UpdateEventFab;
