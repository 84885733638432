/* eslint-disable import/prefer-default-export */
const getSetValues = (sets, reps) => Array.from(Array(sets)).map((_, idx) => (
  {
    set_number: idx + 1,
    load: 0,
    typeWeight: 'kg',
    reps,
  }
));
const getListValues = (propValue, propName, sub, sets, reps) => ({
  [propName]: propValue,
  sets: getSetValues(sets, reps),
  // sub: propValue.split('-')[1]?.split('*')[0],
  sub,
});

const getTemplateModel = (data) => data.reduce((total, curr) => {
  const finalObj = curr.days.map((day) => ({
    week: curr.number,
    day: day.number,
    categoryList: day.categoryList
      .map(({
        id, sets, reps, sub,
      }) => getListValues(id, 'category', sub, +sets, +reps)),
  }));
  return total.concat(finalObj);
}, []);

const getPreprogrammedModel = (data) => data.reduce((total, curr) => {
  const finalObj = curr.days.map((day) => ({
    week: curr.number,
    day: day.number,
    exercises: day.exercises
      .map(({ sets, reps, id }) => getListValues(id, 'id_exercise', 'All', +sets, +reps)),
  }));
  return total.concat(finalObj);
}, []);

export const getSession = (data, mode) => (mode === 'template'
  ? getTemplateModel(data)
  : getPreprogrammedModel(data));
