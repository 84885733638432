import React from 'react';
import propTypes from 'prop-types';
import { FormControl, FormLabel } from '@chakra-ui/react';

const CustomInput = ({ label, children, required }) => (
  <FormControl isRequired={required}>
    <FormLabel visibility={['hidden', 'visible']} height={[0, 'auto']}>{label}</FormLabel>
    {
      children
    }
  </FormControl>
);

CustomInput.defaultProps = {
  required: false,
};
CustomInput.propTypes = {
  label: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
  required: propTypes.bool,
};
export default CustomInput;
