/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import {
  VStack, Heading, Text, Badge, Button, IconButton,
} from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons';
import { useForm } from 'react-hook-form';
import propTypes from 'prop-types';

import TextInput from 'components/common/TextInput';
import { updateEvaluation, isDominant } from './evaluationReducer';

const updateData = (oldInfo, addInfo) => {
  const newInfo = {
    ...oldInfo,
    dominant_leg: isDominant(oldInfo),
    notes: oldInfo.notes.map((note, id) => ({ ...note, content: [addInfo[`content-${id}`]] })),
    succesfulMovements: {
      leftLeg: +addInfo.leftLeg,
      rightLeg: +addInfo.rightLeg,
    },
  };
  console.log('updatedData', newInfo);
  return newInfo;
};

const isEvaluationWithLeg = (exercise) => {
  const exercisesWithoutLeg = ['Push Up', 'Recline Pull', 'Rotation withour Pivot'];
  return !exercisesWithoutLeg.includes(exercise);
};

const ReportCard = ({
  evaluation, dispatch, lang, t,
}) => {
  const [edit, setEdit] = useState(false);
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    console.log(evaluation);
    console.log(data);
    const dataToSend = updateData(evaluation, data);
    console.log(dataToSend);
    dispatch(updateEvaluation(dataToSend, evaluation.exercise));
    setEdit(false);
  };
  return (
    <VStack
      border="2px solid #d9ad42"
      borderRadius="5px"
      mb="0.5rem"
      p="1rem 0.5rem"
      w={['100%', undefined, '45%']}
      position="relative"
      as={edit ? 'form' : 'section'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <IconButton
        aria-label="edit"
        color="ptRed.500"
        fontSize="20px"
        icon={edit ? <CloseIcon /> : <EditIcon />}
        onClick={() => setEdit(!edit)}
        position="absolute"
        right="10px"
        top="10px"
      />
      <Heading isTruncated size="md">{evaluation.exercise}</Heading>
      {
        isEvaluationWithLeg(evaluation.exercise) && (
          <>
            <Text>
              {t('dominant_leg')}
              {' '}
              <Badge colorScheme="yellow">{evaluation.dominant_leg}</Badge>
            </Text>
            <Text>
              {t('succesful_movement_in_left')}
              {' '}
              {
                edit
                  ? (
                    <TextInput
                      control={control}
                      name="leftLeg"
                      def={evaluation.succesfulMovements.leftLeg}
                      required={false}
                    />
                  )
                  : evaluation.succesfulMovements.leftLeg
              }
            </Text>
            <Text>
              {t('succesful_movement_in_right')}
              {' '}
              {
                edit
                  ? (
                    <TextInput
                      control={control}
                      name="rightLeg"
                      def={evaluation.succesfulMovements.rightLeg}
                      required={false}
                    />
                  )
                  : evaluation.succesfulMovements.rightLeg
              }
            </Text>
          </>
        )
      }
      <Heading size="md">{t('notes')}</Heading>
      {
        evaluation.notes.map((note) => (
          <Text size="md">{note.defaultMsg[lang]}</Text>
        ))
      }
      {
        Object.values(evaluation.generalNote).some((note) => note !== '') && (
          <>
            <Heading size="md">General Notes</Heading>
            {
              Object.values(evaluation.generalNote).map((note) => (note !== '' ? <Text>{note}</Text> : null))
            }
          </>
        )
      }
      {
        edit && <Button type="submit" colorScheme="ptRed"> Save Edit</Button>
      }
    </VStack>
  );
};

ReportCard.propTypes = {
  lang: propTypes.string.isRequired,
  t: propTypes.func.isRequired,
  evaluation: propTypes.shape({
    notes: propTypes.shape([]).isRequired,
    exercise: propTypes.string.isRequired,
    dominant_leg: propTypes.string.isRequired,
    generalNote: propTypes.shape([]).isRequired,
    succesfulMovements: propTypes.shape({
      leftLeg: propTypes.number.isRequired,
      rightLeg: propTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  dispatch: propTypes.func.isRequired,
};

export default ReportCard;
