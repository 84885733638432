/* eslint-disable no-unused-vars */
// const columns = [
//   {
//     Header: 'Category',
//     accessor: 'category',
//   },
//   {
//     Header: 'Sets',
//     accessor: 'sets',
//   },
//   {
//     Header: 'Reps',
//     accessor: 'reps',
//   },
//   {
//     Header: 'Option',
//     accessor: 'opt',
//   },
// ];

const columns = [
  {
    Header: { en: 'Category', es: 'Categoria' },
    accessor: 'categoryName',
    maxWidth: 200,
    minWidth: 10,
  },
  {
    Header: { en: 'Sub', es: 'Sub' },
    accessor: 'sub',
    maxWidth: 150,
    minWidth: 50,
  },
  {
    Header: { en: 'Sets', es: 'Sets' },
    accessor: 'sets',
    maxWidth: 100,
    minWidth: 50,
  },
  {
    Header: { en: 'Reps', es: 'Reps' },
    accessor: 'reps',
    maxWidth: 100,
    minWidth: 50,
  },
  {
    Header: { en: 'Option', es: 'Opción' },
    accessor: 'opt',
    maxWidth: 100,
    minWidth: 50,
  },
  {
    Header: { en: 'id', es: 'id' },
    accessor: 'id',
    maxWidth: 100,
    minWidth: 50,
  },
];

const data = [
  {
    categoryName: { en: 'Choose category', es: 'Elegir categoria' },
    sub: 'All',
    sets: '1',
    reps: '1',
    opt: 'delete',
    id: '',
  },
];

const initialState = {
  columns,
  data,
};

// const getRows = (row) => (row.categoryList.map((item) => ({
//   category: `${item.category.name}-${item.sub}*${item.category._id}`,
//   sets: String(item.sets),
//   reps: String(item.reps),
//   opt: 'delete',
//   id: item.category._id,
// })));

const getRows = (row) => (row.categoryList.map((item) => ({
  categoryName: item.category.name,
  sub: item.sub,
  sets: String(item.sets.length),
  reps: String(item.sets[0].reps),
  opt: 'delete',
  id: item.category._id,
})));

const getTemplateModel = (template) => ({
  columns,
  data: getRows(template),
});

const templateCategoryReducer = (state, action) => {
  const {
    type, rowIdx, columnIdx, value, exerciseIdx, template,
  } = action;
  switch (type) {
    case 'GET_TEMPLATE': return getTemplateModel(template);
    case 'ADD_ROW': return {
      ...state,
      data: [
        ...state.data, {
          ...data[0],
          // category: 'Choose Category',
          // sets: '1',
          // reps: '1',
          // opt: 'delete',
          // id: undefined,
        },
      ],
    };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: state.data.map((row, index) => {
          if (index === rowIdx) {
            return {
              ...state.data[rowIdx],
              // [columnIdx]: exerciseIdx ? `${value}*${exerciseIdx}` : value,
              [columnIdx]: value,
            };
          }
          return row;
        }),
      };
    case 'DELETE_ROW':
      return {
        ...state,
        data: state.data.filter((row, index) => index !== +value),
      };
    default: return state;
  }
};

export default templateCategoryReducer;

const addRow = () => ({ type: 'ADD_ROW' });
const deleteRow = (value) => ({ type: 'DELETE_ROW', value });

const updateData = (value, rowIdx, columnIdx, exerciseIdx) => ({
  type: 'UPDATE_DATA', value, rowIdx, columnIdx, exerciseIdx,
});

const makeTemplate = (template) => ({ type: 'GET_TEMPLATE', template });

export {
  initialState,
  addRow,
  updateData,
  deleteRow,
  makeTemplate,
};
