import React from 'react';
import { string, shape } from 'prop-types';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Template from '../Template';
import Preprogrammed from '../Preprogrammed';
import Custom from '../Custom';

const RoutineDisplay = ({
  workout, data, preSelected, actual,
}) => (
  <ErrorBoundary>
    {
      data.map((week, weekIdx) => (
        <>
          {
            week.days.map((day, dayIdx) => (
              <>
                {
                  workout === 'template' && (
                    <Template
                      key={day.number}
                      actual={actual}
                      date={{ day: day.number, week: week.number }}
                      data={preSelected[weekIdx]?.days[dayIdx]}
                    />
                  )
                }
                {
                  workout === 'preprogrammed' && (
                    <Preprogrammed
                      key={day.number}
                      actual={actual}
                      date={{ day: day.number, week: week.number }}
                      data={preSelected[weekIdx]?.days[dayIdx]}
                    />
                  )
                }
                {
                  workout === 'custom' && (
                    <Custom
                      key={day.number}
                      date={{ day: day.number, week: week.number }}
                      actual={actual}
                      data={day.exercises}
                      preData={preSelected[weekIdx]?.days[dayIdx]}
                    />
                  )
                }
              </>
            ))
          }
        </>
      ))
    }
  </ErrorBoundary>
);

RoutineDisplay.propTypes = {
  workout: string.isRequired,
  data: shape([]).isRequired,
  preSelected: shape([]).isRequired,
  actual: shape({}).isRequired,
};

export default RoutineDisplay;
