const columns = [
  {
    Header: { en: 'Exercise', es: 'Ejercicio' },
    accessor: 'exercise',
  },
  {
    Header: { en: 'Set 1', es: 'Set 1' },
    accessor: 'set1',
    width: 10,
  },
  {
    Header: { en: 'Set 2', es: 'Set 2' },
    accessor: 'set2',
  },
  {
    Header: { en: 'Set 3', es: 'Set 3' },
    accessor: 'set3',
  },
  {
    Header: { en: 'Set 4', es: 'Set 4' },
    accessor: 'set4',
  },
  {
    Header: { en: 'Set 5', es: 'Set 5' },
    accessor: 'set5',
  },
  {
    Header: { en: 'Option', es: 'Opción' },
    accessor: 'opt',
  },
];

const data = [
  {
    exercise: { en: 'Add exercise', es: 'Agregar Ejercicio' },
    set1: { en: 'load/reps', es: 'carga/reps' },
    set2: { en: 'load/reps', es: 'carga/reps' },
    set3: { en: 'load/reps', es: 'carga/reps' },
    set4: { en: 'load/reps', es: 'carga/reps' },
    set5: { en: 'load/reps', es: 'carga/reps' },
    opt: { en: 'delete', es: 'eliminar' },
  },
];

const initialState = {
  columns,
  data,
};

const getInitialState = (newData) => {
  console.log('entering get init', newData);
  return ({
    columns,
    data: newData,
  });
};
const getRows = (rows, lang) => (rows.map((row) => ({
  exercise: `${row.id_exercise.name[lang]}*${row.id_exercise._id}`,
  set1: `${row.sets[0].load}${row.sets[0].typeWeight}/${row.sets[0].reps}`,
  set2: `${row.sets[1].load}${row.sets[1].typeWeight}/${row.sets[1].reps}`,
  set3: `${row.sets[2].load}${row.sets[2].typeWeight}/${row.sets[2].reps}`,
  set4: `${row.sets[3].load}${row.sets[3].typeWeight}/${row.sets[3].reps}`,
  set5: `${row.sets[4].load}${row.sets[4].typeWeight}/${row.sets[4].reps}`,
  opt: 'delete',
})));

const getCustomModel = (custom, lang) => {
  console.log('enterin getCustom', custom);
  return ({
    columns,
    data: getRows(custom, lang),
  });
};

const customReducer = (state, action) => {
  const {
    type, rowIdx, columnIdx, value, exerciseIdx,
  } = action;
  switch (type) {
    case 'INIT_CUSTOM': return getInitialState(action.newData);
    case 'ADD_ROW': return {
      ...state,
      data: [
        ...state.data, {
          exercise: 'Add exercise',
          set1: 'load/reps',
          set2: 'load/reps',
          set3: 'load/reps',
          set4: 'load/reps',
          set5: 'load/reps',
          opt: 'delete',
        },
      ],
    };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: state.data.map((row, index) => {
          if (index === rowIdx) {
            return {
              ...state.data[rowIdx],
              [columnIdx]: exerciseIdx ? `${value}*${exerciseIdx}` : value,
            };
          }
          return row;
        }),
      };
    case 'DELETE_ROW':
      console.log(state.data.filter((row, index) => ((index !== +value) ? row : undefined)));
      return {
        ...state,
        data: state.data.filter((row, index) => index !== +value),
      };
    case 'GET_PRE_CUSTOM': return getCustomModel(action.obj, action.lang);
    default: return state;
  }
};

const addRow = () => ({ type: 'ADD_ROW' });
const deleteRow = (value) => ({ type: 'DELETE_ROW', value });
const makeCustom = (obj, lang) => ({ type: 'GET_PRE_CUSTOM', obj, lang });
const setInitial = (newData) => ({ type: 'INIT_CUSTOM', newData });

const updateData = (value, rowIdx, columnIdx, exerciseIdx) => ({
  type: 'UPDATE_DATA', value, rowIdx, columnIdx, exerciseIdx,
});

export default customReducer;

export {
  initialState,
  addRow,
  updateData,
  deleteRow,
  makeCustom,
  setInitial,
};
