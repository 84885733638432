import React from 'react';
import {
  Avatar,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import useWindowSize from 'hooks/useWindowSize';

import styles from './Menu.module.scss';

const Menu = ({ onClose, isOpen }) => {
  const { role, pic } = useSelector(({ user }) => user);
  const size = useWindowSize();
  const { t } = useTranslation();
  return (
    <Drawer isOpen={isOpen} placement={size.width < 600 ? 'top' : 'left'} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton className={styles.close} />
        <DrawerHeader className={styles.header}>
          <Avatar size="md" src={pic} mr="0.5rem" />
          Menu
        </DrawerHeader>
        <DrawerBody className={styles.body} p="0" overflow="none">
          <Link to="/user/clients" className={styles.options} onClick={onClose}>{t('clients')}</Link>
          {role === 'admin' && (
            <>
              <Link to="/user/trainers" className={styles.options} onClick={onClose}>{t('trainers')}</Link>
              <Link to="/user/create" className={styles.options} onClick={onClose}>{t('add_program')}</Link>
              <Link to="/user/programs" className={styles.options} onClick={onClose}>{t('program_list')}</Link>
            </>
          )}

          <Link to="/user/exercises" className={styles.options} onClick={onClose}>{t('exercisesDB')}</Link>
          <Link to="/user/evaluation" className={styles.options} onClick={onClose}>{t('evaluation')}</Link>
          {role === 'trainer' && (
            <>
              <Link to="/user/routines" className={styles.options} onClick={onClose}>{t('routines')}</Link>
              <Link to="/user/customer" className={styles.options} onClick={onClose}>{t('services')}</Link>
              <div className={styles.options}>
                {t('planning')}
                <ul>
                  <Link to="/user/planning/month" onClick={onClose} className={styles.sub}>{t('planning_month')}</Link>
                  <Link to="/user/planning/week" onClick={onClose} className={styles.sub}>{t('planning_week')}</Link>
                  <Link to="/user/planning/day" onClick={onClose} className={styles.sub}>{t('planning_day')}</Link>
                  <Link to="/user/planning" onClick={onClose} className={styles.sub}>{t('planning_general')}</Link>
                </ul>
              </div>
              <Link to="/user/feedback" onClick={onClose} className={styles.options}>Feedback</Link>
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

Menu.propTypes = {
  onClose: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
};
export default Menu;
