/* eslint-disable max-len */
import React from 'react';
import propTypes from 'prop-types';
import {
  ButtonGroup, Button, Text, Flex,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import useIteration from 'hooks/useIteration';
import { useTranslation } from 'react-i18next';

const Paginate = ({
  list, Component, regex, extraFeatures,
}) => {
  const [paginatedList, next, previous, steps] = useIteration(list);
  const { t } = useTranslation();

  if (!list.length) return (<p>Sorry there&apos;s no item to display</p>);
  return (
    <Flex direction="column" alignItems="center" w="100%" as="section">
      <Component
        list={paginatedList.filter((template) => regex.test(template.name))}
        {...extraFeatures}
      />
      <ButtonGroup mt="0.5rem" colorScheme="ptRed" display="flex" alignItems="center" size="xs">
        <Button onClick={previous} isDisabled={steps.start <= 0}>
          <ArrowBackIcon />
        </Button>
        <Text>{t('show_pagination', { start: steps.start, end: steps.end, total: list.length })}</Text>
        <Button onClick={next} isDisabled={steps.end >= list.length}>
          <ArrowForwardIcon />
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

Paginate.defaultProps = {
  regex: /(?:)/gi,
  extraFeatures: {},
};

Paginate.propTypes = {
  list: propTypes.shape([]).isRequired,
  Component: propTypes.func.isRequired,
  extraFeatures: propTypes.shape({}),
  regex: propTypes.string,
};

export default Paginate;
