/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';
import Swal from 'sweetalert2';
import { Input, Textarea, InputGroup } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { uiCloseModal } from '../../actions/ui';
import {
  eventClearActiveEvent,
  eventStartAddNew,
  eventStartUpdate,
} from '../../actions/events';

const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
  },
};

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

const now = moment().minutes(0).seconds(0).add(1, 'hours');
// const nowEnd = moment(now).add(1, 'hours');
const nowPlus1 = now.clone().add(1, 'hours');

const initEvent = {
  title: '',
  notes: '',
  start: now.toDate(),
  end: nowPlus1.toDate(),
};
const inputStyle = {
  backgroundColor: '#ececec',
  border: '1px solid #d6d6d6',
  borderRadius: '5px',
  color: '#444',
  fontWeight: 400,
  focusBorderColor: '#b42519',
  letterSpacing: '1px',
  marginBottom: '1rem',
  padding: '0.5rem',
  width: '100%',
  transition: 'border 2s cubic-bezier(0.075, 0.82, 0.165, 1)',
};
const CalendarModal = ({ category = 'all' }) => {
  const { modalOpen } = useSelector((state) => state.ui);
  const { activeEvent, eventSetSlotEmpty } = useSelector((state) => state.calendar);
  const dispatch = useDispatch();

  const [dateStart, setDateStart] = useState(now.toDate());
  const [dateEnd, setDateEnd] = useState(nowPlus1.toDate());
  const [titleValid, setTitleValid] = useState(true);

  const [formValues, setFormValues] = useState(initEvent);

  const {
    notes, title, start, end,
  } = formValues;

  useEffect(() => {
    if (activeEvent) {
      setFormValues(activeEvent);
    } else {
      let initOtherDate = null;
      if (eventSetSlotEmpty) {
        initOtherDate = {
          ...initEvent,
          start: eventSetSlotEmpty.start,
          end: eventSetSlotEmpty.end,
        };
      }
      setFormValues(initOtherDate || initEvent);
    }
  }, [activeEvent, setFormValues, eventSetSlotEmpty, setDateStart, setDateEnd]);

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const closeModal = () => {
    // todo : cerrar el modal
    dispatch(uiCloseModal());
    dispatch(eventClearActiveEvent());
    setFormValues(initEvent);
  };

  const handleStartDateChange = (e) => {
    setDateStart(e);
    setFormValues({
      ...formValues,
      start: e,
    });
  };

  const handleEndDateChange = (e) => {
    setDateEnd(e);
    setFormValues({
      ...formValues,
      end: e,
    });
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();

    const momentStart = moment(start);
    const momentEnd = moment(end);

    if (momentStart.isSameOrAfter(momentEnd)) {
      return Swal.fire(
        'Error',
        'La fecha fin debe de ser mayor a la fecha de inicio',
        'error',
      );
    }
    if (title.trim().length < 2) {
      return setTitleValid(false);
    }
    const formValuesCat = {
      ...formValues,
      category: category !== 'all' ? category : '',
    };
    if (activeEvent) {
      dispatch(eventStartUpdate(category !== 'all' ? formValuesCat : formValues));
    } else {
      dispatch(eventStartAddNew(category !== 'all' ? formValuesCat : formValues));
    }

    setTitleValid(true);
    closeModal();
  };
  return (
    <Modal
      isOpen={modalOpen}
      //   onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      closeTimeoutMS={200}
      className="modal"
      overlayClassName="modal-fondo"
      ariaHideApp={!process.env.NODE_ENV === 'test'}
    >
      <h1>{activeEvent ? 'Editar Evento' : 'Nuevo evento'}</h1>
      <hr />
      <form onSubmit={handleSubmitForm} className="container">
        <div className="chakra-form-control css-1kxonj9">
          <label className="chakra-form__label css-1dlt96s">
            Fecha y hora inicio
          </label>
          <DateTimePicker
            onChange={handleStartDateChange}
            value={start && start.length !== 0 ? start : dateStart}
            className="css-15by6gf"
          />
        </div>

        <div className="chakra-form-control css-1kxonj9">
          <label className="chakra-form__label css-1dlt96s">Fecha y hora fin</label>
          <DateTimePicker
            onChange={handleEndDateChange}
            value={end && end.length !== 0 ? end : dateEnd}
            minDate={start && start.length !== 0 ? start : dateStart}
            className="css-15by6gf"
          />
        </div>

        <hr />
        <label className="chakra-form__label css-1dlt96s">Titulo y notas</label>
        <InputGroup size="md">
          <Input
            type="text"
            className={`chakra-input css-15by6gf ${!titleValid && 'is-invalid'}`}
            {...inputStyle}
            placeholder="Título del evento"
            name="title"
            autoComplete="off"
            value={title}
            onChange={handleInputChange}
          />
        </InputGroup>
        <InputGroup size="md">
          <Textarea
            type="text"
            className="chakra-textarea css-1vbcmny"
            placeholder="Notas"
            rows="5"
            name="notes"
            value={notes}
            onChange={handleInputChange}
          />
        </InputGroup>
        <button type="submit" className="btn btn-outline-primary btn-block">
          <i className="far fa-save" />
          <span> Guardar</span>
        </button>
      </form>
    </Modal>
  );
};
CalendarModal.defaultProps = {
  category: 'all',
};
CalendarModal.propTypes = {
  category: propTypes.string,
};
export default CalendarModal;
