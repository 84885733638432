/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
const initialWeekState = [
  {
    number: 1,
    days: [
      {
        number: 1,
        exercises: [],
        categoryList: [],
        notes: '',
      },
    ],
  },
];

// utils
const generateNewWeek = (state) => {
  const newWeek = {
    number: state.length + 1,
    days: [
      {
        number: 1,
        exercises: [],
        categoryList: [],
      },
    ],
  };
  return state.concat(newWeek);
};

const deleteWeekFromState = (state, weekIdx) => state.filter((week) => week.number !== weekIdx);

const generateNewDay = (state, weekId) => {
  const weekSelected = state.find((week) => week.number === weekId);
  const newDay = {
    number: weekSelected.days.length + 1,
    exercises: [],
    categoryList: [],
    notes: '',
  };
  const oldArr = weekSelected.days.concat(newDay);

  const newState = ({
    number: weekSelected.number,
    days: Array.from(new Set(oldArr.map(JSON.stringify))).map(JSON.parse),
  });

  const stateFiltered = state.filter((week) => week.number !== weekId);
  return [...stateFiltered, newState].sort((a, b) => a.number - b.number);
};

const deleteDayFromState = (state, weekIdx, dayIdx) => {
  const weekSelected = state.find((week) => week.number === weekIdx);
  const weekWithLess = ({
    number: weekSelected.number,
    days: weekSelected.days
      .filter((day) => day.number !== dayIdx)
      .map((day, idx) => ({ number: idx + 1, exercises: day.exercises })),
  });
  console.log('week less', weekWithLess);
  const weekStateFiltered = state.filter((week) => week.number !== weekIdx);
  console.log([...weekStateFiltered, weekWithLess]);
  return [...weekStateFiltered, weekWithLess].sort((a, b) => a.number - b.number);
};

const updateDayFromState = (state, newData, name, weekIdx, dayIdx) => {
  const weekSelected = state.find((week) => week.number === weekIdx);
  console.log('week', weekSelected);
  const daySelected = weekSelected.days.find((day) => day.number === dayIdx);
  console.log('day', daySelected);

  const newDayObj = {
    ...daySelected,
    [name]: newData,
  };

  const stateUpdated = state
    .map((week) => ((week.number === weekIdx)
      ? ({ ...week, days: week.days.map((day) => (day.number === dayIdx ? newDayObj : day)) })
      : week));

  return stateUpdated;
};

// reducer
const weekReducer = (state = initialWeekState, action) => {
  const {
    type, data, idx, weekIdx, dayIdx, name,
  } = action;
  switch (type) {
    case 'RESET_WEEK': return initialWeekState;
    case 'ADD_WEEK': return generateNewWeek(state);
    case 'ADD_DAY_WEEK': return generateNewDay(state, idx);
    case 'DELETE_DAY_WEEK': return deleteDayFromState(state, weekIdx, dayIdx);
    case 'DELETE_WEEK': return deleteWeekFromState(state, weekIdx);
    case 'UPDATE_DAY_EXERCISES': return updateDayFromState(state, data, name, weekIdx, dayIdx);
    default: return state;
  }
};

// action creators
const resetWeeks = () => ({ type: 'RESET_WEEK' });
const addWeek = () => ({ type: 'ADD_WEEK' });
const addDay = (idx) => ({ type: 'ADD_DAY_WEEK', idx });

const deleteDay = (weekIdx, dayIdx) => ({ type: 'DELETE_DAY_WEEK', weekIdx, dayIdx });
const deleteWeek = (weekIdx) => ({ type: 'DELETE_WEEK', weekIdx });

const updateDay = (data, weekIdx, dayIdx, name = 'exercises') => ({
  type: 'UPDATE_DAY_EXERCISES', data, name, weekIdx, dayIdx,
});

export default weekReducer;

export {
  addWeek,
  // addDayToWeek,
  resetWeeks,
  addDay,
  deleteDay,
  deleteWeek,
  updateDay,
};
