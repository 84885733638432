export const basicFlex = {
  border: '2px solid #b42519',
  boxShadow: '1px 1px 1px 1px #ededed',
  borderRadius: 5,
  // align: ['center', undefined, 'stretch'],
  align: 'stretch',
  direction: 'column',
  fontSize: '15px',
  padding: '0.5rem',
  transition: 'box-shadow 0.2s ease-in-out',
  _hover: {
    boxShadow: '2px 3px 3px 2px #444444',
  },
};

export const extraFlex = {
  backgroundColor: 'white',
  border: '3px solid #b42519',
  boxShadow: '1px 1px 1px 1px #ededed',
  borderRadius: 5,
  direction: 'column',
  fontSize: ['1.2rem', '1.5rem'],
  left: ['10px', '5%', '10%', '15%'],
  minHeight: 400,
  padding: '2rem',
  position: 'fixed',
  right: ['30px', '5%', '10%', '15%'],
  transition: 'box-shadow 0.2s ease-in-out',
  top: '10%',
  // width: ['90vw', '80vw'],
  zIndex: '2000',
  _hover: {
    boxShadow: '2px 3px 3px 2px #444444',
  },
};
