/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const bioApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_PROD}/biomechanicalanalysis/`,
});

const startBio = async (data) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await bioApi.post('/', data, { headers: { authorization: token } });
    return response.data.biomechanicalAnalisys;
  } catch (error) {
    return error.response;
  }
};

export {
  startBio,
};
