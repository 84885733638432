import { errorMessage, resetMessage, successMessage } from '../reducers/notification.reducer';

const notificationDisplay = (dispatch, message, mode) => {
  if (mode === 'error') dispatch(errorMessage(message));
  if (mode === 'success') dispatch(successMessage(message));
  setTimeout(() => {
    dispatch(resetMessage());
  }, 4000);
};

export default notificationDisplay;
