import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import 'components/planning/style.css';
import CalendarScreen from 'components/calendar/CalendarScreen';
import Month from './month';
import Week from './week';
import Day from './day';

const Planning = () => {
  const { path } = useRouteMatch();
  console.log(path);
  return (
    <div>
      <Switch>
        <Route exact path={path} component={CalendarScreen} />
        <Route path={`${path}/month`} component={Month} />
        <Route path={`${path}/week`} component={Week} />
        <Route path={`${path}/day`} component={Day} />
      </Switch>
    </div>
  );
};

export default Planning;
