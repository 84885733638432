import React, { useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import propTypes from 'prop-types';

const Toggle = ({ children, Component, large }) => {
  const [hide, setHide] = useState(true);
  return (
    <article>
      <h2 style={{
        display: 'flex',
        alignItems: 'center',
        margin: (hide && large) ? '1rem 6rem 0' : '1rem 0 0',
        justifyContent: 'space-between',
      }}
      >
        {Component}
        <IconButton onClick={() => setHide(!hide)} type="button" aria-label="expand" size="sm" colorScheme="ptRed">
          {hide ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </IconButton>
      </h2>
      <div style={{ display: hide ? 'none' : 'block' }}>
        {children}
      </div>
    </article>
  );
};

Toggle.defaultProps = {
  large: false,
};

Toggle.propTypes = {
  children: propTypes.shape({}).isRequired,
  Component: propTypes.shape({}).isRequired,
  large: propTypes.bool,
};

export default Toggle;
