/* eslint-disable no-array-constructor */
const getLoadAndReps = (number, value) => {
  const pureValue = value?.en || value;
  return ({
    set_number: number,
    load: /load/.test(pureValue) ? 0 : +pureValue.split(/(?:kg|lb)/gi)[0],
    reps: /reps/.test(pureValue) ? 0 : +pureValue.split('/')[1],
    typeWeight: /load/.test(pureValue) ? 'kg' : pureValue.match(/(?:kg|lb)/gi)[0],
  });
};

// const getExerciseModel = (info) => {
//   const sessions = [];

//   info.sessions.forEach((obj) => {
//     const exercises = obj.exercises.map(({
//       exercise, set1, set2, set3, set4,
//     }) => ({
//       id_exercise: exercise.split('*')[1],
//       sets: [
//         getLoadAndReps(1, set1),
//         getLoadAndReps(2, set2),
//         getLoadAndReps(3, set3),
//         getLoadAndReps(4, set4),
//       ],
//     }));

//     const day = {
//       number: obj.number,
//       exercises,
//     };
//     sessions.push(day);
//   });

//   const routine = {
//     id_trainer: info.trainer,
//     id_client: info.client,
//     workout_name: info.name,
//     date: info.date ? new Date(info.date) : '',
//     source: info.source,
//     sessions,
//   };

//   return routine;
// };

const getExerciseModel = (info) => {
  const sessions = [];

  info.sessions.forEach((week) => {
    console.log('in eercisemodel', week.days[0]);
    week.days.forEach((day) => sessions.push({
      day: day.number,
      week: week.number,
      notes: { en: day.notes, es: '' },
      exercises: day.exercises.map((item) => ({
        id_exercise: item.id,
        sets: [
          getLoadAndReps(1, item.set1),
          getLoadAndReps(2, item.set2),
          getLoadAndReps(3, item.set3),
          getLoadAndReps(4, item.set4),
          getLoadAndReps(5, item.set5),
        ],
      })),
    }));
  });

  const routine = {
    id_trainer: info.trainer,
    id_client: info.client,
    workout_name: { en: info.name, es: '' },
    date: new Date(info.date) || '',
    source: info.source,
    sessions,
  };

  return routine;
};

const getExerciseModelFromTemplate = (info) => {
  const sessions = [];

  info.sessions.forEach((week) => {
    week.days.forEach((day) => sessions.push({
      day: day.number,
      week: week.number,
      // soon to change
      notes: { en: day.notes, es: '' },
      exercises: day.exercises.map((exercise) => ({
        id_exercise: exercise.id,
        sets: Array.from(Array(5)).map((_, id) => getLoadAndReps(id + 1, exercise[`set${id + 1}`])),
      })),
    }));
  });

  const routine = {
    id_trainer: info.trainer,
    id_client: info.client,
    workout_name: { en: info.name, es: '' },
    date: new Date(info.date) || '',
    source: info.source,
    sessions,
  };

  return routine;
};

const getFunctionaProgramModel = (info) => {
  const exercises = [];
  info.sessions.forEach((obj) => {
    const exercise = {
      id_exercise: obj.exercise.split('*')[1],
      sets: Array.from(Array(5))
        .map((_, idx) => (idx < +obj.sets
          ? getLoadAndReps(idx + 1, `${obj.load}kg/${obj.reps}`)
          : getLoadAndReps(idx + 1, '0kg/0'))),
    };
    exercises.push(exercise);
  });

  const day = {
    week: 1,
    day: 1,
    exercises,
  };

  const routine = {
    id_trainer: info.trainer,
    id_client: info.client,
    workout_name: { en: info.name, es: '' },
    source: info.source,
    date: info?.date || '',
    sessions: [day],
  };
  console.log(routine);
  return routine;
};

export {
  getExerciseModel,
  getExerciseModelFromTemplate,
  getFunctionaProgramModel,
};
