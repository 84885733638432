import { useMutation, useQueryClient } from 'react-query';
import { updateTrainer } from 'services/trainer';

const useUpdateTrainer = (idTrainer, idUser, setEdit) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateTrainer(idTrainer, data), {
    onMutate: (updatedData) => {
      queryClient.setQueryData(['userInfo', idUser], updatedData);
      setEdit(false);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['userInfo', idUser]);
    },
  });
};

export default useUpdateTrainer;
