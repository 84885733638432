const userReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD':
      return action.data;
    case 'DELETE':
      return {};
    default:
      return state;
  }
};

const addInfo = (data) => ({ type: 'ADD', data });
const deleteInfo = () => ({ type: 'DELETE' });

export default userReducer;

export { addInfo, deleteInfo };
