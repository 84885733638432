/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button, ButtonGroup, IconButton, Select,
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import propTypes from 'prop-types';

import useAdd from 'hooks/useAdd';
import { transformClientData } from 'utils/transformData';
import { createClient } from 'services/clients';

import { infoStyle } from 'theme/commonStyles';
import DateInput from '../common/DateInput';
import TextInput from '../common/TextInput';

const groupStyle = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '1rem 0',
};
const addressBtn = {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  color: '#444444',
};

const AddClient = ({ close }) => {
  const [secondCity, setSecondCity] = useState(false);
  const { id_user } = useSelector(({ user }) => user);
  const { control, handleSubmit } = useForm();
  const { t } = useTranslation();
  const {
    mutate, isLoading, isSuccess, isError,
  } = useAdd('clients', createClient);

  const onSubmit = (data) => mutate(transformClientData(data, id_user));

  if (isLoading) return <p style={infoStyle}>{t('data_addition')}</p>;
  if (isError) return <p style={infoStyle}>{t('something_wrong')}</p>;
  if (isSuccess) return <p style={infoStyle}>{t('succesful_addition')}</p>;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <legend>{t('client_basic_info')}</legend>
        <TextInput
          control={control}
          name="name"
          place={t('fullname')}
        />

        <DateInput
          control={control}
          name="birth_date"
          place={t('birth_date')}
        />

        <div role="group" style={addressBtn}>
          <TextInput
            control={control}
            name="street1"
            place={t('principal_address')}
          />
          <IconButton
            mb="4"
            icon={!secondCity ? <AddIcon /> : <MinusIcon />}
            onClick={() => setSecondCity(!secondCity)}
          />
        </div>

        {secondCity && (
          <TextInput
            control={control}
            name="street2"
            place={t('secondary_address')}
          />
        )}
        <TextInput
          control={control}
          name="city"
          place={t('city')}
        />
        <TextInput
          control={control}
          name="state"
          place={t('state')}
        />
        <TextInput
          control={control}
          name="zip"
          place={t('zip')}
        />
        <TextInput
          control={control}
          name="phone"
          place={t('phonenumber')}
        />

        <Controller
          control={control}
          name="gender"
          defaultValue=""
          render={({ field }) => (
            <Select placeholder={t('select_gender')} {...field} backgroundColor="#ececec" focusBorderColor="ptRed.500" color="#444444">
              <option value="male">{t('opt_male')}</option>
              <option value="female">{t('opt_female')}</option>
            </Select>
          )}
        />
      </fieldset>
      <fieldset>
        <legend>{t('client_user_info')}</legend>
        <TextInput
          control={control}
          name="email"
          place={t('email')}
        />
        <TextInput
          control={control}
          name="speciality"
          place={t('speciality')}
        />

      </fieldset>
      <ButtonGroup style={groupStyle} colorScheme="ptRed">
        <Button onClick={close}>{t('cancel')}</Button>
        <Button type="submit">{t('save')}</Button>
      </ButtonGroup>
    </form>
  );
};

AddClient.propTypes = {
  close: propTypes.func.isRequired,
};

export default AddClient;
