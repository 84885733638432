import React from 'react';
import propTypes from 'prop-types';
import {
  VStack, Heading, Flex, Button, ButtonGroup, useDisclosure,
} from '@chakra-ui/react';

import { generateEvaluationPDF } from 'utils/pdfGenerators';

import useLang from 'hooks/useLang';
import ReportCard from './ReportCard';
import Alert from './Features/Alert';

const Report = ({
  client, evaluations, dispatch, setProgram, setStep,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t, lang } = useLang();

  console.log('evaluations', evaluations);
  const handleSelectProgram = (program) => {
    setProgram(program);
    setStep((prev) => prev + 1);
  };
  return (
    <>
      <VStack
        border="3px solid #b42519"
        p="1.5rem 1rem"
        borderRadius="5px"
        backgroundColor="#252625"
        color="#ededed"
        width="90%"
      >
        <Heading size="lg">
          {t('eval_name', { name: client.split('-')[0] })}
        </Heading>
        <Flex
          direction={['column', undefined, 'row', 'row']}
          wrap={[undefined, undefined, 'wrap', 'wrap']}
          justify="space-around"
          width="100%"
        >
          {
            evaluations
              .map((evaluation) => (
                <ReportCard
                  evaluation={evaluation}
                  key={evaluation.exercise}
                  dispatch={dispatch}
                  lang={lang}
                  t={t}
                />
              ))
          }
        </Flex>
        <ButtonGroup colorScheme="ptRed">
          <Button onClick={() => generateEvaluationPDF(evaluations, client.split('-')[0], lang)}>PDF</Button>
          <Button onClick={onOpen}>{t('create_program')}</Button>
        </ButtonGroup>
      </VStack>
      <Alert onClose={onClose} isOpen={isOpen} t={t} setProgram={handleSelectProgram} />
    </>
  );
};

Report.propTypes = {
  client: propTypes.string.isRequired,
  evaluations: propTypes.shape([]).isRequired,
  dispatch: propTypes.func.isRequired,
  setProgram: propTypes.func.isRequired,
  setStep: propTypes.func.isRequired,
};

export default Report;

// GET /evaluation/program
// POST /evaluation/program

// evaluationProgram = [
//   {
//     exercise_id: id,
//     weakness: string,
//     type: enum [traditional, functional]
//   }
// ]
