import React from 'react';
import { HStack, Button } from '@chakra-ui/react';
import { func } from 'prop-types';

const CancelBtn = ({ cancel }) => (
  <HStack w="90%" justify="flex-end" mb="0.5rem">
    <Button onClick={cancel} colorScheme="ptRed" variant="link">
      cancel
    </Button>
  </HStack>
);

CancelBtn.propTypes = {
  cancel: func.isRequired,
};

export default CancelBtn;
