import React from 'react';
import AdminHelp from 'components/adminHelp';

import { bigContainerStyle } from 'theme/commonStyles';

const Help = () => (
  <main style={bigContainerStyle}>
    <AdminHelp />
  </main>
);

export default Help;
