import React from 'react';
import propTypes from 'prop-types';
import { Alert, AlertIcon } from '@chakra-ui/react';

const styles = {
  position: 'sticky',
  textAlign: 'center',
  top: '0',
  zIndex: '2000',
};

const Notification = ({ message, status }) => (
  <Alert status={status} style={styles}>
    <AlertIcon />
    {' '}
    {message}
  </Alert>
);

// const Notification = ({ message }) => <div className={styles.root}>{message}</div>;

Notification.propTypes = {
  message: propTypes.string.isRequired,
  status: propTypes.string.isRequired,
};

export default Notification;
