/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useReducer } from 'react';
import {
  CloseIcon, DownloadIcon, CopyIcon, EditIcon, ExternalLinkIcon,
} from '@chakra-ui/icons';
import {
  Badge, Button, ButtonGroup, Box, Flex, Grid, IconButton, Checkbox,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import propTypes from 'prop-types';

import useLang from 'hooks/useLang';
import { generateReport } from 'utils/pdfGenerators';
import Toggle from 'components/common/Toggle';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { useDispatch } from 'react-redux';
import { resetWeeks } from 'reducers/weekReducer.reducer';
import CopyAlert from './CopyAlert';
import InfoLine from './Features/InfoLine';
import RoutineCard from './RoutineCard';
import BackgroundOverlay from './Features/BackgroundOverlay';

const RoutineDisplay = ({ routine, handleDelete }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [redirection, setRedirection] = useState(false);
  const [redirectionType, setRedirectionType] = useState('clone');
  // const [objToSend, setObjToSend] = useState({});
  // const [objMocked, setObjMocked] = useState({});
  const [infoToCopy, setInfoToCopy] = useState({});

  const [isExtended, toggleCard] = useReducer((prev) => !prev, false);
  const [actual, setActual] = useState({ day: 0, week: 0 });

  const dispatch = useDispatch();
  // const { t, lang } = useLang();
  const { t } = useTranslation();
  const lang = localStorage.getItem('i18nextLng');

  const handleClose = () => setDialogOpen(false);
  const handleChangeStyle = (day, week) => {
    toggleCard();
    setActual({ day, week });
  };
  const handleOpen = (exercises, day, week) => {
    setInfoToCopy({ exercises, week });
    setDialogOpen(true);
    console.log('exercises', exercises);
    console.log('routine', routine);
    // setObjToSend(routineToSend);
    // setObjMocked(mockRoutine);
  };

  const handleEdit = () => {
    dispatch(resetWeeks());
    setRedirectionType('edit');
    setRedirection(true);
  };
  const handleClone = () => {
    dispatch(resetWeeks());
    setRedirection(true);
  };

  // const handleComplete = (sessionId) => updateCompletedDay(sessionId);

  if (redirection) {
    const stateToSend = redirectionType === 'clone'
      ? ({
        routine,
        workType: 'custom',
        step: 2,
      })
      : ({
        routine,
        workType: 'custom',
        step: 3,
        client: `${routine.id_client.name}-${routine.id_client._id}`,
        edit: true,
      });
    const config = {
      pathname: '/user/routines',
      state: stateToSend,
    };
    return <Redirect to={config} />;
  }

  return (
    <>
      <Toggle
        large
        Component={(
          <span>
            <Badge colorScheme="red" variant="solid">
              {routine.workout_name.en}
            </Badge>
            {' '}
            {dayjs(routine.created_at).format('MM/DD/YYYY')}
          </span>
        )}
      >
        <BackgroundOverlay isExtended={isExtended} />
        <InfoLine label={t('source')} text={routine.source} />
        <InfoLine label={t('number_sessions')} text={routine.sessions.length} />
        <InfoLine label={t('created_at')} text={dayjs(routine.created_at).format('MM-DD-YYYY')} />
        {routine.date && (
          <InfoLine label={t('start_date')} text={dayjs(routine.date).format('MM-DD-YYYY')} />
        )}
        <Grid templateColumns={['1fr', '1fr', '1fr 1fr']} gap={2} autoRows mt="0.5rem">
          {
            routine.sessions
              .sort((dayA, dayB) => dayA.week - dayB.week)
              .map((session) => (
                <ErrorBoundary>
                  <RoutineCard
                    key={session._id}
                    actual={actual}
                    handleChangeStyle={handleChangeStyle}
                    isExtended={isExtended}
                    session={session}
                    handleOpen={handleOpen}
                  />
                </ErrorBoundary>
              ))
          }
        </Grid>
        <ButtonGroup colorScheme="ptRed" isAttached mt="1rem" display="flex" justifyContent="center">
          <Button rightIcon={<CloseIcon />} onClick={() => handleDelete(routine._id)}>
            {t('delete')}
          </Button>
          <Button rightIcon={<EditIcon />} onClick={handleEdit}>
            Edit
          </Button>
          <Button rightIcon={<DownloadIcon />} onClick={() => generateReport(routine, lang)}>
            {t('download')}
          </Button>
          <Button rightIcon={<CopyIcon />} onClick={handleClone}>
            Clone
          </Button>
        </ButtonGroup>
      </Toggle>
      {
        dialogOpen && (
          <CopyAlert
            dialogOpen={dialogOpen}
            handleClose={handleClose}
            routine={routine}
            info={infoToCopy}
          // objToSend={objToSend}
          // objMocked={objMocked}
          // type={type}
          // setType={setType}
          />
        )
      }
    </>
  );
};

RoutineDisplay.propTypes = {
  routine: propTypes.shape({
    workout_name: propTypes.string.isRequired,
    source: propTypes.string.isRequired,
    sessions: propTypes.shape([]).isRequired,
    created_at: propTypes.string.isRequired,
    date: propTypes.string.isRequired,
    preProgrammed: propTypes.bool.isRequired,
    id_client: propTypes.shape({
      _id: propTypes.string.isRequired,
      name: propTypes.string.isRequired,
    }).isRequired,
    id_trainer: propTypes.shape({
      _id: propTypes.string.isRequired,
    }).isRequired,
    _id: propTypes.string.isRequired,
  }).isRequired,
  handleDelete: propTypes.func.isRequired,
};

export default RoutineDisplay;
