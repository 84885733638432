/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react';
import propTypes from 'prop-types';
import {
  Button,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
} from '@chakra-ui/react';
import MaterialTable from 'material-table';

import useGetAll from 'hooks/useGetAll';
import { getAllCategories } from 'services/category';
import { updateData } from './createTemplate/templateCategoryReducer';
import tableIcons from '../../theme/tableIcons';
import localizationMaterialTable from '../../helpers/localizationMaterialTable';
import customFilterAndSearch from '../../helpers/customFilterAndSearch';

const muscleList = [
  'All', 'Abs', 'Adductors', 'Anterior Core', 'Arms', 'Back', 'Biceps', 'Calves', 'Chest', 'Core',
  'Hamstrings', 'Posterior Core', 'Legs', 'Quadriceps', 'Hips', 'Gluteus',
  'Shoulders', 'Triceps', 'Lateral Core',
];

const actionList = [
  'All', 'Level Change', 'Hip Hinge', 'Traction', 'Pull', 'Push', 'Core Stifness', 'Rotation', 'Jump',
  'Plyometric', 'Locomotion', 'Push-Pull', 'Adductors',
];

const CategoryOverlay = ({
  onClose, isOpen, position, dispatch, final, save,
}) => {
  const {
    data, isLoading, isError, isSuccess,
  } = useGetAll('categories', null, getAllCategories);
  const [subCategory, setSubCategory] = useState('All');
  const handleClick = (rowIdx, columnIdx, name, sub, exerciseIdx) => {
    console.log(name, rowIdx, columnIdx, exerciseIdx);
    dispatch(updateData(name, rowIdx, columnIdx));
    dispatch(updateData(sub, rowIdx, 'sub'));
    dispatch(updateData(exerciseIdx, rowIdx, 'id'));
    save();
    onClose();
  };
  const localization = localizationMaterialTable();
  const handleSelectMuscle = ({ target }) => {
    setSubCategory(target.value);
  };

  // console.log('create template data', data);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={final}
    >
      <ModalOverlay />
      <ModalContent maxWidth="40rem">
        <ModalHeader>
          Select Category
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {
            isLoading && <p> Loading </p>
          }
          {
            isError && <p> Somenthing went wrong </p>
          }
          {
            (data && isSuccess) && (
              <MaterialTable
                columns={[
                  {
                    title: 'Name',
                    field: 'name',
                    customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'name'),
                  },
                  { title: 'Muscle', field: 'muscle' },
                  { title: 'Action', field: 'action' },
                  { title: 'Options', field: 'options' },
                ]}
                data={[
                  ...data
                    .map(({ name, category_id, _id }) => ({
                      name,
                      muscle: <select name="muscle_system" id="muscle_system" onChange={handleSelectMuscle} value={subCategory}>
                        {
                          muscleList.map((muscle) => <option value={muscle}>{muscle}</option>)
                        }
                      </select>,
                      action: <select name="action" id="action" onChange={handleSelectMuscle} value={subCategory}>
                        {
                          actionList.map((muscle) => <option value={muscle}>{muscle}</option>)
                        }
                      </select>,
                      options: <Button
                        variant="link"
                        // onClick={() => handleClick(position.rowIdx, position.columnIdx, name, _id)}
                        onClick={() => handleClick(position.rowIdx, position.columnIdx, name, subCategory, _id)}
                      // onClick={() => handleClick(position.rowIdx, position.columnIdx, `${name}-${subCategory}`, _id)}
                      >
                        {
                        }
                        select
                      </Button>,
                    })),
                ]}
                title=""
                icons={tableIcons}
                localization={localization}
              />
            )
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

CategoryOverlay.defaultProps = {
  final: null,
};

CategoryOverlay.propTypes = {
  save: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  dispatch: propTypes.func.isRequired,
  position: propTypes.shape({
    rowIdx: propTypes.number.isRequired,
    columnIdx: propTypes.string.isRequired,
  }).isRequired,
  final: propTypes.node,
};

export default CategoryOverlay;
