/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import useUploadFileS3 from 'hooks/useUploadFileS3';
import {
  Button, Input, Checkbox, CheckboxGroup, HStack, Textarea, Text,
  VStack, Radio, RadioGroup, Flex, IconButton,
} from '@chakra-ui/react';
import { ArrowUpIcon, AddIcon, MinusIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';

import useAdd from 'hooks/useAdd';
import { addExercises } from 'services/exercise';
import transformData, { transformToAddExercise } from 'utils/transformData';
import notificationDisplay from 'utils/notificationDisplay';

import { tagStyle, infoStyle, inputStyle } from 'theme/commonStyles';

import Toggle from 'components/common/Toggle';

const AddExercise = () => {
  const [imgArr, setImgArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { control, handleSubmit, register } = useForm({
    mode: 'onChange',
  });
  const {
    fields, append, prepend, remove, swap, move, insert,
  } = useFieldArray({
    control,
    name: 'instructions',
  });
  const { t } = useTranslation();
  const uploadFileS3 = useUploadFileS3();
  const {
    mutate, isLoading, isError, isSuccess,
  } = useAdd('exercises', addExercises);
  const { _id, id_user } = useSelector(({ user }) => user);
  const lang = window.localStorage.getItem('i18nextLng');

  const handleImageClick = () => document.querySelector('.file').click();

  const onChange = async (data) => {
    const file = document.querySelector('.file');
    let response = '';
    if (data.images[0]) {
      setLoading(true);
      response = await uploadFileS3.mutateAsync(data.images[0], data.name);
      if (response.location) {
        notificationDisplay(dispatch, 'Your image have been saved', 'success');
        setImgArr((prev) => prev.concat(response.location));
      }
      file.value = '';
      setLoading(false);
    }
  };

  const onSubmit = (data) => {
    // const dataToSend = transformData(
    //   data,
    //   'addExercise',
    //   lang,
    //   imgArr,
    // );
    const dataToSend = transformToAddExercise(
      data,
      lang,
      imgArr,
      id_user,
    );

    console.log('dataToAd', dataToSend);
    mutate(dataToSend);
  };
  if (isLoading) return <p style={infoStyle}>Updating Data</p>;
  if (isError) return <p style={infoStyle}>Something went wrong</p>;
  if (isSuccess) return <p style={infoStyle}>Data was updated succesfully</p>;
  return (
    <form onChange={handleSubmit(onChange)} onSubmit={handleSubmit(onSubmit)}>
      <Flex align="center" justify="center" as="fieldset">
        <Flex align="center" justify="center" direction="column">
          <Text>
            {t('images_uploaded', { number: imgArr.length })}
          </Text>
          <Button
            colorScheme="ptRed"
            my="1rem"
            rightIcon={<ArrowUpIcon />}
            onClick={handleImageClick}
            isLoading={loading}
          >
            {t('upload_image')}
          </Button>
          <input
            accept="image/*"
            className="file"
            type="file"
            {...register('images')}
            style={{ display: 'none' }}
          />
        </Flex>
      </Flex>

      <p style={tagStyle}>
        {t('exercise_name')}
        {' '}
        EN
      </p>
      <Controller
        control={control}
        defaultValue=""
        name="enName"
        render={({ field }) => (
          <Input
            focusBorderColor="ptRed.500"
            placeholder={t('exercise_name_placeholder_en')}
            {...field}
          />
        )}
      />

      <p style={tagStyle}>
        {t('exercise_name')}
        {' '}
        ES
      </p>
      <Controller
        control={control}
        defaultValue=""
        name="esName"
        render={({ field }) => (
          <Input
            focusBorderColor="ptRed.500"
            placeholder={t('exercise_name_placeholder_es')}
            {...field}
          />
        )}
      />

      <Toggle Component={<p style={tagStyle}>{t('muscle_system')}</p>}>
        <Controller
          control={control}
          name="muscle_system"
          render={({ field }) => (
            <CheckboxGroup {...field} colorScheme="red">
              <HStack ml="1rem" wrap="wrap">
                <Checkbox value={t('opt_legs')}>{t('opt_legs')}</Checkbox>
                <Checkbox value={t('opt_shoulder')}>{t('opt_shoulder')}</Checkbox>
                <Checkbox value={t('opt_hips')}>{t('opt_hips')}</Checkbox>
                <Checkbox value={t('opt_gluteus')}>{t('opt_gluteus')}</Checkbox>
                <Checkbox value={t('opt_hamstring')}>{t('opt_hamstring')}</Checkbox>
                <Checkbox value={t('opt_posterior_core')}>{t('opt_posterior_core')}</Checkbox>
                <Checkbox value={t('opt_anterior_core')}>{t('opt_anterior_core')}</Checkbox>
                <Checkbox value={t('opt_lateral_core')}>{t('opt_lateral_core')}</Checkbox>
                <Checkbox value={t('opt_abs')}>{t('opt_abs')}</Checkbox>
                <Checkbox value={t('opt_quadriceps')}>{t('opt_quadriceps')}</Checkbox>
                <Checkbox value={t('opt_back')}>{t('opt_back')}</Checkbox>
                <Checkbox value={t('opt_arms')}>{t('opt_arms')}</Checkbox>
                <Checkbox value={t('opt_biceps')}>{t('opt_biceps')}</Checkbox>
                <Checkbox value={t('opt_triceps')}>{t('opt_triceps')}</Checkbox>
                <Checkbox value={t('opt_pec')}>{t('opt_pec')}</Checkbox>
                <Checkbox value={t('opt_adductors')}>{t('opt_adductors')}</Checkbox>
                <Checkbox value={t('opt_calves')}>{t('opt_calves')}</Checkbox>
                <Checkbox value={t('opt_ancles')}>{t('opt_ancles')}</Checkbox>
                <Checkbox value={t('opt_all_body')}>{t('opt_all_body')}</Checkbox>
              </HStack>
            </CheckboxGroup>
          )}
        />
      </Toggle>

      <Toggle Component={<p style={tagStyle}>{t('categories')}</p>}>
        <Controller
          control={control}
          name="category_id"
          render={({ field }) => (
            <CheckboxGroup {...field} colorScheme="red" as="fieldset">
              <HStack ml="1rem" wrap="wrap">
                <Checkbox value="6171d84aec64ada0efa05721">Traditional</Checkbox>
                <Checkbox value="61a62b7fb2f0481a2d9db38e">Functional</Checkbox>
                <Checkbox value="61be5e479abd1ebc3d540764">Rehab</Checkbox>
                <Checkbox value="61a62ba3b2f0481a2d9db38f">Strength</Checkbox>
                <Checkbox value="61be5e799abd1ebc3d540767">Metabolic</Checkbox>
                <Checkbox value="61be5e969abd1ebc3d54076a">SAQ</Checkbox>
              </HStack>
            </CheckboxGroup>
          )}
        />
      </Toggle>

      <Toggle Component={<p style={tagStyle}>{t('action')}</p>}>
        <Controller
          control={control}
          name="action"
          render={({ field }) => (
            <RadioGroup {...field} colorScheme="red">
              <HStack ml="1rem" wrap="wrap">
                <Radio value={t('opt_level_change')}>{t('opt_level_change')}</Radio>
                <Radio value={t('opt_hip_hinge')}>{t('opt_hip_hinge')}</Radio>
                <Radio value={t('opt_traction')}>{t('opt_traction')}</Radio>
                <Radio value={t('opt_pull')}>{t('opt_pull')}</Radio>
                <Radio value={t('opt_push')}>{t('opt_push')}</Radio>
                <Radio value={t('opt_core_stiffness')}>{t('opt_core_stiffness')}</Radio>
                <Radio value={t('opt_rotation')}>{t('opt_rotation')}</Radio>
                <Radio value={t('opt_jumps')}>{t('opt_jumps')}</Radio>
                <Radio value={t('opt_plyometric')}>{t('opt_plyometric')}</Radio>
                <Radio value={t('opt_locomotion')}>{t('opt_locomotion')}</Radio>
                <Radio value={t('opt_push_pull')}>{t('opt_push_pull')}</Radio>
                <Radio value={t('opt_adductors')}>{t('opt_adductors')}</Radio>
              </HStack>
            </RadioGroup>
          )}
        />
      </Toggle>

      <Toggle Component={<p style={tagStyle}>{t('equipment')}</p>}>
        <Controller
          control={control}
          name="equipment"
          render={({ field }) => (
            <CheckboxGroup {...field} colorScheme="red">
              <HStack ml="1rem" wrap="wrap">
                <Checkbox value={t('opt_bb')}>{t('opt_bb')}</Checkbox>
                <Checkbox value={t('opt_db/kb')}>{t('opt_db/kb')}</Checkbox>
                <Checkbox value={t('opt_m')}>{t('opt_m')}</Checkbox>
                <Checkbox value={t('opt_db')}>{t('opt_db')}</Checkbox>
                <Checkbox value={t('opt_bp')}>{t('opt_bp')}</Checkbox>
                <Checkbox value={t('opt_ez')}>{t('opt_ez')}</Checkbox>
                <Checkbox value={t('opt_sm')}>{t('opt_sm')}</Checkbox>
                <Checkbox value={t('opt_abw')}>{t('opt_abw')}</Checkbox>
                <Checkbox value={t('opt_bw')}>{t('opt_bw')}</Checkbox>
                <Checkbox value={t('opt_mb')}>{t('opt_mb')}</Checkbox>
                <Checkbox value={t('opt_ropes/sus')}>{t('opt_ropes/sus')}</Checkbox>
                <Checkbox value={t('opt_sb')}>{t('opt_sb')}</Checkbox>
                <Checkbox value={t('opt_slb')}>{t('opt_slb')}</Checkbox>
                <Checkbox value={t('opt_kb')}>{t('opt_kb')}</Checkbox>
                <Checkbox value={t('opt_sprb')}>{t('opt_sprb')}</Checkbox>
                <Checkbox value={t('opt_sprb_short')}>{t('opt_sprb_short')}</Checkbox>
                <Checkbox value={t('opt_hg360')}>{t('opt_hg360')}</Checkbox>
                <Checkbox value={t('opt_car/truck')}>{t('opt_car/truck')}</Checkbox>
                <Checkbox value={t('opt_ropes')}>{t('opt_ropes')}</Checkbox>
                <Checkbox value={t('opt_sled')}>{t('opt_sled')}</Checkbox>
                <Checkbox value={t('opt_vc')}>{t('opt_vc')}</Checkbox>
                <Checkbox value={t('opt_bp_band')}>{t('opt_bp_band')}</Checkbox>
                <Checkbox value={t('opt_db2')}>{t('opt_db2')}</Checkbox>
                <Checkbox value={t('opt_sus')}>{t('opt_sus')}</Checkbox>
                <Checkbox value={t('opt_lm')}>{t('opt_lm')}</Checkbox>
                <Checkbox value={t('opt_plate')}>{t('opt_plate')}</Checkbox>
                <Checkbox value={t('opt_vib')}>{t('opt_vib')}</Checkbox>
                <Checkbox value={t('opt_agl')}>{t('opt_agl')}</Checkbox>
                <Checkbox value={t('opt_agl_short')}>{t('opt_agl_short')}</Checkbox>
                <Checkbox value={t('opt_hur')}>{t('opt_hur')}</Checkbox>
                <Checkbox value={t('opt_cones')}>{t('opt_cones')}</Checkbox>
                <Checkbox value={t('opt_cs')}>{t('opt_cs')}</Checkbox>
              </HStack>
            </CheckboxGroup>
          )}
        />
      </Toggle>

      {/*       <Controller
        control={control}
        name="instructions"
        render={({ field }) => (
          <VStack wrap="wrap" mt="1rem">
            <Text color="#d9ad42" fontWeight="700" alignSelf="start">{t('instructions')}</Text>
            <Text fontSize="xs" mt="0">{t('intructions_instruction')}</Text>
            <Textarea {...field} />
          </VStack>
        )}
      />
 */}
      <Text color="#d9ad42" fontWeight="700" alignSelf="start" my="1rem">
        {t('instructions')}
        {' '}
        <IconButton
          aria-label="add instruction"
          colorScheme="ptRed"
          icon={<AddIcon />}
          onClick={() => append({})}
          type="button"
          variant="link"
        />
      </Text>
      {fields.map(({
        id, name,
      }, index) => (
        <>
          <Input
            key={id}
            name={`instructions.${index}.name`}
            placeholder={`Step: ${index + 1}`}
            {...register(`instructions.${index}.name`)}
            {...inputStyle}
            w="80%"
          />
          <IconButton
            aria-label="remove instructon"
            colorScheme="ptRed"
            icon={<MinusIcon />}
            type="button"
            onClick={() => remove(index)}
            variant="link"
          />
        </>
      ))}

      <Button type="submit" colorScheme="ptRed" my="1rem" float="right">
        {t('save')}
      </Button>
    </form>
  );
};

AddExercise.propTypes = {
  exercise: propTypes.shape({
    _id: propTypes.number.isRequired,
    name: propTypes.string.isRequired,
    muscle_system: propTypes.shape([]).isRequired,
    equipment: propTypes.shape([]).isRequired,
    category_id: propTypes.shape([]).isRequired,
    abbrevations: propTypes.shape([]).isRequired,
    instructions: propTypes.shape([]).isRequired,
  }).isRequired,
};

export default AddExercise;
