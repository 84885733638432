import React from 'react';
import propTypes from 'prop-types';
import styles from './Info.module.scss';

const Article = ({ children, title }) => (
  <article className={styles.container}>
    <h3 className={styles.title}>{title}</h3>
    <div className={styles.divider} />
    {children}
  </article>
);

Article.propTypes = {
  children: propTypes.node.isRequired,
  title: propTypes.string.isRequired,
};

export default Article;
