/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, {
  useState, useReducer, useRef, useEffect,
} from 'react';
import {
  useDisclosure, Heading, Flex, Button,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { shape, number } from 'prop-types';

import useLang from 'hooks/useLang';
import getRoutineSetValues from 'utils/getRoutineSetValues';
import { updateDay } from 'reducers/weekReducer.reducer';
import { addCellValue } from 'reducers/cell.reducer';
// import preprogrammedReducer, {
//   addRow, deleteRow, makePreProgrammed, initialState, getBasicData,
// } from './preprogrammedReducer';

import routineReducer, {
  addRow, deleteRow, initialState, getEmptyInitialRoutine, predefineRoutine,
} from '../routineReducer';

import TableDisplay from '../../TableDisplay';
import ExerciseOverlay from '../Features/ExerciseOverlay';
import SetOverlay from '../Features/SetOverlay';

const Preprogrammed = ({
  date, actual, data,
}) => {
  const [state, dispatch] = useReducer(routineReducer, initialState);
  const [sessionSaved, toggleSession] = useReducer((prev) => !prev, false);
  const [position, setPosition] = useState({ rowIdx: null, columnIdx: null });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const reduxDispatch = useDispatch();
  const finalRef = useRef();
  const { t, lang } = useLang();

  const handleOpen = (rowIdx, columnIdx, value) => {
    const valueGotten = value?.en || value;
    if (columnIdx !== 'exercise') reduxDispatch(addCellValue(getRoutineSetValues(valueGotten)));
    setPosition({ rowIdx, columnIdx });
    onOpen();
  };
  const handleSave = () => reduxDispatch(updateDay(state.data, date.week, date.day));

  const handleAdd = () => {
    dispatch(addRow());
    toggleSession();
  };
  const handleDelete = (idx) => {
    dispatch(deleteRow(idx));
    toggleSession();
  };

  useEffect(() => {
    console.log('pre data', data);
    if (data === undefined) dispatch(getEmptyInitialRoutine());
    else dispatch(predefineRoutine(data.exercises, lang));
    toggleSession();
  }, []);

  useEffect(() => {
    handleSave();
  }, [sessionSaved]);

  return (
    <>
      <div style={{ display: (date.day === actual.day && date.week === actual.week) ? 'block' : 'none' }}>
        <Flex justifyContent="space-around" mb="1rem">
          <Heading>
            {t('workout_title', { day: date.day, week: date.week })}
          </Heading>

          <Button onClick={handleAdd} colorScheme="ptRed">
            {t('add_row')}
          </Button>
        </Flex>
        <TableDisplay
          columns={state.columns}
          data={state.data}
          handleOpen={handleOpen}
          handleDelete={handleDelete}
          ref={finalRef}
          position={position}
        />
      </div>

      {
        (position.columnIdx === 'exercise' && isOpen) && (
          <ExerciseOverlay
            isOpen={isOpen}
            onClose={onClose}
            position={position}
            dispatch={dispatch}
            final={finalRef}
            save={toggleSession}
          />
        )
      }
      {
        (position.columnIdx !== 'exercise' && isOpen) && (
          <SetOverlay
            isOpen={isOpen}
            onClose={onClose}
            position={position}
            dispatch={dispatch}
            final={finalRef}
            save={toggleSession}
          />
        )
      }
    </>
  );
};

Preprogrammed.propTypes = {
  date: shape({
    day: number.isRequired,
    week: number.isRequired,
  }).isRequired,
  actual: shape({
    day: number.isRequired,
    week: number.isRequired,
  }).isRequired,
  data: shape([]).isRequired,
};

export default Preprogrammed;
