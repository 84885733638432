/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, HStack } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ReturnBtn = ({ setStep, initialNumber, mode }) => {
  const { t } = useTranslation();
  return (
    <HStack w="90%" justify="flex-end" mb="0.5rem">
      {
      mode === 'help'
        ? (
          <Button
            colorScheme="red"
            leftIcon={<ArrowBackIcon />}
            as={Link}
            to="/help"
            variant="link"
          >
            {t('return')}
          </Button>
        )
        : (
          <Button
            colorScheme="red"
            leftIcon={<ArrowBackIcon />}
            onClick={() => {
              setStep(initialNumber);
            }}
            variant="link"
          >
            {t('return')}
          </Button>
        )
    }
    </HStack>
  );
};

ReturnBtn.defaultProps = {
  setStep: null,
  initialNumber: 1,
  mode: 'normal',
};

ReturnBtn.propTypes = {
  setStep: propTypes.func,
  initialNumber: propTypes.number,
  mode: propTypes.string,
};

export default ReturnBtn;
