import React from 'react';
import { Box } from '@chakra-ui/react';
import { bool } from 'prop-types';

const BackgroundOverlay = ({ isExtended }) => (
  <Box
    display={isExtended ? 'block' : 'none'}
    borderRadius="5px"
    position="absolute"
    backgroundColor="rgba(0, 0, 0, 0.48)"
    top={0}
    left={0}
    width="100%"
    height="100%"
    data-name="background"
    zIndex={1999}
  />
);

BackgroundOverlay.propTypes = {
  isExtended: bool.isRequired,
};

export default BackgroundOverlay;
