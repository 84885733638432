import React from 'react';
import { Flex, Avatar, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import propTypes from 'prop-types';

import styles from './Info.module.scss';

const Info = ({ client }) => {
  const { t } = useTranslation();
  return (
    <Flex className={styles.root}>
      <Avatar alignSelf="center" />
      <Text fontSize="md" mt="0.5rem">
        <span className={styles.tag}>{t('fullname')}</span>
        {client.name}
      </Text>
      <Text fontSize="md" mt="0.5rem">
        <span className={styles.tag}>{t('birth_date')}</span>
        {dayjs(client.birthdate).format('MM/DD/YYYY')}
      </Text>
      <Text fontSize="md" mt="0.5rem">
        <span className={styles.tag}>{t('phonenumber')}</span>
        {client.phone}
      </Text>
      <Text fontSize="md" mt="0.5rem">
        <span className={styles.tag}>{t('email')}</span>
        {client.email}
      </Text>
      <Text fontSize="md" mt="0.5rem">
        <span className={styles.tag}>
          {t('principal_address')}
          {' '}
        </span>
        {client.address.street1}
      </Text>
      {client.address.street2 && (
        <Text fontSize="md" mt="0.5rem">
          <span className={styles.tag}>
            {t('secondary_address')}
            {' '}
          </span>
          {client.address.street2}
        </Text>
      )}

      <Text mt="0.5rem">
        <span className={styles.tag}>
          {t('city')}
          :
        </span>
        {client.address.city}
      </Text>
      <Text mt="0.5rem">
        <span className={styles.tag}>
          {t('state')}
          :
        </span>
        {client.address.state}
      </Text>
      <Text mt="0.5rem">
        <span className={styles.tag}>
          {t('zip')}
        </span>
        {client.address.zip}
      </Text>
    </Flex>
  );
};

Info.propTypes = {
  client: propTypes.shape({
    address: propTypes.shape({
      city: propTypes.string.isRequired,
      state: propTypes.string.isRequired,
      street1: propTypes.string.isRequired,
      street2: propTypes.string.isRequired,
      zip: propTypes.string.isRequired,
    }).isRequired,
    birthdate: propTypes.string.isRequired,
    email: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    phone: propTypes.string.isRequired,
  }).isRequired,
};

export default Info;
