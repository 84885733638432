/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';

import Carousel from 'components/common/Carousel';
import FrameVideo from 'components/common/FrameVideo';

import styles from './Info.module.scss';

const Info = ({ help }) => {
  const lang = window.localStorage.getItem('i18nextLng');
  const [carouselStep, setCarouselStep] = useState(0);
  const { t } = useTranslation();
  const regex = new RegExp('v=', 'gi');
  const handleCarouselClick = (movement) => {
    setCarouselStep((prev) => (movement === 'prev' ? prev - 1 : prev + 1));
  };

  return (
    <Flex className={styles.root}>
      <Text fontSize="md" mt="0.5rem">
        <span className={styles.tag}>{t('Title')}</span>
        {help.title[lang]}
      </Text>
      {
        help.images.length > 0 && <Carousel step={carouselStep} images={help.images} click={handleCarouselClick} />
      }
      <Text fontSize="md" mt="0.5rem">
        <span className={styles.tag}>{t('Description')}</span>
        {help.description[lang]}
      </Text>
      {
        help.url && (
          <>
            <Text fontSize="md" mt="0.5rem">
              <span className={styles.tag}>{t('video')}</span>
            </Text>
            <FrameVideo embedId={help.url.split(regex)[1]} />
          </>
        )
      }
    </Flex>
  );
};
Info.propTypes = {
  help: propTypes.shape({
    title: propTypes.string.isRequired,
    description: propTypes.string.isRequired,
    url: propTypes.string,
    images: propTypes.shape([]).isRequired,
  }).isRequired,
};

export default Info;
