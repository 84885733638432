import React, { useState } from 'react';
import {
  VStack, Input, Button,
  Table, Tbody, Thead, Th, Td, Tr,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';

import useGetAll from 'hooks/useGetAll';
import { getTrainerClients } from 'services/clients';

import { inputStyle } from 'theme/commonStyles';

import DisplayMessage from './DisplayMessage';

const SearchClient = ({ setClient, setStep }) => {
  const { t } = useTranslation();
  const [nameForSeach, setNameForSearch] = useState('');
  const { id_user } = useSelector(({ user }) => user);
  const { data, isLoading, isError } = useGetAll('clients', id_user, getTrainerClients);
  const handleChange = ({ target }) => setNameForSearch(target.value);
  const handleClick = (name, id) => {
    setClient(`${name}-${id}`);
    setStep((prev) => prev + 1);
  };

  if (isLoading) return <DisplayMessage message={t('loading_clients')} loading />;
  if (isError) return <DisplayMessage message={t('something_wrong')} />;
  return (
    <VStack>
      <Input
        placeholder={t('search_client')}
        onChange={handleChange}
        value={nameForSeach}
        {...inputStyle}
      />
      <>
        {
          data.length === 0
            ? <p>You have no clients yet</p>
            : (
              <Table size="sm" variant="simple" colorScheme="yellow">
                <Thead>
                  <Tr>
                    <Th>{t('table_name')}</Th>
                    <Th>{t('table_gender')}</Th>
                    <Th>{t('table_options')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data
                    .filter((client) => {
                      const regex = new RegExp(nameForSeach, 'i');
                      return regex.test(client.name);
                    })
                    .map((client) => (
                      <Tr key={client._id}>
                        <Td>{client.name}</Td>
                        <Td>{client.gender}</Td>
                        <Td>
                          <Button
                            colorScheme="ptRed"
                            onClick={() => handleClick(client.name, client._id)}
                          >
                            {t('table_select')}
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            )
        }
      </>
    </VStack>
  );
};

SearchClient.propTypes = {
  setClient: propTypes.func.isRequired,
  setStep: propTypes.func.isRequired,
};
export default SearchClient;
