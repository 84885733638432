/* eslint-disable no-useless-escape */
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input } from '@chakra-ui/react';

import { login } from 'services/user';
import { useAuth } from 'utils/authContext';
import { addInfo } from 'reducers/user.reducer';
import notificationDisplay from 'utils/notificationDisplay';
import { loaded, loading } from 'reducers/loader.reducer';
import { getTrainerByUserId } from 'services/trainer';

import { inputStyle } from 'theme/commonStyles';
import styles from './login.module.scss';
import PasswordInput from '../../common/PasswordInput';

const Login = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useAuth();
  const { t } = useTranslation();
  const { from } = { from: { pathname: '/user/clients' } };
  const onSubmit = async (data) => {
    dispatch(loading());
    try {
      const response = await login(data);
      if (response.data) throw new Error(response.data.error);
      localStorage.setItem('userAuth', response.token);
      const trainerInfo = await getTrainerByUserId(response.id_user);
      console.log(response);
      console.log(trainerInfo);
      if (response.role === 'admin' || (response.token && trainerInfo)) {
        const responseToSend = {
          ...response,
          ...trainerInfo,
        };
        console.log('get in response to send', responseToSend);
        dispatch(addInfo(responseToSend));
        auth.signin(() => {
          history.replace(from);
        });
      } else throw new Error('something went wrong');
    } catch (exception) {
      notificationDisplay(dispatch, exception.message, 'error');
    }
    dispatch(loaded());
  };

  return (
    <section className={styles.root}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.sides}>
        <h4 className={`${styles.title} ${styles.left}`}>{t('sign_in')}</h4>
        <Controller
          control={control}
          defaultValue=""
          name="email"
          rules={{
            required: true,
            pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
          }}
          render={({ field }) => (
            <Input
              {...field}
              {...inputStyle}
              placeholder={t('email')}
              mb="0"
            // className={styles.input}
            />
          )}
        />
        {errors.email?.type === 'required' && (
          <span className={styles.message}>{t('error_required')}</span>
        )}
        {errors.email?.type === 'pattern' && (
          <span className={styles.message}>{t('error_email')}</span>
        )}

        <Controller
          control={control}
          defaultValue=""
          name="password"
          rules={{ required: true, minLength: 4, maxLength: 12 }}
          render={({ field }) => (
            <PasswordInput field={field} placeholder={t('password')} />
          )}
        />
        {errors.password?.type === 'required' && (
          <span className={styles.message}>{t('error_required')}</span>
        )}
        {errors.password?.type === 'minLength' && (
          <span className={styles.message}>{t('error_min', { num: 4 })}</span>
        )}
        {errors.password?.type === 'maxLength' && (
          <span className={styles.message}>{t('error_max', { num: 12 })}</span>
        )}
        <a href="https://perfect-trainer.vercel.app/changepass" className={styles.link}>
          {t('forget_password')}
        </a>
        <button type="submit" className={styles.submit}>
          {t('sign_in')}
        </button>
      </form>
      <article className={styles.sides}>
        <p className={styles.title}>{t('create_account')}</p>
        <p>
          {t('login_info_start')}
          <b>
            {' '}
            PERFECT TRAINER
            <sup>&trade;</sup>
          </b>
          {' '}
          {t('login_info_end')}
        </p>

        <Link to="/signup" className={styles.sign}>
          {t('sign_up')}
        </Link>
      </article>
    </section>
  );
};

export default Login;
