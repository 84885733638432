const Button = {
  variants: {
    outline: {
      border: '2px solid',
      borderColor: 'ptRed.500',
      color: 'ptText.500',
    },
    solid: {
      _hover: {
        backgroundColor: 'ptBack.500',
        color: 'ptText.500',
        border: '2px',
        borderColor: 'ptRed.500',
      },
    },
  },
};

export default Button;
