/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import './App.css';
import './theme/index.scss';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import Help from 'domains/help';
import { loading, loaded } from 'reducers/loader.reducer';
import { deleteInfo } from 'reducers/user.reducer';
import { logout } from 'reducers/trainer.reducer';
import { useAuth } from 'utils/authContext';
import notificationDisplay from 'utils/notificationDisplay';
import reLoggedUser from 'utils/reLoggedUser';

import Header from 'components/header';
import Footer from 'components/footer';
import { PrivateRoute } from './utils/authContext';
import Home from './domains/home';
import Notification from './components/common/Notification';
import User from './domains/user';
import Loader from './components/common/Loader';

const App = () => {
  const { message, status } = useSelector(({ notification }) => notification);
  const isLoading = useSelector(({ loader }) => loader);
  const dispatch = useDispatch();
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    history.listen(() => {
      const user = localStorage.getItem('userAuth');
      if (user) {
        const { exp } = jwtDecode(user);
        const expirationTime = (exp * 1000) - 60000;
        if (Date.now() >= expirationTime) {
          notificationDisplay(dispatch, 'Your session has expired', 'error');
          dispatch(deleteInfo());
          dispatch(logout());
          localStorage.removeItem('userAuth');
          auth.signout(() => console.log('signing out through token expiration'));
          history.push('/login');
        }
        console.log(history.location);
      }
    });
  }, []);

  useEffect(() => {
    const user = localStorage.getItem('userAuth');
    if (user) {
      dispatch(loading());
      reLoggedUser(user, dispatch, auth, history);
      dispatch(loaded());
    }
  }, []);

  return (
    <>
      {message && <Notification message={message} status={status} />}
      {isLoading && <Loader />}
      <Header />
      <Switch>
        <PrivateRoute path="/user" children={<User />} />
        <Route path="/help" children={<Help />} />
        <Route path="/" children={<Home />} />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
