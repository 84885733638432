/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, useDisclosure } from '@chakra-ui/react';
import { ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';

import useDelete from 'hooks/useDelete';
import useGetAll from 'hooks/useGetAll';
import { getHelps, deleteHelp } from 'services/helps';

import DisplayMessage from 'components/common/DisplayMessage';
import MaterialTable from 'material-table';
import tableIcons from 'theme/tableIcons';
import DeleteAlert from 'components/common/DeleteAlert';
import localizationMaterialTable from '../../helpers/localizationMaterialTable';
import customFilterAndSearch from '../../helpers/customFilterAndSearch';
import Popup from './Popup';

import styles from './Board.module.scss';

const HelpBoard = ({ category }) => {
  const [mode, setMode] = useState('info');
  const [clientInfo, setClientInfo] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { role } = useSelector(({ user }) => user);
  const { t } = useTranslation();
  const { data, isLoading, isError } = useGetAll('helps', null, () => getHelps(category));
  const lang = window.localStorage.getItem('i18nextLng');

  const handleClose = () => setDialogOpen(false);
  const handleOpen = (client) => {
    setClientInfo(client);
    setDialogOpen(true);
  };
  const onOpenPop = (client, name) => {
    setMode(name);
    setClientInfo(client);
    onOpen();
  };
  const switchMode = (client, type) => {
    setMode(type);
    setClientInfo(client);
  };

  const localization = localizationMaterialTable();

  if (isLoading) return <DisplayMessage message={t('loading_help')} loading />;
  if (isError) return <DisplayMessage message={t('something_wrong')} />;

  const columns = [
    {
      title: t('word'),
      headerStyle: { fontSize: '16px', fontWeight: '600' },
      field: 'title',
      customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'title'),
      render: (rowData) => <h6 style={{ fontSize: '14px' }}>{rowData.title[lang]}</h6>,
    },
    {
      title: t('table_options'),
      cellStyle: { width: '40%' },
      headerStyle: { fontSize: '16px', fontWeight: '600' },
      render: (rowData) => (
        <>
          <IconButton
            fontSize="20px"
            icon={<ViewIcon />}
            onClick={() => onOpenPop(rowData, 'info')}
            variant="link"
          />
          {
            role === 'admin' && (
              <IconButton
                fontSize="20px"
                icon={<DeleteIcon />}
                onClick={() => handleOpen(rowData)}
                variant="link"
              />
            )
          }
        </>
      ),
    },
  ];

  return (
    <>
      <section className={styles.root}>
        <h2 className={styles.root.h2}>{category === 'glossary' ? t('key_terms') : t('user_guide')}</h2>
        <MaterialTable
          style={{ width: '100%' }}
          columns={columns}
          data={data.filter((d) => d.title[lang])}
          icons={tableIcons}
          title=""
          localization={localization}
        />

      </section>
      <Popup
        isOpen={isOpen}
        onClose={onClose}
        other={clientInfo}
        mode={mode}
        title={category === 'glossary' ? t('key_terms') : t('user_guide')}
        category={category}
        switchMode={switchMode}
      />
      {
        dialogOpen && (
          <DeleteAlert
            open={dialogOpen}
            close={handleClose}
            id={clientInfo.id}
            title={t('delete_client')}
            func={() => useDelete('helps', deleteHelp)}
          />
        )
      }
    </>
  );
};
HelpBoard.propTypes = {
  category: propTypes.string.isRequired,
};
export default HelpBoard;
