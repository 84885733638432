/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { bigContainerStyle } from 'theme/commonStyles';

import Selector from './Selector';

const Routines = () => (
  <main style={bigContainerStyle}>
    <Selector />
  </main>
);
export default Routines;
