import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_PROD}/template`,
});

const createTemplates = async (data) => {
  try {
    const token = localStorage.getItem('userAuth');
    const res = await api.post(
      '/',
      data,
      { headers: { authorization: token } },
    );
    return res.data.template;
  } catch (error) {
    throw new Error(error.message);
  }
};

const updateTemplates = async (data, id) => {
  try {
    const token = localStorage.getItem('userAuth');
    const res = await api.put(
      `/${id}`,
      data,
      { headers: { authorization: token } },
    );
    return res.data.template;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getTemplates = async () => {
  try {
    const token = localStorage.getItem('userAuth');
    const res = await api.get(
      '/',
      { headers: { authorization: token } },
    );
    return res.data.templates;
  } catch (error) {
    return error.message;
  }
};

const deleteTemplate = async (id) => {
  try {
    const token = localStorage.getItem('userAuth');
    const res = await api.delete(
      `/${id}`,
      { headers: { authorization: token } },
    );
    return res.data;
  } catch (error) {
    return error.message;
  }
};

export {
  createTemplates,
  getTemplates,
  deleteTemplate,
  updateTemplates,
};
