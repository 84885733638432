import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_PROD}/help/`,
  // baseURL: 'http://localhost:8100/api/help/',
});

const getHelps = async (category) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.get(`/cat/${category}`, { headers: { authorization: token } });
    return response.data.helps;
  } catch (error) {
    return error.response;
  }
};
const createHelp = async (data) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.post('/', data, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const updateHelp = async (id, data) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.put(`/${id}`, data, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return error.response;
  }
};
const deleteHelp = async (id) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.delete(`/${id}`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export {
  getHelps,
  createHelp,
  updateHelp,
  deleteHelp,
};
