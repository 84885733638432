/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import {
  Box, Grid, Flex,
  NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper,
  NumberDecrementStepper,
  VStack,
  Button,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';

import { inputStyle } from 'theme/commonStyles';

import general from './general.module.scss';
import { changeRadioCheckState } from './radiosState';

const General = ({
  setGeneralNote, generalNote, reps, setReps, radios, className, setRadios, radiosDefault, side,
}) => {
  const [addNote, setAddNote] = useState(false);
  const { t } = useTranslation();

  const handleRadioClick = ({ target }) => setRadios((prev) => changeRadioCheckState(prev, target));

  return (
    <>
      <Box className={className.root}>
        {
          radios
            .map((radio) => (
              <input
                className={`${className.check} ${radio.checked ? general.mark : general.great}`}
                id={radio.name}
                key={radio.name}
                name="exercise"
                onClick={handleRadioClick}
                type="radio"
              />
            ))
        }
      </Box>
      <Flex align="center" justify="space-between" fontSize="1.5rem">
        <Flex align="center">
          <label htmlForm="reps" style={{ fontSize: '1.1rem' }}>
            {t('succesful_reps')}
          </label>
          <NumberInput
            backgroundColor="#ececec"
            focusBorderColor="ptRed.500"
            borderRadius="5px"
            color="#444444"
            name="reps"
            ml="0.5rem"
            max={20}
            min={0}
            onChange={(value) => setReps(value)}
            value={reps}
            w="20%"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
        <Button onClick={() => setAddNote((prev) => !prev)} colorScheme="ptRed">
          {
              addNote ? t('hide_general_note') : t('add_general_note')
            }
        </Button>
      </Flex>
      <VStack w="80%">
        <Grid gap={2} w="100%">
          {
            addNote && (
            <>
              <p>{t('notes')}</p>
              <Textarea {...inputStyle} value={generalNote} onChange={({ target }) => setGeneralNote(target.value)} />
            </>
            )
          }
        </Grid>
      </VStack>
    </>
  );
};
General.propTypes = {
  generalNote: propTypes.shape([]).isRequired,
  setGeneralNote: propTypes.func.isRequired,
  reps: propTypes.number.isRequired,
  setReps: propTypes.func.isRequired,
  setRadios: propTypes.func.isRequired,
  radios: propTypes.shape([]).isRequired,
  radiosDefault: propTypes.shape([]).isRequired,
  side: propTypes.string.isRequired,
  className: propTypes.shape({
    root: propTypes.string.isRequired,
    check: propTypes.string.isRequired,
  }).isRequired,
};

export default General;
