import {
  combineReducers,
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
import calendarReducer from 'reducers/calendarReducer';
import uiReducer from 'reducers/uiReducer';
import sessionReducer from 'reducers/sessionReducer';
import exerciseReducer from 'reducers/exercise.reducer';
import loaderReducer from 'reducers/loader.reducer';
import notificationReducer from 'reducers/notification.reducer';
import pictureReducer from 'reducers/picture.reducer';
import trainerReducer from 'reducers/trainer.reducer';
import userReducer from 'reducers/user.reducer';
import customWorkoutReducer from 'reducers/customWorkout.reducer';
// soon to delete
// import customListReducer from 'reducers/customList.reducer';
// end of soon t delete
import cellReducer from 'reducers/cell.reducer';
import weekReducer from 'reducers/weekReducer.reducer';

const rootReducer = combineReducers({
  notification: notificationReducer,
  user: userReducer,
  loader: loaderReducer,
  trainer: trainerReducer,
  exercise: exerciseReducer,
  picture: pictureReducer,
  custom: customWorkoutReducer,
  sessions: sessionReducer,
  // workoutList: customListReducer,
  cell: cellReducer,
  ui: uiReducer,
  calendar: calendarReducer,
  weekList: weekReducer,
});
const composeEnhancers = (typeof window !== 'undefined'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
  // composeWithDevTools(),
);

export default store;
