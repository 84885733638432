/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Basic from './Basic';
import Confirmation from './Confirmation';
import Data from './Data';
import Membership from './Membership';
import { deleteInfo } from '../../../reducers/user.reducer';

const SignUp = ({ location, ...props }) => {
  const [steps, setSteps] = useState(location.state ? location.state.step : 1);
  const [basic, setBasic] = useState(location.state ? { id_user: location.state.id_user } : {});
  const [data, setData] = useState({});
  const [membership, setMembership] = useState({});
  const dispatch = useDispatch();
  console.log('Las props: ', props);

  if (location.state) dispatch(deleteInfo());

  useEffect(() => {
    if (window.location.href.includes('/confirmation')) {
      const user_data = localStorage.getItem('user_data');
      const isSuccess = new URLSearchParams(props.history.location.search).get('success');
      const sessionId = new URLSearchParams(props.history.location.search).get('session_id');
      const isCanceled = new URLSearchParams(props.history.location.search).get('canceled');

      if (isCanceled === 'true' && user_data) {
      // eslint-disable-next-line no-alert
        alert('La compra ha sido cancelada');
        setBasic({ id_user: user_data.id_user });
        // eslint-disable-next-line no-param-reassign
        props.history.push('/signup');
        setSteps(2);
      }

      if (isSuccess === 'true') {
        console.log('sessionId', sessionId);
        localStorage.removeItem('user_data');
        setSteps(4);
      }
    }
  }, [props.history.location.pathname]);

  return (
    <>
      { steps === 1 && <Basic move={setSteps} set={setBasic} info={basic} /> }
      { steps === 2 && (
      <Data
        move={setSteps}
        set={setData}
        info={{ basic, data }}
      />
      )}
      { steps === 3 && (
      <Membership
        move={setSteps}
        set={setMembership}
        info={{ membership, basic }}
      />
      )}
      { steps === 4 && <Confirmation />}
    </>
  );
};

export default SignUp;
