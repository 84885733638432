import React from 'react';
import { Button, Heading } from '@chakra-ui/react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ListDisplay = ({
  list, workout, predefine, lang,
}) => {
  const prop = list[0]?.name ? 'name' : 'workout_name';
  const { t } = useTranslation();
  return (
    <>
      <Heading size="lg" mb="1rem">{t('select_routine', { type: workout })}</Heading>
      {list.map((item) => (
        <Button
          colorScheme="ptRed"
          minWidth="200px"
          mb="0.5rem"
          onClick={() => predefine(item, workout)}
          key={item._id}
        >
          {item[prop][lang]}
        </Button>
      ))}
    </>
  );
};

ListDisplay.propTypes = {
  lang: propTypes.string.isRequired,
  list: propTypes.arrayOf(propTypes.shape({
    name: propTypes.string.isRequired,
  })).isRequired,
  workout: propTypes.string.isRequired,
  predefine: propTypes.func.isRequired,
};

export default ListDisplay;
