/* eslint-disable no-unused-vars */
import React, {
  useState, useReducer, useRef, useEffect,
} from 'react';
import { shape, number } from 'prop-types';
import {
  useDisclosure, Heading, Button, Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import getRoutineSetValues from 'utils/getRoutineSetValues';
import { updateDay } from 'reducers/weekReducer.reducer';
import { addCellValue } from 'reducers/cell.reducer';
import TableDisplay from '../../TableDisplay';
import ExerciseOverlay from '../Features/ExerciseOverlay';
import SetOverlay from '../Features/SetOverlay';

import routineReducer, {
  addRow, initialState, deleteRow, predefineRoutine,
} from '../routineReducer';

const Template = ({
  date, actual, data,
}) => {
  const [state, dispatch] = useReducer(routineReducer, initialState);
  const [sessionSaved, toggleSession] = useReducer((prev) => !prev, false);
  const [position, setPosition] = useState({ rowIdx: null, columnIdx: null });
  const [category, setCategory] = useState('');
  const [sub, setSub] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const reduxDispatch = useDispatch();
  const { t } = useTranslation();
  const finalRef = useRef();

  const handleOpen = (rowIdx, columnIdx, value, categoryValue, subValue) => {
    const valueGotten = value?.en || value;
    console.log('hanldeOpen value', valueGotten);
    setSub(subValue);
    setCategory(categoryValue);
    if (columnIdx !== 'exercise') reduxDispatch(addCellValue(getRoutineSetValues(valueGotten)));
    setPosition({ rowIdx, columnIdx });
    onOpen();
  };
  const handleSave = () => reduxDispatch(updateDay(state.data, date.week, date.day));

  const handleAdd = () => {
    dispatch(addRow());
    toggleSession();
  };
  const handleDelete = (idx) => {
    dispatch(deleteRow(idx));
    toggleSession();
  };

  useEffect(() => {
    console.log('template data', data);
    // if (data === undefined) dispatch(getTemplate());
    if (data?.categoryList.length > 0) dispatch(predefineRoutine(data.categoryList));
    toggleSession();
  }, []);

  useEffect(() => {
    handleSave();
  }, [sessionSaved]);

  return (
    <>
      <div style={{ display: (date.day === actual.day && date.week === actual.week) ? 'block' : 'none' }}>
        <Flex justifyContent="space-around" mb="1rem">
          <Heading>
            {t('workout_title', { day: date.day, week: date.week })}
          </Heading>

          <Button onClick={handleAdd} colorScheme="ptRed">
            {t('add_row')}
          </Button>
        </Flex>
        <TableDisplay
          columns={state.columns}
          data={state.data}
          handleOpen={handleOpen}
          handleDelete={handleDelete}
          ref={finalRef}
          position={position}
        />

        {
          (position.columnIdx === 'exercise' && isOpen) && (
            <ExerciseOverlay
              isOpen={isOpen}
              onClose={onClose}
              position={position}
              dispatch={dispatch}
              final={finalRef}
              category={category}
              save={toggleSession}
              sub={sub}
            />
          )
        }

        {
          (position.columnIdx !== 'exercise' && isOpen) && (
            <SetOverlay
              isOpen={isOpen}
              onClose={onClose}
              position={position}
              dispatch={dispatch}
              final={finalRef}
              save={toggleSession}
            />
          )
        }
      </div>
    </>
  );
};

Template.propTypes = {
  date: shape({
    day: number.isRequired,
    week: number.isRequired,
  }).isRequired,
  actual: shape({
    day: number.isRequired,
    week: number.isRequired,
  }).isRequired,
  data: shape({
    categoryList: shape([]).isRequired,
  }).isRequired,
};

export default Template;
