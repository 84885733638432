const pictureReducer = (state = '', action) => {
  switch (action.type) {
    case 'ADD_PICTURE': return action.data;
    default: return state;
  }
};

const addPicture = (data) => ({ type: 'ADD_PICTURE', data });

export default pictureReducer;

export {
  addPicture,
};
