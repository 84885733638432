import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_PROD}/exercise/`,
});

const getExercises = async () => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.get('/', { headers: { authorization: token } });
    return response.data.exercises;
  } catch (error) {
    return error.response;
  }
};

const getTrainerExercises = async (trainerId) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.get(`/trainer/${trainerId}`, { headers: { authorization: token } });
    return response.data.exercises;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const getEvaluationExercises = async (number) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.get(`/weakness/${number}`, { headers: { authorization: token } });
    return response.data.exercises;
  } catch (error) {
    return error.response;
  }
};

const getAllEvaluationExercises = async () => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.get('/weakness/all', { headers: { authorization: token } });
    return response.data.exercises;
  } catch (error) {
    throw new Error(error);
  }
};

const addExercises = async (data) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.post('/', data, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const updateExercise = async (idExercise, data) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.put(`/${idExercise}`, data, { headers: { authorization: token } });
    return response.data;
  } catch (exception) {
    return exception.response;
  }
};

const deleteExercise = async (idExercise) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.delete(`/${idExercise}`, { headers: { authorization: token } });
    return response.data;
  } catch (exception) {
    return exception.response;
  }
};

export {
  getExercises,
  addExercises,
  updateExercise,
  deleteExercise,
  getTrainerExercises,
  getEvaluationExercises,
  getAllEvaluationExercises,
};
