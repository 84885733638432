import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@chakra-ui/react';
import styles from './Profile.module.scss';

const Info = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.avatarContainer}>
        <Avatar
          src={data.pic || ''}
          name={data.name}
          size="xl"
          className={styles.avatar}
        />
      </div>
      <h3 className={styles.title}>{t('profile_name', { name: data.name })}</h3>

      <article className={styles.sides}>
        <h4 className={styles.subtitle}>
          {t('profile_info', { name: data.name })}
        </h4>
        <p>
          <span className={styles.tag}>{t('profile_speciality')}</span>
          {': '}
          {data.speciality}
        </p>
        <p>
          <span className={styles.tag}>
            {t('phonenumber')}
            :
          </span>
          {data.phone}
        </p>
        <p>
          <span className={styles.tag}>{t('job_description')}</span>
          {': '}
          {data.job_description}
        </p>
      </article>

      <article className={styles.sides}>
        <h4 className={styles.subtitle}>
          {t('profile_address', { name: data.name })}
        </h4>
        <address>
          <span className={styles.tag}>{t('state')}</span>
          {': '}
          {data.address.state}
        </address>
        <address>
          <span className={styles.tag}>{t('city')}</span>
          {': '}
          {data.address.city}
        </address>
        <address>
          <span className={styles.tag}>{t('principal_address')}</span>
          {': '}
          {data.address.street1}
        </address>
        {data.address.street2 && (
          <address>
            <span className={styles.tag}>{t('secondary_address')}</span>
            {': '}
            {data.address.street2}
          </address>
        )}
        <address>
          <span className={styles.tag}>{t('zip')}</span>
          {': '}
          {data.address.zip}
        </address>
      </article>
    </>
  );
};

Info.propTypes = {
  data: propTypes.shape({
    name: propTypes.string.isRequired,
    address: propTypes.shape({
      state: propTypes.string.isRequired,
      city: propTypes.string.isRequired,
      street1: propTypes.string.isRequired,
      street2: propTypes.string.isRequired,
      zip: propTypes.string.isRequired,
    }).isRequired,
    speciality: propTypes.string.isRequired,
    phone: propTypes.string.isRequired,
    job_description: propTypes.string.isRequired,
    pic: propTypes.string.isRequired,
  }).isRequired,
};

export default Info;
