import React from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalCloseButton, ModalBody,
} from '@chakra-ui/react';
import propTypes from 'prop-types';

import Info from './Info';
import Edit from './Edit';
import AddExercise from './AddExercise';

const Popup = ({
  exercise, isOpen, onClose, mode,
}) => {
  const lang = window.localStorage.getItem('i18nextLng');
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{mode === 'add' ? 'AddExercise' : exercise.name[lang]}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {
          mode === 'edit' && <Edit exercise={exercise} />
        }
          {
          mode === 'info' && <Info exercise={exercise} />
        }
          {
          mode === 'add' && <AddExercise />
        }
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

Popup.propTypes = {
  exercise: propTypes.shape({
    name: propTypes.string.isRequired,
  }).isRequired,
  isOpen: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  mode: propTypes.string.isRequired,
};
export default Popup;
