const trainerReducer = (state = {}, action) => {
  switch (action.type) {
    case 'TRAINER_LOGIN': return action.data;
    case 'TRAINER_LOGOUT': return {};
    default: return state;
  }
};

const login = (data) => ({ type: 'TRAINER_LOGIN', data });
const logout = () => ({ type: 'TRAINER_LOGOUT' });

export {
  login,
  logout,
};

export default trainerReducer;
