/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, {
  useReducer, useState, useRef, useEffect,
} from 'react';
import {
  Flex, Heading, useDisclosure, Button,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { string, func } from 'prop-types';

import { getAllEvaluationExercises } from 'services/exercise';
import { createRoutine } from 'services/customWorkout';
import { getFunctionaProgramModel } from 'utils/exerciseModel';
import notificationDisplay from 'utils/notificationDisplay';
import useLang from 'hooks/useLang';

import TableDisplay from 'components/routines/TableDisplay';
import RoutineInfo from 'components/routines/workout/Features/RoutineInfo';
import hybridReducer, { initialState, deleteRow, updateData } from './hybridReducer';
import ProgramExercises from '../Features/ProgramExercises';
import ProgramValue from '../Features/ProgramValue';

const isEmpty = (data) => {
  const list = ['Core posterior', 'Escapular empuje', 'Rigidez core', 'Rotadores externos cadera', 'Escapular tracción', 'Core anterior'];

  return data
    .reduce((total, { exercise }) => list.indexOf(exercise) === -1, false);
};

const HybridProgram = ({ client, setStep }) => {
  const [state, dispatch] = useReducer(hybridReducer, initialState);
  const [isBtnLoading, toggleBtn] = useReducer((prev) => !prev, false);
  const [sessionSaved, toggleSession] = useReducer((prev) => !prev, false);
  const [position, setPosition] = useState({ rowIdx: null, columnIdx: null });
  const [exercise, setExercise] = useState('');
  const [value, setValue] = useState('');
  const [weakness, setWeakness] = useState(0);

  const [name, setName] = useState('');
  const [date, setDate] = useState('');

  const { _id: trainer } = useSelector(({ user }) => user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef();
  const reduxDispatch = useDispatch();
  const { t, lang } = useLang();

  let response = '';

  const handleOpen = (rowIdx, columnIdx) => {
    setPosition({ rowIdx, columnIdx });
    onOpen();
  };
  const handleDelete = (idx) => {
    dispatch(deleteRow(idx));
    toggleSession();
  };

  const handleSave = async () => {
    if (!name) return notificationDisplay(dispatch, t('routine_name_req'), 'error');
    const dataToSend = {
      source: 'hybrid_program',
      client: client.split('-')[1],
      trainer,
      name,
      date,
      sessions: state.data,
    };

    try {
      response = await createRoutine(getFunctionaProgramModel(dataToSend));
      notificationDisplay(reduxDispatch, t('succesful_addition'), 'success');
      setStep((prev) => prev - 1);
    } catch (e) {
      console.log(e.message);
      return notificationDisplay(dispatch, e.message, 'error');
    }
  };

  const handleGenerateProgram = async () => {
    toggleBtn();
    const generateRandom = (size) => Math.floor(Math.random() * size);
    const fillFields = (data, exercises) => {
      console.log('lang', lang);
      data.forEach((item, idx) => {
        const weakList = exercises.filter((exer) => exer.weakness === item.weakness);
        if (item.traditional) {
          const weakListFiltered = weakList.filter((w) => w.category_id[0].name === 'Traditional');
          const number = generateRandom(weakListFiltered.length);
          const selected = weakListFiltered[number];
          console.log('selected traditional', selected);
          return dispatch(updateData(selected.name[lang], idx, 'exercise', selected._id));
        }
        const number = generateRandom(weakList.length);
        const selected = weakList[number];
        dispatch(updateData(selected.name[lang], idx, 'exercise', selected._id));
      });
    };

    try {
      const allExercises = await getAllEvaluationExercises();
      console.log(allExercises);
      fillFields(state.data, allExercises);
      toggleBtn();
    } catch (e) {
      toggleBtn();
      return notificationDisplay(reduxDispatch, t('something_wrong', 'error'));
    }
  };

  // set random exercieses in the field in the first render
  useEffect(() => {
    handleGenerateProgram();
  }, []);

  useEffect(() => {
    if (position.columnIdx && position.columnIdx !== 'exercise') {
      setValue(state.data[position.rowIdx][position.columnIdx]);
      console.log('state', state.data[position.rowIdx][position.columnIdx]);
    }
    if (position.columnIdx && position.columnIdx === 'exercise') {
      setExercise(state.data[position.rowIdx][position.columnIdx]);
      setWeakness(state.data[position.rowIdx].weakness);
    }
  }, [position]);

  return (
    <>
      <div style={{ width: '90%' }}>
        <RoutineInfo
          name={name}
          setName={setName}
          date={date}
          setDate={setDate}
        />
        <Heading>
          {t('hybrid_program')}
        </Heading>
        <Button colorScheme="ptRed" mb="0.5rem" onClick={handleGenerateProgram} isLoading={isBtnLoading}>
          {t('generate_program')}
        </Button>
        <TableDisplay
          columns={state.columns}
          data={state.data}
          handleOpen={handleOpen}
          handleDelete={handleDelete}
          ref={finalRef}
          position={position}
        />
      </div>
      {
        (position.columnIdx === 'exercise' && isOpen) && (
          <ProgramExercises
            isOpen={isOpen}
            onClose={onClose}
            final={finalRef}
            position={position}
            defaultValue={exercise}
            setDefault={setExercise}
            dispatch={dispatch}
            weakness={weakness}
            traditional={state.data[position.rowIdx].traditional}
          />
        )
      }
      {
        (position.columnIdx !== 'exercise' && isOpen) && (
          <ProgramValue
            position={position}
            onClose={onClose}
            isOpen={isOpen}
            defaultValue={value}
            setDefault={setValue}
            dispatch={dispatch}
          />
        )
      }
      {
        isEmpty(state.data) && (
          <Flex>
            <Button colorScheme="ptRed" onClick={handleSave}>
              Save Program
            </Button>
          </Flex>
        )
      }
    </>
  );
};

HybridProgram.propTypes = {
  client: string.isRequired,
  setStep: func.isRequired,
};
export default HybridProgram;
