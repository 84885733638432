import { useEffect, useState } from 'react';

const useIteration = (list, start = 0, end = 5) => {
  const [items, setItems] = useState(list);
  const [steps, setSteps] = useState({ start, end });

  const next = () => setSteps((prev) => ({ start: prev.start + 1, end: prev.end + 1 }));
  const previous = () => setSteps((prev) => ({ start: prev.start - 1, end: prev.end - 1 }));

  useEffect(() => {
    setItems(list);
  }, [list]);

  useEffect(() => {
    setItems(list.slice(steps.start, steps.end));
  }, [steps]);

  return [
    items,
    next,
    previous,
    steps,
  ];
};

export default useIteration;
