import React from 'react';
import styles from './Footer.module.css';
import Flyer from './flyer';
import Copyright from './copyright';
import Info from './info';

const Footer = () => (
  <footer className={styles.root}>
    <Flyer />
    <Info />
    <Copyright />
  </footer>
);

export default Footer;
