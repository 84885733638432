import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './Flyer.module.css';

const Item = ({ title, text, icon }) => (
  <article className={styles.item}>
    <div className={styles.icon}>
      {icon}
    </div>
    <h3 className={styles.title}>{title}</h3>
    <p className={styles.text}>{text}</p>
  </article>
);

const Flyer = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.root}>
      <Item title={t('contact')} text="(800) 555-1234" icon={<i className="fas fa-phone-alt" />} />
      <Item title={t('events')} text="IHP CPT Seminar 8/12/2021" icon={<i className="far fa-calendar-alt" />} />
      <Item title={t('opportunities')} text="info@perfectrainer.com" icon={<i className="fas fa-globe" />} />
    </section>
  );
};

Item.propTypes = {
  title: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  icon: propTypes.node.isRequired,
};

export default Flyer;
