/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
const initialState = [];

const addSessionToState = (state, data) => {
  const newSession = {
    number: state.length + 1,
    exercises: data,
  };

  return state.concat(newSession);
};

const deleteSessionFromState = (state, idx) => state
  .filter(({ number }) => number !== idx)
  .map((day, index) => ({ ...day, number: index + 1 }));

const updateSessionState = (state, idx, newData) => {
  const objToUpdate = state.find((session) => session.number === idx);
  const newObjValue = {
    ...objToUpdate,
    exercises: newData,
  };

  return state
    .map((session) => (
      session.number === newObjValue.number
        ? newObjValue
        : session
    ));
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_SESSION': return [];
    case 'ADD_SESSION': return addSessionToState(state, action.data);
    case 'DELETE_SESSION':
      console.log(deleteSessionFromState(state, action.idx));
      return deleteSessionFromState(state, action.idx);
    case 'UPDATE_SESSION': return updateSessionState(state, action.idx, action.data);
    default: return state;
  }
};

const addSession = (data) => ({ type: 'ADD_SESSION', data });
const deleteSession = (idx) => ({ type: 'DELETE_SESSION', idx });
const resetSession = () => ({ type: 'RESET_SESSION' });
const updateSession = (idx, data) => ({ type: 'UPDATE_SESSION', idx, data });

export default sessionReducer;
export {
  addSession,
  deleteSession,
  resetSession,
  updateSession,
};
