import types from '../types/types';
// {
//   id: 'fggthrth',
//   title: 'Cumpleaños de Maria',
//   start: moment().toDate(),
//   end: moment().add(2, 'hours').toDate(),
//   notes: 'Comprar pastel',
//   user: {
//     _id: '123',
//     name: 'Pedrito',
//   },
// },
const initialState = {
  events: [],
  activeEvent: null,
  eventSetSlotEmpty: null,
};
const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.eventAddNew:
      return {
        ...state,
        events: [...state.events, action.payload],
      };

    case types.eventSetActive:
      return {
        ...state,
        activeEvent: action.payload,
      };
    case types.eventSetSlotEmpty:
      return {
        ...state,
        eventSetSlotEmpty: action.payload,
      };
    case types.eventClearActiveEvent:
      return {
        ...state,
        activeEvent: null,
      };

    case types.eventUpdated:
      return {
        ...state,
        events: state.events.map((e) => (e.id === action.payload.id ? action.payload : e)),
      };
    case types.eventDeleted:
      return {
        ...state,
        events: state.events.filter((e) => e.id !== state.activeEvent.id),
        activeEvent: null,
      };
    case types.eventLoaded:
      return {
        ...state,
        events: [...action.payload],
      };

    case types.eventLogout:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default calendarReducer;
