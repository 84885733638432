import React, { useEffect } from 'react';
import { Button, Heading, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';

import { resetWeeks } from 'reducers/weekReducer.reducer';

const buttonStyles = {
  colorScheme: 'ptRed',
  padding: '1.5rem 0',
  width: [200, undefined, 300],
};

const Type = ({ setValue, setStep }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClick = ({ target }) => {
    setValue(target.id);
    setStep((prev) => prev + 1);
  };

  useEffect(() => {
    dispatch(resetWeeks());
  }, []);

  return (
    <VStack>
      <Heading textAlign="center" mb="1rem">{t('select_workout')}</Heading>
      <Button
        onClick={handleClick}
        id="template"
        {...buttonStyles}
      >
        {t('template_workout')}
      </Button>
      <Button
        onClick={handleClick}
        id="preprogrammed"
        {...buttonStyles}
      >
        {t('preprogrammed_workout')}
      </Button>
      <Button
        onClick={handleClick}
        id="custom"
        {...buttonStyles}
      >
        {t('custom_workout')}
      </Button>
    </VStack>
  );
};

Type.propTypes = {
  setValue: propTypes.func.isRequired,
  setStep: propTypes.func.isRequired,
};

export default Type;
