/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useReducer, useState } from 'react';
import {
  Flex, IconButton, Checkbox, Grid, GridItem,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import {
  func, number, shape, bool, string,
} from 'prop-types';

import useLang from 'hooks/useLang';
import { updateCompletedDay } from 'services/customWorkout';

import ExerciseLine from './Features/ExerciseLine';
import { basicFlex, extraFlex } from './styles';
import NoteLine from './Features/NoteLine';

const RoutineCard = ({
  handleOpen, actual, handleChangeStyle, isExtended, session,
}) => {
  const [isCompleted, toggleCompleted] = useReducer((prev) => !prev, session.completed);
  const { t, lang } = useLang();
  const handleComplete = () => {
    toggleCompleted();
    updateCompletedDay(session._id);
  };
  return (
    <Flex
      {...(isExtended && session.day === actual.day && session.week === actual.week ? extraFlex : basicFlex)}
      onClick={() => handleChangeStyle(session.day, session.week)}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Checkbox colorScheme="red" onChange={handleComplete} isChecked={isCompleted}>Completed</Checkbox>
        <IconButton
          icon={<CopyIcon />}
          alignSelf="flex-end"
          colorScheme="ptRed"
          w="30px"
          onClick={(e) => {
            e.stopPropagation();
            handleOpen(session.exercises, session.day, session.week);
          }}
        />

      </Flex>
      <p style={{
        alignSelf: 'center', color: '#b42519', fontWeight: 'bold',
      }}
      >
        {t('workout_title', { day: session.day, week: session.week })}
      </p>
      <Grid templateColumns=" 45% repeat(5, 10%)" autoRows w="100%" gap={1}>
        <GridItem as="b">
          {t('exercise')}
        </GridItem>
        <GridItem as="b">
          Set 1
        </GridItem>
        <GridItem as="b">
          Set 2
        </GridItem>
        <GridItem as="b">
          Set 3
        </GridItem>
        <GridItem as="b">
          Set 4
        </GridItem>
        <GridItem as="b">
          Set 5
        </GridItem>
        {
          session.exercises.map(({ id_exercise, sets, _id }) => (
            <ExerciseLine id_exercise={id_exercise} sets={sets} lang={lang} key={_id} />
          ))
        }
      </Grid>
      {/* {session.exercises.map(({ id_exercise, sets, _id }) => (
        <ExerciseLine id_exercise={id_exercise} sets={sets} lang={lang} key={_id} />
      ))} */}
      {
        session?.notes[lang] && <NoteLine note={session.notes[lang]} />
      }
    </Flex>
  );
};

RoutineCard.propTypes = {
  notes: string.isRequired,
  day: number.isRequired,
  week: number.isRequired,
  exercises: shape([]).isRequired,
  handleOpen: func.isRequired,
  handleChangeStyle: func.isRequired,
  sessionId: string.isRequired,
  actual: shape({}).isRequired,
  date: shape({
    day: number.isRequired,
    week: number.isRequired,
  }).isRequired,
  isExtended: bool.isRequired,
  completed: bool.isRequired,
  session: shape({}).isRequired,
};

export default RoutineCard;
