import CalendarScreen from 'components/calendar/CalendarScreen';
import React from 'react';

const Day = () => (
  <>
    <CalendarScreen category="day" />
  </>
);

export default Day;
