const loaderReducer = (state = false, action) => {
  switch (action.type) {
    case 'LOADING': return true;
    case 'LOADED': return false;
    default: return state;
  }
};

const loading = () => ({ type: 'LOADING' });
const loaded = () => ({ type: 'LOADED' });

export default loaderReducer;

export {
  loading,
  loaded,
};
