const columns = [
  {
    Header: { en: 'Exercise', es: 'Ejercicio' },
    accessor: 'exercise',
  },
  {
    Header: { en: 'Sub', es: 'Sub' },
    accessor: 'sub',
  },
  {
    Header: { en: 'Set 1', es: 'Set 1' },
    accessor: 'set1',
  },
  {
    Header: { en: 'Set 2', es: 'Set 2' },
    accessor: 'set2',
  },
  {
    Header: { en: 'Set 3', es: 'Set 3' },
    accessor: 'set3',
  },
  {
    Header: { en: 'Set 4', es: 'Set 4' },
    accessor: 'set4',
  },
  {
    Header: { en: 'Set 5', es: 'Set 5' },
    accessor: 'set5',
  },
  {
    Header: { en: 'Option', es: 'Opción' },
    accessor: 'opt',
  },
  {
    Header: { en: 'Category', es: 'Categoria' },
    accessor: 'category',
  },
  {
    Header: { en: 'id', es: 'id' },
    accessor: 'id',
  },
];

const data = [
  {
    exercise: { en: 'Add exercise', es: 'Agregar ejercicio' },
    sub: 'All',
    set1: { en: 'load/reps', es: 'carga/reps' },
    set2: { en: 'load/reps', es: 'carga/reps' },
    set3: { en: 'load/reps', es: 'carga/reps' },
    set4: { en: 'load/reps', es: 'carga/reps' },
    set5: { en: 'load/reps', es: 'carga/reps' },
    opt: 'delete',
    category: 'All',
    id: '',
  },
];

// const basicModel = {
//   columns,
//   data,
// };

const initialState = {
  columns,
  data,
};

const getBasicValue = (sets) => Array.from(Array(5))
  .reduce((final, curr, idx) => {
    const setProp = `set${idx + 1}`;
    const setValue = sets[idx]?.set_number ? `${sets[idx].load}${sets[idx].typeWeight}/${sets[idx].reps}` : { en: 'load/reps', es: 'carga/reps' };
    return {
      ...final,
      [setProp]: setValue,
    };
  }, {});
const getRows = (item, lang) => (item.map((i) => {
  console.log('getRows', i);
  return ({
    exercise: i?.id_exercise?.name[lang] || `${i.category.name}-${i.sub}`,
    sub: i?.sub || 'All',
    ...getBasicValue(i.sets),
    opt: 'delete',
    category: i?.category?.name || 'All',
    id: i?.id_exercise?._id || '',
  });
}));

const makeRoutine = (sessions, lang = 'en') => ({
  columns,
  data: getRows(sessions, lang),
});

const routineReducer = (state, action) => {
  const {
    type, value, rowIdx, columnIdx, lang, sessions,
  } = action;
  switch (type) {
    case 'INITIAL_ROUTINE': return initialState;
    case 'PREDEFINE_ROUTINE': return makeRoutine(sessions, lang);
    case 'ADD_ROW': return {
      ...state,
      data: [
        ...state.data, {
          ...data[0],
        },
      ],
    };
    case 'DELETE_ROW': return {
      ...state,
      data: state.data.filter((row, index) => index !== +value),
    };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: state.data.map((row, index) => {
          if (index === rowIdx) {
            return {
              ...state.data[rowIdx],
              [columnIdx]: value,
            };
          }
          return row;
        }),
      };
    default: return state;
  }
};

const addRow = () => ({ type: 'ADD_ROW' });
const deleteRow = (value) => ({ type: 'DELETE_ROW', value });
const getEmptyInitialRoutine = () => ({ type: 'INITIAL_ROUTINE' });
const predefineRoutine = (sessions, lang) => ({ type: 'PREDEFINE_ROUTINE', sessions, lang });
const updateData = (value, rowIdx, columnIdx, exerciseIdx) => ({
  type: 'UPDATE_DATA', value, rowIdx, columnIdx, exerciseIdx,
});

export default routineReducer;

export {
  initialState,
  addRow,
  deleteRow,
  updateData,
  getEmptyInitialRoutine,
  predefineRoutine,
};
