/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import { Button, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import propTypes from 'prop-types';

import useLang from 'hooks/useLang';
import { startBio } from 'services/evaluations';
import notificationDisplay from 'utils/notificationDisplay';

import styles from './Welcome.module.scss';

const Welcome = ({ set, client, setBio }) => {
  const [btnLoading, setBtnloading] = useState(false);
  const { t } = useLang();
  const { _id } = useSelector(({ user }) => user);
  const dispatch = useDispatch();

  const handleNext = async () => {
    const biomechanical = {
      id_trainer: _id,
      id_clienct: client,
      date: dayjs(new Date()).set('MM-DD-YYYY'),
      evaluations: [],
    };

    try {
      setBtnloading(true);
      const res = await startBio(biomechanical);
      console.log(res);
      if (res.data) throw new Error(res.data.message);
      notificationDisplay(dispatch, t('bio_start'), 'success');
      setBio(res);
      set((prev) => prev + 1);
      console.log(res);
    } catch (exception) {
      notificationDisplay(dispatch, exception.message, 'error');
    }
    setBtnloading(false);
  };
  return (
    <Flex className={styles.root}>
      <Button
        onClick={handleNext}
        type="button"
        colorScheme="ptRed"
        isLoading={btnLoading}
        loadingText={t('creating_bio')}
      >
        {t('start_evaluation')}
      </Button>
    </Flex>
  );
};

Welcome.propTypes = {
  set: propTypes.func.isRequired,
  setBio: propTypes.func.isRequired,
  client: propTypes.string.isRequired,
};

export default Welcome;
