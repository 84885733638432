/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import propTypes from 'prop-types';
import {
  Button,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  Input, InputGroup, InputRightElement,
  Flex,
} from '@chakra-ui/react';
// import { useDispatch } from 'react-redux';
// import { updateCustomData } from 'reducers/customWorkout.reducer';
import { useSelector } from 'react-redux';
import { updateData } from '../FunctionalProgram/functionalReducer';

const inputStyle = {
  backgroundColor: '#ececec',
  border: '1px solid #d6d6d6',
  borderRadius: '5px',
  color: '#444',
  fontWeight: 400,
  focusBorderColor: '#b42519',
  letterSpacing: '1px',
  marginBottom: '1rem',
  padding: '0.5rem',
  width: '100%',
  transition: 'border 2s cubic-bezier(0.075, 0.82, 0.165, 1)',
};

const ProgramValue = ({
  onClose, isOpen, position: { rowIdx, columnIdx }, dispatch, final, defaultValue, setDefault,
}) => {
  const initialRef = useRef();

  const handleChangeValue = ({ target }) => {
    setDefault(target.value);
  };
  const handleClick = (e) => {
    e.preventDefault();
    console.log('pre', defaultValue);
    dispatch(updateData(defaultValue, rowIdx, columnIdx));
    console.log('after', defaultValue);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef} finalFocusRef={final}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Set the exercise&apos;s load and reps
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form>
            <Input
              onChange={handleChangeValue}
              ref={initialRef}
              type="number"
              value={defaultValue}
              {...inputStyle}
            />
            <Flex justify="end" mt="1rem">
              <Button colorScheme="ptRed" onClick={handleClick} type="submit">
                Set
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ProgramValue.defaultProps = {
  final: null,
};

ProgramValue.propTypes = {
  save: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  position: propTypes.shape({
    rowIdx: propTypes.number.isRequired,
    columnIdx: propTypes.string.isRequired,
  }).isRequired,
  final: propTypes.node,
};

export default ProgramValue;
