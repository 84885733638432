/* eslint-disable no-unused-vars */
import React, {
  useState, useReducer, useCallback, useMemo,
} from 'react';
import SearchClient from 'components/common/SearchClient';

import Report from './Report';
import Selector from './Selector';
import Welcome from './Welcome';

import evaluationReducer, { initialState } from './evaluationReducer';
import ProgramCreation from './ProgramCreation';

const Container = () => {
  const [step, setStep] = useState(1);
  const [client, setClient] = useState('');
  const [bio, setBio] = useState('');
  const [evaluations, setEvaluations] = useState([]);
  const [program, setProgram] = useState('');
  const [state, dispatch] = useReducer(evaluationReducer, initialState);

  return (
    <>
      {
        step === 1 && <Welcome set={setStep} client={client} setBio={setBio} />
      }
      {
        step === 2 && <SearchClient setClient={setClient} setStep={setStep} />
      }
      {
        step === 3 && (
        <Selector
          setStep={setStep}
          state={state}
          dispatch={dispatch}
          setEvaluations={setEvaluations}
          evaluations={evaluations}
        />
        )
      }
      {
        step === 4 && (
        <Report
          client={client}
          evaluations={state}
          dispatch={dispatch}
          setProgram={setProgram}
          setStep={setStep}
        />
        )
      }
      {
        step === 5 && <ProgramCreation program={program} client={client} setStep={setStep} />
      }
    </>
  );
};

export default Container;
