import React from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalCloseButton, ModalBody, IconButton,
} from '@chakra-ui/react';
import { EditIcon, ViewIcon } from '@chakra-ui/icons';
import propTypes from 'prop-types';

import AddClient from './AddClient';
import Info from './Info';
import Edit from './Edit';
import Routine from './routine';

const Popup = ({
  onClose, isOpen, title, mode, other, switchMode,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent
      maxWidth="60rem"
      backgroundColor={mode === 'routine' ? undefined : '#252625'}
      color={mode === 'routine' ? undefined : '#ededed'}
    >
      <ModalHeader borderBottom="3px solid #b42519">
        {title}
        {' '}
        {
          mode === 'info' && (
            <IconButton
              variant="link"
              icon={<EditIcon />}
              onClick={() => switchMode(other, 'edit')}
              fontSize="20px"
            />
          )
        }
        {
          mode === 'edit' && (
            <IconButton
              variant="link"
              icon={<ViewIcon />}
              onClick={() => switchMode(other, 'info')}
              fontSize="20px"
            />
          )
        }
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody borderBottom="3px solid #b42519" borderRadius="5px">
        {
          mode === 'add' && <AddClient close={onClose} />
        }
        {
          mode === 'info' && <Info client={other} />
        }
        {
          mode === 'edit' && <Edit client={other} close={onClose} />
        }
        {
          mode === 'routine' && <Routine idClient={other._id} />
        }
      </ModalBody>
    </ModalContent>
  </Modal>
);

Popup.defaultProps = {
  other: [],
};

Popup.propTypes = {
  onClose: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  title: propTypes.string.isRequired,
  mode: propTypes.string.isRequired,
  other: propTypes.shape([]),
  switchMode: propTypes.func.isRequired,
};

export default Popup;
