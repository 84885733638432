/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDisclosure, IconButton } from '@chakra-ui/react';
import { EditIcon, ViewIcon } from '@chakra-ui/icons';
import MaterialTable from 'material-table';

import DisplayMessage from 'components/common/DisplayMessage';
import useLang from 'hooks/useLang';
import useGetAll from 'hooks/useGetAll';
import Fetch from 'components/common/Fetch';

import { getExercises, getTrainerExercises } from 'services/exercise';
import localizationMaterialTable from '../../helpers/localizationMaterialTable';
import customFilterAndSearch from '../../helpers/customFilterAndSearch';

import style from './Board.module.scss';

import Popup from './Popup';
import tableIcons from '../../theme/tableIcons';
import Options from './Options';

const Board = () => {
  const { id_user, role } = useSelector(({ user }) => user);
  const { t, lang } = useLang();
  const {
    isLoading, isError, isSuccess, data, error,
  } = useGetAll('exercises', null, () => getTrainerExercises(id_user));

  const [exerciseData, setExerciseData] = useState({});
  const [mode, setMode] = useState('info');
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const errorObj = error.message ? JSON.parse(error.message) : {};

  const onClick = (exercise, type) => {
    setExerciseData(exercise);
    setMode(type);
    onOpen();
  };

  const localization = localizationMaterialTable();

  const columns = [
    {
      title: t('table_name'),
      headerStyle: { fontSize: '16px', fontWeight: '600' },
      field: `name[${lang}]`,
      customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'name', lang),
      render: (rowData) => <h6 style={{ fontSize: '14px' }}>{rowData.name[lang]}</h6>,
    },
    {
      title: t('table_action'),
      headerStyle: { fontSize: '16px', fontWeight: '600' },
      field: `action[${lang}]`,
      customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'action', lang),
      render: (rowData) => <h6 style={{ fontSize: '14px' }}>{rowData.action[lang]}</h6> },
    {
      title: t('table_category'),
      headerStyle: { fontSize: '16px', fontWeight: '600' },
      field: 'category_id',
      filtering: false,
      render: (rowData) => <span style={{ fontSize: '14px' }}>{rowData.category_id[0].name}</span> },
    {
      title: t('table_muscle'),
      field: `muscle_system[${lang}]`,
      filtering: false,
      headerStyle: { fontSize: '16px', fontWeight: '600' },
      render: (rowData) => rowData.muscle_system[lang].map((elem) => (
        <p style={{ fontSize: '14px' }}>
          {elem}
          <br />
        </p>
      )),
    },
    {
      title: t('table_options'),
      headerStyle: { fontSize: '16px', fontWeight: '600' },
      render: (rowData) => (
        <>
          <IconButton aria-label="display exercisePopup" colorScheme="yellow" fontSize="1.5rem" icon={<ViewIcon />} onClick={() => onClick(rowData, 'info')} variant="ghost" />
          {role === 'admin' && (
            <IconButton aria-label="edit exercise info" colorScheme="yellow" fontSize="1.5rem" icon={<EditIcon />} onClick={() => onClick(rowData, 'edit')} variant="ghost" />
          )}
        </>
      ),
    },
  ];
  if (isLoading) return <DisplayMessage message={t('loading_exercises')} loading />;
  if (isError) return <DisplayMessage message={t('something_wrong')} />;
  return (
    <>
      {
      (data && isSuccess) && (
        <section className={style.root}>
          <Options onClick={onClick} />
          {/* Material-table implementation for exercises */}
          <MaterialTable
            style={{ width: '100%' }}
            className={style.table}
            icons={tableIcons}
            columns={columns}
            data={data.filter((d) => d.name[lang])}
            title={t('exercisesDB')}
            localization={localization}
          />
          {
            isOpen && (
              <Popup
                exercise={exerciseData}
                isOpen={isOpen}
                onClose={onClose}
                mode={mode}
              />

            )
        }
        </section>
      )
  }
    </>
  );
};

export default Board;
