/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import propTypes from 'prop-types';
import {
  HStack, VStack,
  Button, ButtonGroup,
  Heading,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import General from './pictures/General';

const SingleEvaluation = ({
  title, local, legRep, notes, side, move, radios, className, general,
}) => {
  const [generalNote, setGeneralNote] = useState(general === undefined ? '' : general[side]);
  const [point, setPoint] = useState(radios.map((radio) => notes?.find((note) => (note.name === radio.name && note.side === side)) || radio));
  const [reps, setReps] = useState(legRep || 0);
  const { t } = useTranslation();

  return (
    <VStack
      backgroundColor="#252625"
      border="3px solid #b42519"
      borderRadius="10px"
      color="#ececec"
      py="1rem"
    >
      <HStack>
        <ButtonGroup variant="link" colorScheme="red">
          {local !== 1 && <Button onClick={() => move(point, generalNote, reps, side, local, 'prev')}>{t('prev_eval')}</Button>}
          <Heading size={['sm', 'md', 'lg']} textAlign="center">{title}</Heading>
          {
            local === 11
              ? <Button onClick={() => move(point, generalNote, reps, side, local, 'save')}>{t('save_eval')}</Button>
              : <Button onClick={() => move(point, generalNote, reps, side, local, 'next')}>{t('next_eval')}</Button>
          }
        </ButtonGroup>
        <InfoIcon />
      </HStack>
      <General
        reps={reps}
        setReps={setReps}
        radios={point}
        setRadios={setPoint}
        generalNote={generalNote}
        setGeneralNote={setGeneralNote}
        className={className}
        radiosDefault={notes}
        side={side}
      />
    </VStack>
  );
};

SingleEvaluation.propTypes = {
  general: propTypes.string.isRequired,
  move: propTypes.func.isRequired,
  title: propTypes.string.isRequired,
  local: propTypes.number.isRequired,
  legRep: propTypes.number.isRequired,
  notes: propTypes.shape([]).isRequired,
  side: propTypes.string.isRequired,
  radios: propTypes.shape([]).isRequired,
  className: propTypes.shape({}).isRequired,
};

export default SingleEvaluation;
