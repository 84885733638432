import React from 'react';
import styles from './copyright.module.css';

const Copyright = () => (
  <div className={styles.root}>
    <small className={styles.text}>
      Copyright &copy; 2022 The Institute Of Human Performance | All Rights Reserved
      Web Created By
      {' '}
      <a href="http://jamtechcorp.com/" className={styles.link} target="_blank" rel="noreferrer">JAM TECH C.A.</a>
      {' | '}
      <a href="/" className={styles.link}>HOME</a>
      {' | '}
      <a href="/login" className={styles.link}>LOGIN</a>
      {' | '}
      <a href="/signup" className={styles.link}>SIGNUP</a>
    </small>
  </div>
);

export default Copyright;
