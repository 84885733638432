import React from 'react';
import propTypes from 'prop-types';
import styles from './Info.module.scss';

const Item = ({
  title, text, date, author, children,
}) => (
  <section>
    <h4 className={styles.subTitle}>{title}</h4>
    <p className={styles.text}>{text.substring(0, 80)}</p>
    <p className={styles.text}>
      {date}
      {' '}
      {author}
    </p>
    { children }
  </section>
);

Item.defaultProps = {
  date: '',
  children: null,
};

Item.propTypes = {
  title: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  author: propTypes.string.isRequired,
  date: propTypes.string,
  children: propTypes.node,
};

export default Item;
