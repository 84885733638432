/* eslint-disable no-throw-literal */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_PROD}/recomendation`,
});

const getRecomendationByTrainerId = async (trainerId) => {
  try {
    const token = localStorage.getItem('userAuth');
    const res = await api.get(
      `/${trainerId}`,
      { headers: { authorization: token } },
    );

    console.log('response', res);
    if (res.status !== 200 || res.data.recomendation.length < 1) {
      // const myError = new Error('No list');
      const myError = JSON.stringify({
        en: "there's no recomendation list",
        es: 'No hay lista de recomendación',
      });
      throw new Error(myError);
    }
    return res.data.recomendation[0].recomendationsList;
  } catch (error) {
    console.log('erorr from func', error.message);
    throw new Error(error.message);
  }
};

const getAllRecomendations = async () => {
  try {
    const token = localStorage.getItem('userAuth');
    const res = await api.get(
      '/',
      { headers: { authorization: token } },
    );
    return res.data.recomendations;
  } catch (error) {
    return error.message;
  }
};

export {
  getAllRecomendations,
  getRecomendationByTrainerId,
};
