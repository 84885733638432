/* eslint-disable no-unreachable */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-useless-return */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';

import propTypes from 'prop-types';

// testing
import {
  addDay, addWeek, deleteDay, deleteWeek,
} from 'reducers/weekReducer.reducer';
// testing end
import notificationDisplay from 'utils/notificationDisplay';
import { isEmpty } from 'utils/checkEmptyness';
import { getExerciseModel, getExerciseModelFromTemplate } from 'utils/exerciseModel';
import { createWeekDayStructure, returnPredefinePreprogrammed, returnPredefineTemplate } from 'utils/workout.utils';
import { createRoutine, getRoutine, updateRoutine } from 'services/customWorkout';
import { getTemplates } from 'services/template';
import { getPreprogrammed } from 'services/preProgrammed';
import useLang from 'hooks/useLang';

import DateList from 'components/common/DateList';
import DisplayMessage from 'components/common/DisplayMessage';
import Paginate from 'components/common/Paginate';
import ErrorBoundary from 'components/common/ErrorBoundary';
import RoutineInfo from './Features/RoutineInfo';
import DeleteAlert from '../DeleteAlert';
import ListDisplay from './Features/ListDisplay';
import RoutineDisplay from './Features/RoutineDisplay';

// newVersion
const Workout = ({
  client, workout, setReport, setStep, sessionClone, edit,
}) => {
  const [loadingBtn, toggleBtn] = useReducer((prev) => !prev, false);
  const [isLoadingInfo, toggleLoading] = useReducer((prev) => !prev, false);
  const [selectionList, setSelectionList] = useState([]);
  const [preSelected, setPreSelected] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [redirection, setRedirection] = useState(false);

  const [name, setName] = useState(sessionClone ? sessionClone.workout_name.en : '');
  const [date, setDate] = useState(sessionClone ? sessionClone.date : '');

  // testing
  const [navegation, setNavegation] = useState('list');
  const [actualWeek, setActualWeek] = useState(1);
  const [actualDay, setActualDay] = useState(1);
  const [deleteType, setDeleteType] = useState('day');

  const weekData = useSelector(({ weekList }) => weekList);
  // testing end
  const { _id } = useSelector(({ user }) => user);
  const { t, lang } = useLang();
  const dispatch = useDispatch();
  let response = '';

  const actual = {
    day: actualDay,
    week: actualWeek,
  };

  const onDeleteClose = () => setIsDeleteOpen(false);

  // week func
  const handleAddWeek = () => {
    dispatch(addWeek());
    setActualWeek(actualWeek + 1);
    setActualDay(1);
    console.log('week');
  };

  const handleSelectWeek = (week, days) => {
    setActualWeek(week);
    setActualDay(1);
  };
  const handleDeleteWeek = () => {
    setIsDeleteOpen(true);
    setDeleteType('week');
  };

  // days func
  const handleAddDay = () => {
    dispatch(addDay(actualWeek));
    setActualDay(actualDay + 1);
  };

  const handleSelectday = (day) => {
    setActualDay(day);
  };
  const handleDeleteDay = () => {
    // dispatch(deleteDay(actualWeek, weekData[actualWeek].days.length));
    setIsDeleteOpen(true);
    setDeleteType('day');
  };

  const handlePredefineWorkout = (item, mode) => {
    // organize the fetched data to the preSelected model
    const finalPreSelect = mode === 'template'
      ? returnPredefineTemplate(item)
      : returnPredefinePreprogrammed(item);

    // set the basic week/day structure
    createWeekDayStructure(item, dispatch);

    setPreSelected(finalPreSelect);
    setNavegation('routine');
  };

  const onDeleteSession = () => {
    // dispatch(deleteSession(sessionsData.length));
    if (deleteType === 'day') {
      dispatch(deleteDay(actualWeek, weekData[actualWeek - 1].days.length));
      setActualDay(weekData[actualWeek - 1].days.length - 1);
    }
    if (deleteType === 'week') {
      dispatch(deleteWeek(weekData.length));
      setActualWeek(weekData.length - 1);
      setActualDay(1);
    }
    // display the user to the actual last day
    setIsDeleteOpen(false);
  };

  const handleSaveEdit = async () => {
    toggleBtn();
    const routine = {
      trainer: _id,
      source: sessionClone.source,
      client: client.split('-')[1],
      name,
      date,
      sessions: weekData,
    };

    console.log('exerciseModel', getExerciseModel(routine));
    const responseEdit = await updateRoutine(sessionClone._id, getExerciseModel(routine));
    console.log('updatedRoutine', responseEdit);
    if (responseEdit.data) return notificationDisplay(dispatch, 'something went wrong', 'error');
    notificationDisplay(dispatch, 'Routine succesfully edited', 'success');
    toggleBtn();
    setRedirection(true);
  };

  const handleSave = async () => {
    if (!name) return notificationDisplay(dispatch, 'Please enter the routine Name', 'error');

    toggleBtn();
    const routine = {
      trainer: _id,
      source: workout === 'preprogrammed' ? 'pre-programmed' : workout,
      client: client.split('-')[1],
      name,
      date,
      sessions: weekData,
    };

    if (workout === 'template') console.log('template routine', getExerciseModelFromTemplate(routine));
    else console.log('exerciseModel', getExerciseModel(routine));

    // return;
    workout === 'template'
      ? response = await createRoutine(getExerciseModelFromTemplate(routine))
      : response = await createRoutine(getExerciseModel(routine));
    console.log('response', response);
    if (response.data) {
      setLoading(false);
      return notificationDisplay(dispatch, 'something went wrong', 'error');
    }
    const routineGotten = await getRoutine(response.customWorkout._id);
    console.log(routineGotten);
    setReport(routineGotten);
    toggleBtn();
    setStep((prev) => prev + 1);
  };

  // bring a list of template or preprogrammed
  useEffect(() => {
    const bringTemplates = async () => {
      toggleLoading();
      const res = await getTemplates();
      console.log('template res list', res);
      setSelectionList(res);
      toggleLoading();
    };
    const brignPreprogrammed = async () => {
      toggleLoading();
      const res = await getPreprogrammed();
      console.log('pre list', res);
      setSelectionList(res);
      toggleLoading();
    };

    switch (workout) {
      case 'template': return bringTemplates();
      case 'preprogrammed': return brignPreprogrammed();
      default:
        if (sessionClone) {
          console.log('sessionClone', sessionClone);
          createWeekDayStructure(sessionClone, dispatch);
          const pre = returnPredefinePreprogrammed(sessionClone);
          setPreSelected(pre);
        }
    }
  }, []);

  useEffect(() => {
    if (workout === 'custom') setNavegation('routine');
  }, []);

  if (redirection) {
    const redirectionConfig = { pathname: '/user/clients' };
    return <Redirect to={redirectionConfig} />;
  }

  return (
    <>
      {
        navegation !== 'list' && (
          <>
            <ErrorBoundary>
              <DateList
                list={weekData}
                select={handleSelectWeek}
                add={handleAddWeek}
                type={t('list_week')}
                actual={actual}
                erase={handleDeleteWeek}
              />
            </ErrorBoundary>

            <ErrorBoundary>
              <DateList
                list={weekData[actualWeek - 1].days}
                select={handleSelectday}
                add={handleAddDay}
                type={t('list_day')}
                actual={actual}
                erase={handleDeleteDay}
              />
            </ErrorBoundary>

            <ErrorBoundary>
              <RoutineInfo
                setDate={setDate}
                date={date}
                setName={setName}
                name={name}
              />
            </ErrorBoundary>
          </>
        )
      }

      {
        (workout !== 'custom' && !selectionList.length) && <DisplayMessage message={t('loading_list')} loading />
      }
      {
        /* if template or preprogrammed is selected, you need to choose one to display */
        (navegation === 'list' && !!selectionList.length) && (
          <ErrorBoundary>
            <Paginate
              list={selectionList}
              Component={ListDisplay}
              extraFeatures={{
                isLoadingInfo, workout, predefine: handlePredefineWorkout, lang,
              }}
            />
          </ErrorBoundary>
        )
      }
      {
        navegation === 'routine' && (
          <>
            <RoutineDisplay
              workout={workout}
              data={weekData}
              preSelected={preSelected}
              actual={actual}
            />

            {
              /* display btn only if you have save all routines */
              (!edit && navegation === 'routine' && isEmpty(weekData)) && (
                <Button onClick={handleSave} colorScheme="ptRed" mt="1rem" isLoading={loadingBtn}>
                  {t('save_routine')}
                </Button>
              )
            }
            {
              (edit && navegation === 'routine') && (
                <Button onClick={handleSaveEdit} colorScheme="ptRed" mt="1rem" isLoading={loadingBtn}>
                  Save edit
                </Button>
              )
            }
            <DeleteAlert isOpen={isDeleteOpen} onClose={onDeleteClose} onDelete={onDeleteSession} />
          </>
        )
      }
    </>
  );
};

Workout.defaultProps = {
  sessionClone: undefined,
};

Workout.propTypes = {
  client: propTypes.string.isRequired,
  workout: propTypes.string.isRequired,
  setStep: propTypes.func.isRequired,
  setReport: propTypes.func.isRequired,
  edit: propTypes.bool.isRequired,
  sessionClone: propTypes.shape([]),
};

export default Workout;
