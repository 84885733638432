/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button, Input, Checkbox, CheckboxGroup, HStack, Radio, RadioGroup,
  VStack, Text, Textarea, Flex, IconButton,
} from '@chakra-ui/react';
import { ArrowUpIcon, AddIcon, MinusIcon } from '@chakra-ui/icons';
import propTypes from 'prop-types';

import useUploadFileS3 from 'hooks/useUploadFileS3';
import useUpdate from 'hooks/useUpdate';
import useDelete from 'hooks/useDelete';
import { updateExercise, deleteExercise } from 'services/exercise';
import { transformToEditExercise } from 'utils/transformData';
import notificationDisplay from 'utils/notificationDisplay';

import { infoStyle, tagStyle, inputStyle } from 'theme/commonStyles';

import Toggle from 'components/common/Toggle';
import DeleteAlert from 'components/common/DeleteAlert';
import Carousel from 'components/common/Carousel';

const getInstructions = (instructions) => {
  const arrObj = [];
  instructions.forEach((instruction) => arrObj.push({ name: instruction }));
  return arrObj;
};

const Edit = ({ exercise }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [carouselStep, setCarouselStep] = useState(0);
  const [imgArr, setImgArr] = useState(exercise?.images || []);
  const [loading, setLoading] = useState(false);
  const lang = window.localStorage.getItem('i18nextLng');
  const { control, handleSubmit, register } = useForm({
    mode: 'onChange',
    defaultValues: {
      instructions: getInstructions(exercise.instructions[lang].filter((item) => item)),
    },
  });
  const {
    fields, append, prepend, remove, swap, move, insert,
  } = useFieldArray({
    control,
    name: 'instructions',
  });
  const {
    mutate, isLoading, isError, isSuccess,
  } = useUpdate('exercises', exercise._id, updateExercise);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const uploadFileS3 = useUploadFileS3();

  const handleDeleteClose = () => setDeleteOpen(false);
  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleCarouselClick = (movement) => {
    setCarouselStep((prev) => (movement === 'prev' ? prev - 1 : prev + 1));
  };
  const deleteImage = (index) => {
    setImgArr(imgArr.filter((_, id) => id !== index));
    setCarouselStep((prev) => (carouselStep === 0 ? 0 : prev - 1));
  };

  const onChange = async (data) => {
    const file = document.querySelector('.file');
    let response = '';
    if (data.images[0]) {
      setLoading(true);
      response = await uploadFileS3.mutateAsync(data.images[0], data.name);
      if (response.location) {
        notificationDisplay(dispatch, 'Your image have been saved', 'success');
        setImgArr((prev) => prev.concat(response.location));
      }
      file.value = '';
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const dataToSend = transformToEditExercise(
      data,
      exercise,
      imgArr,
      lang,
    );
    delete dataToSend.tableData;
    delete dataToSend.avatar;

    console.log('exercise', exercise);
    console.log('predata', data);
    console.log('postData', dataToSend);

    mutate(dataToSend);
  };
  if (isLoading) return <p style={infoStyle}>{t('data_update')}</p>;
  if (isError) return <p style={infoStyle}>{t('something_wrong')}</p>;
  if (isSuccess) return <p style={infoStyle}>{t('succesful_update')}</p>;
  return (
    <>
      {console.log(exercise.name[lang], '-', exercise._id, '-', exercise.category_id[0].name, '-', exercise.weakness)}
      <form onSubmit={handleSubmit(onSubmit)} onChange={handleSubmit(onChange)}>
        <Flex align="center" justify="center" as="fieldset">
          <Flex align="center" justify="center" direction="column">
            <Carousel
              step={carouselStep}
              click={handleCarouselClick}
              images={imgArr}
              mode="edit"
              erase={deleteImage}
            />
            <Button
              colorScheme="ptRed"
              my="1rem"
              rightIcon={<ArrowUpIcon />}
              onClick={() => document.querySelector('.file').click()}
              isLoading={loading}
            >
              {t('upload_image')}
            </Button>
            <input
              accept="image/*"
              className="file"
              type="file"
              {...register('images')}
              style={{ display: 'none' }}
            />
          </Flex>
        </Flex>

        <p style={tagStyle}>
          {t('exercise_name')}
          {' '}
          EN
        </p>
        <Controller
          control={control}
          defaultValue={exercise.name.en}
          name="enName"
          render={({ field }) => (
            <Input
              focusBorderColor="ptRed.500"
              placeholder="Name"
              {...field}
            />
          )}
        />

        <p style={tagStyle}>
          {t('exercise_name')}
          {' '}
          ES
        </p>
        <Controller
          control={control}
          defaultValue={exercise.name.es}
          name="esName"
          render={({ field }) => (
            <Input
              focusBorderColor="ptRed.500"
              placeholder={t('exercise_name_placeholder_es')}
              {...field}
            />
          )}
        />

        <Toggle Component={<p style={tagStyle}>{t('muscle_system')}</p>}>
          <Controller
            control={control}
            name="muscle_system"
            defaultValue={[...exercise.muscle_system[lang]]}
            render={({ field }) => (
              <CheckboxGroup {...field} colorScheme="red">
                <HStack ml="1rem" wrap="wrap">
                  <Checkbox value={t('opt_legs')}>{t('opt_legs')}</Checkbox>
                  <Checkbox value={t('opt_shoulder')}>{t('opt_shoulder')}</Checkbox>
                  <Checkbox value={t('opt_hips')}>{t('opt_hips')}</Checkbox>
                  <Checkbox value={t('opt_gluteus')}>{t('opt_gluteus')}</Checkbox>
                  <Checkbox value={t('opt_hamstring')}>{t('opt_hamstring')}</Checkbox>
                  <Checkbox value={t('opt_posterior_core')}>{t('opt_posterior_core')}</Checkbox>
                  <Checkbox value={t('opt_anterior_core')}>{t('opt_anterior_core')}</Checkbox>
                  <Checkbox value={t('opt_lateral_core')}>{t('opt_lateral_core')}</Checkbox>
                  <Checkbox value={t('opt_abs')}>{t('opt_abs')}</Checkbox>
                  <Checkbox value={t('opt_quadriceps')}>{t('opt_quadriceps')}</Checkbox>
                  <Checkbox value={t('opt_back')}>{t('opt_back')}</Checkbox>
                  <Checkbox value={t('opt_arms')}>{t('opt_arms')}</Checkbox>
                  <Checkbox value={t('opt_biceps')}>{t('opt_biceps')}</Checkbox>
                  <Checkbox value={t('opt_triceps')}>{t('opt_triceps')}</Checkbox>
                  <Checkbox value={t('opt_pec')}>{t('opt_pec')}</Checkbox>
                  <Checkbox value={t('opt_adductors')}>{t('opt_adductors')}</Checkbox>
                  <Checkbox value={t('opt_calves')}>{t('opt_calves')}</Checkbox>
                  <Checkbox value={t('opt_ancles')}>{t('opt_ancles')}</Checkbox>
                  <Checkbox value={t('opt_all_body')}>{t('opt_all_body')}</Checkbox>
                </HStack>
              </CheckboxGroup>
            )}
          />
        </Toggle>

        <Toggle Component={<p style={tagStyle}>{t('categories')}</p>}>
          <Controller
            control={control}
            name="category_id"
            defaultValue={exercise.category_id.map((x) => x._id)}
            render={({ field }) => (
              <CheckboxGroup {...field} colorScheme="red" as="fieldset">
                <HStack ml="1rem" wrap="wrap">
                  <Checkbox value="6171d84aec64ada0efa05721">{t('opt_traditional')}</Checkbox>
                  <Checkbox value="61a62b7fb2f0481a2d9db38e">{t('opt_functional')}</Checkbox>
                  <Checkbox value="61be5e479abd1ebc3d540764">{t('opt_rehab')}</Checkbox>
                  <Checkbox value="61a62ba3b2f0481a2d9db38f">{t('opt_power')}</Checkbox>
                  <Checkbox value="61be5e799abd1ebc3d540767">{t('opt_metabolic')}</Checkbox>
                  <Checkbox value="61be5e969abd1ebc3d54076a">{t('opt_saq')}</Checkbox>
                </HStack>
              </CheckboxGroup>
            )}
          />
        </Toggle>

        <Toggle Component={<p style={tagStyle}>{t('action')}</p>}>
          <Controller
            control={control}
            name="action"
            defaultValue={exercise.action[lang]}
            render={({ field }) => (
              <RadioGroup {...field} colorScheme="red">
                <HStack ml="1rem" wrap="wrap">
                  <Radio value={t('opt_level_change')}>{t('opt_level_change')}</Radio>
                  <Radio value={t('opt_hip_hinge')}>{t('opt_hip_hinge')}</Radio>
                  <Radio value={t('opt_traction')}>{t('opt_traction')}</Radio>
                  <Radio value={t('opt_pull')}>{t('opt_pull')}</Radio>
                  <Radio value={t('opt_push')}>{t('opt_push')}</Radio>
                  <Radio value={t('opt_core_stiffness')}>{t('opt_core_stiffness')}</Radio>
                  <Radio value={t('opt_rotation')}>{t('opt_rotation')}</Radio>
                  <Radio value={t('opt_jumps')}>{t('opt_jumps')}</Radio>
                  <Radio value={t('opt_plyometric')}>{t('opt_plyometric')}</Radio>
                  <Radio value={t('opt_locomotion')}>{t('opt_locomotion')}</Radio>
                  <Radio value={t('opt_push_pull')}>{t('opt_push_pull')}</Radio>
                  <Radio value={t('opt_adductors')}>{t('opt_adductors')}</Radio>
                </HStack>
              </RadioGroup>
            )}
          />
        </Toggle>

        <Toggle Component={<p style={tagStyle}>{t('equipment')}</p>}>
          <Controller
            control={control}
            name="equipment"
            defaultValue={[...exercise.equipment[lang]]}
            render={({ field }) => (
              <CheckboxGroup {...field} colorScheme="red">
                <HStack ml="1rem" wrap="wrap">
                  <Checkbox value={t('opt_bb')}>{t('opt_bb')}</Checkbox>
                  <Checkbox value={t('opt_db/kb')}>{t('opt_db/kb')}</Checkbox>
                  <Checkbox value={t('opt_m')}>{t('opt_m')}</Checkbox>
                  <Checkbox value={t('opt_db')}>{t('opt_db')}</Checkbox>
                  <Checkbox value={t('opt_bp')}>{t('opt_bp')}</Checkbox>
                  <Checkbox value={t('opt_ez')}>{t('opt_ez')}</Checkbox>
                  <Checkbox value={t('opt_sm')}>{t('opt_sm')}</Checkbox>
                  <Checkbox value={t('opt_abw')}>{t('opt_abw')}</Checkbox>
                  <Checkbox value={t('opt_bw')}>{t('opt_bw')}</Checkbox>
                  <Checkbox value={t('opt_mb')}>{t('opt_mb')}</Checkbox>
                  <Checkbox value={t('opt_ropes/sus')}>{t('opt_ropes/sus')}</Checkbox>
                  <Checkbox value={t('opt_sb')}>{t('opt_sb')}</Checkbox>
                  <Checkbox value={t('opt_slb')}>{t('opt_slb')}</Checkbox>
                  <Checkbox value={t('opt_kb')}>{t('opt_kb')}</Checkbox>
                  <Checkbox value={t('opt_sprb')}>{t('opt_sprb')}</Checkbox>
                  <Checkbox value={t('opt_sprb_short')}>{t('opt_sprb_short')}</Checkbox>
                  <Checkbox value={t('opt_hg360')}>{t('opt_hg360')}</Checkbox>
                  <Checkbox value={t('opt_car/truck')}>{t('opt_car/truck')}</Checkbox>
                  <Checkbox value={t('opt_ropes')}>{t('opt_ropes')}</Checkbox>
                  <Checkbox value={t('opt_sled')}>{t('opt_sled')}</Checkbox>
                  <Checkbox value={t('opt_vc')}>{t('opt_vc')}</Checkbox>
                  <Checkbox value={t('opt_bp_band')}>{t('opt_bp_band')}</Checkbox>
                  <Checkbox value={t('opt_db2')}>{t('opt_db2')}</Checkbox>
                  <Checkbox value={t('opt_sus')}>{t('opt_sus')}</Checkbox>
                  <Checkbox value={t('opt_lm')}>{t('opt_lm')}</Checkbox>
                  <Checkbox value={t('opt_plate')}>{t('opt_plate')}</Checkbox>
                  <Checkbox value={t('opt_vib')}>{t('opt_vib')}</Checkbox>
                  <Checkbox value={t('opt_agl')}>{t('opt_agl')}</Checkbox>
                  <Checkbox value={t('opt_agl_short')}>{t('opt_agl_short')}</Checkbox>
                  <Checkbox value={t('opt_hur')}>{t('opt_hur')}</Checkbox>
                  <Checkbox value={t('opt_cones')}>{t('opt_cones')}</Checkbox>
                  <Checkbox value={t('opt_cs')}>{t('opt_cs')}</Checkbox>
                </HStack>
              </CheckboxGroup>
            )}
          />
        </Toggle>

        {/* testing */}
        <Toggle Component={<p style={tagStyle}>evaluation</p>}>
          <Controller
            control={control}
            name="weakness"
            defaultValue={exercise.weakness ? String(exercise.weakness) : ''}
            render={({ field }) => (
              <RadioGroup {...field} colorScheme="red">
                <HStack ml="1rem" wrap="wrap">
                  <Radio value="">None</Radio>
                  <Radio value="1">Core Posterior</Radio>
                  <Radio value="2">{t('core_anterior')}</Radio>
                  <Radio value="3">{t('escapular_push')}</Radio>
                  <Radio value="4">{t('escapular_pull')}</Radio>
                  <Radio value="5">{t('rotation')}</Radio>
                  <Radio value="6">{t('core_stiffness')}</Radio>
                </HStack>
              </RadioGroup>
            )}
          />
        </Toggle>

        <Text color="#d9ad42" fontWeight="700" alignSelf="start" my="1rem">
          {t('instructions')}
          {' '}
          <IconButton
            aria-label="add instruction"
            colorScheme="ptRed"
            icon={<AddIcon />}
            onClick={() => append({})}
            type="button"
            variant="link"
          />
        </Text>
        {fields.map(({ id, name }, index) => (
          <>
            <Input
              key={id}
              name={`instructions.${index}.name`}
              defaultValue={name}
              placeholder={`Step: ${index + 1}`}
              {...register(`instructions.${index}.name`)}
              {...inputStyle}
              w="80%"
            />
            <IconButton
              aria-label="remove instructon"
              colorScheme="ptRed"
              icon={<MinusIcon />}
              type="button"
              onClick={() => remove(index)}
              variant="link"
            />
          </>
        ))}
        <Button colorScheme="ptRed" float="left" my="1rem" onClick={handleDeleteOpen}>
          Delete exercise
        </Button>
        <Button type="submit" colorScheme="ptRed" my="1rem" float="right">
          {t('save')}
        </Button>
      </form>
      {
        deleteOpen && (
          <DeleteAlert
            open={deleteOpen}
            close={handleDeleteClose}
            id={exercise._id}
            title={t('delete_exercise')}
            func={() => useDelete('exercises', deleteExercise)}
          />
        )
      }
    </>
  );
};

Edit.propTypes = {
  exercise: propTypes.shape({
    _id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    muscle_system: propTypes.shape([]).isRequired,
    images: propTypes.shape([]).isRequired,
    equipment: propTypes.shape([]).isRequired,
    category_id: propTypes.shape([]).isRequired,
    action: propTypes.shape([]).isRequired,
    abbrevations: propTypes.shape([]).isRequired,
    instructions: propTypes.shape([]).isRequired,
    weakness: propTypes.number.isRequired,
  }).isRequired,
};

export default Edit;
