/* eslint-disable max-len */
/* eslint-disable consistent-return */
const evaluation = {
  exercise: '',
  imageExercise: '',
  videoExercise: '',
  generalNote: {
    right: '',
    left: '',
    both: '',
  },
  notes: [],
  dominant_leg: '',
  succesfulMovements: {
    leftLeg: 0,
    rightLeg: 0,
  },
};

const initialState = [];

const isDominant = ({ succesfulMovements }) => {
  if (succesfulMovements.leftLeg === succesfulMovements.rightLeg) return 'both';
  if (succesfulMovements.leftLeg > succesfulMovements.rightLeg) return 'left';
  if (succesfulMovements.leftLeg < succesfulMovements.rightLeg) return 'right';
};
const removeDuplicate = (oldNotes, newNotes) => {
  const notesFiltered = oldNotes.filter((oldNote) => oldNote.side !== newNotes[0]?.side);
  const allNotes = notesFiltered.concat(newNotes);
  return Array.from(new Set(allNotes.map(JSON.stringify))).map(JSON.parse);
};

const addGeneralNoteToState = (state, value, side, data) => {
  console.log('state', state);
  const addedGeneral = state
    .map((evaluationState, index) => {
      console.log('evaluation State', evaluationState);
      console.log('index ', index, ' value ', value);
      return (index === value
        ? ({ ...evaluationState, generalNote: ({ ...evaluationState.generalNote, [side]: data }) })
        : evaluationState);
    });
  console.log('added', addedGeneral);
  return addedGeneral;
};

const evaluationReducer = (state, action) => {
  switch (action.type) {
    case 'RESET_EVALUATION': return initialState;
    case 'INIT_EVALUATION':
      if (state.length < action.value) {
        return state.concat({ ...evaluation, exercise: action.exercise });
      }
      return state;
    // case 'NOTES_EVALUATION': return state.map((item, index) =>
    // (index === action.value ? ({ ...item, notes: item.notes.concat(action.notes) }) : item));
    case 'NOTES_EVALUATION':
      return state
        .map((item, index) => (
          index === action.value
            ? ({
              ...item,
              notes: removeDuplicate(item.notes, action.notes),
            })
            : item));
    case 'SET_DOMINANT': return state.map((item, index) => (index === action.value ? ({ ...item, dominant_leg: isDominant(item) }) : item));
    case 'UPDATE_REPS':
      if (action.side === 'left') return state.map((item, index) => (index === action.value ? ({ ...item, succesfulMovements: { ...item.succesfulMovements, leftLeg: +action.data } }) : item));
      if (action.side === 'right') return state.map((item, index) => (index === action.value ? ({ ...item, succesfulMovements: { ...item.succesfulMovements, rightLeg: +action.data } }) : item));
      if (action.side === 'both') return state.map((item, index) => (index === action.value ? ({ ...item, succesfulMovements: { leftLeg: +action.data, rightLeg: +action.data } }) : item));
      break;
    case 'SAVE_EVALUATION': return state.filter((item) => item.notes.length > 0);
    // || item.succesfulMovements.rightLeg > 0
    // || item.succesfulMovements.leftLeg > 0);
    case 'UPDATE_EVALUATION': return state.map(
      (item) => (item.exercise === action.value
        ? action.data
        : item),
    );
    case 'GENERAL_NOTE': return addGeneralNoteToState(state, action.value, action.side, action.data);
    default: return state;
  }
};

export default evaluationReducer;

const resetEvaluation = () => ({ type: 'RESET_EVALUATION' });
const setDominant = (value) => ({ type: 'SET_DOMINANT', value });
const initEvaluation = (exercise, value) => ({ type: 'INIT_EVALUATION', exercise, value });
const addNotes = (notes, value) => ({ type: 'NOTES_EVALUATION', notes, value });
const saveEvaluation = () => ({ type: 'SAVE_EVALUATION' });
const updateEvaluation = (data, value) => ({ type: 'UPDATE_EVALUATION', data, value });
const addGeneralNote = (data, side, value) => ({
  type: 'GENERAL_NOTE', data, side, value,
});

const updateReps = (side, data, value) => ({
  type: 'UPDATE_REPS',
  side,
  data,
  value,
});

export {
  initialState,
  isDominant,
  addNotes,
  initEvaluation,
  resetEvaluation,
  updateReps,
  setDominant,
  saveEvaluation,
  updateEvaluation,
  addGeneralNote,
};
