/* eslint-disable no-lone-blocks */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';

import ReturnBtn from 'components/common/ReturnBtn';
import {
  initEvaluation, addNotes, updateReps, setDominant, saveEvaluation, addGeneralNote,
} from './evaluationReducer';

import SingleEvaluation from './SingleEvaluation';

import dataToMapEvaluation from './dataToMapEvaluation';

const setValueForEvaluation = (number) => {
  if (number === 1 || number === 2) return 0;
  if (number === 3 || number === 4) return 1;
  if (number === 5) return 2;
  if (number === 6 || number === 7) return 3;
  if (number === 8) return 4;
  if (number === 9) return 5;
  if (number === 10) return 6;
  if (number === 11) return 7;
};

const Selector = ({
  setStep, state, dispatch, setEvaluations, evaluations,
}) => {
  const [localStep, setLocalStep] = useState(1);
  const { t } = useTranslation();

  const handleClick = (data, general, reps, side, local, direction) => {
    const dataFiltered = data.filter((radio) => radio.checked);

    dispatch(updateReps(side, reps, setValueForEvaluation(local)));
    dispatch(addNotes(dataFiltered, setValueForEvaluation(local)));
    dispatch(setDominant(setValueForEvaluation(local)));
    dispatch(addGeneralNote(general, side, setValueForEvaluation(local)));

    if (direction === 'save') {
      dispatch(saveEvaluation());
      setStep((prev) => prev + 1);
    }
    setLocalStep((prev) => (direction === 'prev' ? prev - 1 : prev + 1));
  };

  useEffect(() => {
    switch (localStep) {
      case 1:
        // case 1: dispatch(initEvaluation('61a5594b2f7ea4933fcf220c'));
        dispatch(initEvaluation('Single Leg Anterior Reach', 1));
        break;
      case 3: {
        // dispatch(initEvaluation('61a559972f7ea4933fcf220e'));
        dispatch(initEvaluation('Single Squat Reach', 2));
      }
        break;
      case 5: {
        // dispatch(initEvaluation('61a559d52f7ea4933fcf2210'));
        dispatch(initEvaluation('Two-Leg-Squat', 3));
      }
        break;
      case 6: {
        // dispatch(initEvaluation('61a559f22f7ea4933fcf2212'));
        dispatch(initEvaluation('Lunges', 4));
      }
        break;
      case 8: {
        // dispatch(initEvaluation('61a55a452f7ea4933fcf2216'));
        dispatch(initEvaluation('Push Up', 5));
      }
        break;
      case 9: {
        // dispatch(initEvaluation('61a55a682f7ea4933fcf221b'));
        dispatch(initEvaluation('Recline Pull', 6));
      }
        break;
      case 10: {
        // dispatch(initEvaluation('61a55ac82f7ea4933fcf221f'));
        dispatch(initEvaluation('Rotation with Pivot', 7));
      }
        break;
      case 11: {
        // dispatch(initEvaluation('61a55ac82f7ea4933fcf221f'));
        dispatch(initEvaluation('Rotation without Pivot', 8));
      }
        break;
      default: return null;
    }
  }, [localStep]);

  return (
    <>
      <ReturnBtn setStep={setStep} />
      {
        dataToMapEvaluation.map((evaluation) => (
          <>
            {
              localStep === evaluation.number && (
                <SingleEvaluation
                  title={t(evaluation.title)}
                  move={handleClick}
                  notes={state[evaluation.state]?.notes}
                  legRep={evaluation.side === 'right' ? state[evaluation.state]?.succesfulMovements.rightLeg : state[evaluation.state]?.succesfulMovements.leftLeg}
                  local={localStep}
                  side={evaluation.side}
                  radios={evaluation.radios}
                  className={evaluation.className}
                  general={state[evaluation.state]?.generalNote}
                  key={evaluation.number}
                />
              )
            }
          </>
        ))
      }
    </>
  );
};

Selector.propTypes = {
  setStep: propTypes.func.isRequired,
  setEvaluations: propTypes.func.isRequired,
  evaluations: propTypes.shape([]).isRequired,
  state: propTypes.shape({
    notes: propTypes.shape([]).isRequired,
  }).isRequired,
  dispatch: propTypes.func.isRequired,
};

export default Selector;
