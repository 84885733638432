const exerciseReducer = (state = '', action) => {
  switch (action.type) {
    case 'SEARCH': return action.data.word;
    default: return state;
  }
};

const lookFor = (word) => ({ type: 'SEARCH', data: { word } });

export default exerciseReducer;

export {
  lookFor,
};
