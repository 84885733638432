import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_PROD}/client/`,
});

const getClients = async () => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.get('/', { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const getTrainerClients = async (idTrainer) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.get(`/trainer/${idTrainer}`, { headers: { authorization: token } });
    return response.data.clients;
  } catch (error) {
    return error.response;
  }
};
const updateClient = async (idClient, data) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.put(`/${idClient}`, data, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return error.response;
  }
};
const createClient = async (data) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.post('/', data, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const deleteClient = async (idClient) => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.delete(`/${idClient}`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export {
  createClient,
  deleteClient,
  getClients,
  getTrainerClients,
  updateClient,
};
