/* eslint-disable react/jsx-filename-extension */
import React, { useContext, createContext, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import propTypes from 'prop-types';

const authService = {
  isAuthenticated: false,
  signin: (cb) => {
    authService.isAuthenticated = true;
    cb();
  },
  signout: (cb) => {
    authService.isAuthenticated = false;
    cb();
  },
};

const authContext = createContext();

const useProvideAuth = () => {
  const [user, setUser] = useState(null);
  const signin = (cb) => {
    authService.signin(() => setUser('user'));
    cb();
  };
  const signout = (cb) => {
    authService.signout(() => setUser(null));
    cb();
  };

  return { user, signin, signout };
};

const useAuth = () => useContext(authContext);

const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
};

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => (auth.user ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  );
};

ProvideAuth.propTypes = {
  children: propTypes.node.isRequired,
};

PrivateRoute.propTypes = {
  children: propTypes.node.isRequired,
};

export default ProvideAuth;
export {
  PrivateRoute,
  useAuth,
};
