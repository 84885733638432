import { Heading, Text, Textarea } from '@chakra-ui/react';
import React from 'react';
import { bigContainerStyle, inputStyle } from 'theme/commonStyles';

const Feedback = () => (
  <main style={bigContainerStyle}>
    <Heading>Feedback</Heading>
    <Text>Your opinion is important to us, Please tell us how we can improve</Text>
    <Textarea {...inputStyle} w="80%" h="150" mt="1rem" resize="none" placeholder="Opinion" />
  </main>
);
export default Feedback;
