/* eslint-disable react/no-array-index-key */
import React from 'react';
import { GridItem } from '@chakra-ui/react';
import { shape, string } from 'prop-types';

// const ExerciseLine = ({ id_exercise, sets, lang }) => (
//   <p style={{ margin: '3px 0 0', fontWeight: 'bold' }}>
//     {id_exercise.name[lang]}
//     <span style={{ display: 'block', fontWeight: 'normal' }}>
//       {sets.map((set, id) => (
//         <span style={{ margin: '0 0 0 0.5rem' }} key={`${set.load}${id}${set.reps}`}>
//           {`${set.load}${set.typeWeight}/${set.reps}`}
//         </span>
//       ))}
//     </span>
//   </p>
// );

const ExerciseLine = ({ id_exercise, sets, lang }) => (
  <>
    <GridItem>
      {id_exercise.name[lang]}
    </GridItem>
    {
      sets.map((set) => (
        <GridItem fontSize={12}>
          {
            `${set.load}${set.typeWeight}/${set.reps}`
          }
        </GridItem>
      ))
    }
  </>
);

ExerciseLine.propTypes = {
  id_exercise: shape({}).isRequired,
  sets: shape([]).isRequired,
  lang: string.isRequired,
};

export default ExerciseLine;
