/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import useUploadFileS3 from 'hooks/useUploadFileS3';

import { loading, loaded } from 'reducers/loader.reducer';
import { transformEditProfileData } from 'utils/transformData';
import useUpdateTrainer from 'hooks/useUpdateTrainer';
import styles from './Edit.module.scss';
import TextInput from '../common/TextInput';

const Edit = ({ setEdit }) => {
  const { control, register, handleSubmit } = useForm();
  const trainer = useSelector((state) => state.trainer);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const uploadFileS3 = useUploadFileS3();
  const {
    mutate, isLoading, isError, isSuccess,
  } = useUpdateTrainer(trainer._id, user.id_user, setEdit);
  const onSubmit = async (data) => {
    dispatch(loading());
    let response = '';
    if (data.avatar[0]) {
      response = await uploadFileS3.mutateAsync(data.avatar[0], 'imagen-de-prueba');
    }

    mutate(transformEditProfileData(data, trainer.name, response.location || trainer.pic));
    dispatch(loaded());
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      {isLoading && <p>Loading...</p>}
      {isError && <p>Error</p>}
      {isSuccess && <p>Success</p>}
      <fieldset className={styles.avatarContainer}>
        <div className={styles.avatarWrapper}>
          <img className={`${styles.profilePic} pic`} src="" alt="" />
          <div className={styles.uploadButton} onClick={() => document.querySelector('.file').click()} role="button" tabIndex={0}>
            <i className={`fa fa-arrow-circle-up ${styles.arrow}`} aria-hidden="true" />
          </div>
          <input
            accept="image/*"
            className={`${styles.fileUpload} file`}
            type="file"
            {...register('avatar')}
          />
        </div>
      </fieldset>
      <fieldset className={styles.sides}>
        <TextInput
          control={control}
          def={trainer.phone}
          name="phone"
          place={t('phonenumber')}
        />

        <TextInput
          control={control}
          def={trainer.speciality}
          name="speciality"
          place={t('profile_speciality')}
        />

        <TextInput
          control={control}
          def={trainer.job_description}
          name="job"
          place={t('job_description')}
          type="textarea"
        />
      </fieldset>
      <fieldset className={styles.sides}>
        <TextInput
          control={control}
          def={trainer.address.street1}
          name="street1"
          place={t('principal_address')}
        />

        <TextInput
          control={control}
          def={trainer.address.street2}
          name="street2"
          place={t('secondary_address')}
          required={false}
        />

        <TextInput
          control={control}
          def={trainer.address.city}
          name="city"
          place={t('city')}
        />

        <TextInput
          control={control}
          def={trainer.address.state}
          name="state"
          place={t('state')}
        />

        <TextInput
          control={control}
          def={trainer.address.zip}
          name="zip"
          place={t('zip')}
        />

      </fieldset>

      <Button
        className={styles.btn}
        colorScheme="ptRed"
        type="submit"
      >
        {t('save_changes')}
      </Button>
    </form>
  );
};

Edit.propTypes = {
  setEdit: propTypes.func.isRequired,
};

export default Edit;
