/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_PROD}/category`,
});

const getAllCategories = async () => {
  try {
    const token = localStorage.getItem('userAuth');
    const res = await api.get(
      '/',
      { headers: { authorization: token } },
    );
    return res.data.categories;
  } catch (error) {
    return error.message;
  }
};

export {
  getAllCategories,
};
