/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Grid, GridItem, IconButton, Image, Center,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon, DeleteIcon } from '@chakra-ui/icons';
import propTypes from 'prop-types';

const Carousel = ({
  step, click, images, mode, erase,
}) => (
  <>
    <Grid templateColumns="10% 1fr 10%">
      <GridItem display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <IconButton
          aria-label="previous image"
          onClick={() => click('prev')}
          icon={<ArrowBackIcon id="prev" />}
          isDisabled={step === 0}
        />
      </GridItem>
      <GridItem>
        {
            images.length === 0
              ? (
                <Center
                  backgroundColor="#ececec"
                  borderRadius="5px"
                  border="1px solid black"
                  w="300px"
                  h="150px"
                >
                  Upload New Image
                </Center>
              )
              : images.map((image, index) => (
                <>
                  <Image
                    display={step === index ? 'block' : 'none'}
                    border="1px solid black"
                    borderRadius="10px"
                    loading="lazy"
                    src={image}
                    maxHeight="300px"
                    w="100%"
                  />
                  {
                    mode === 'edit' && (
                      <IconButton
                        aria-label="delete image execise"
                        display={step === index ? 'block' : 'none'}
                        onClick={() => erase(index)}
                        icon={<DeleteIcon />}
                        top="65px"
                        left="60px"
                        position="absolute"
                        variant="ghost"
                        zIndex="300"
                        colorScheme="red"
                      />
                    )
                  }
                </>
              ))

          }
      </GridItem>
      <GridItem display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <IconButton
          aria-label="next image"
          onClick={() => click('next')}
          icon={<ArrowForwardIcon />}
          isDisabled={
              images.length === 0 || step === images.length - 1
            }
        />
      </GridItem>
    </Grid>
  </>
);

Carousel.defaultProps = {
  mode: '',
  erase: null,
};

Carousel.propTypes = {
  step: propTypes.number.isRequired,
  click: propTypes.func.isRequired,
  images: propTypes.shape([]).isRequired,
  mode: propTypes.string,
  erase: propTypes.func,
};

export default Carousel;
