/* eslint-disable no-unused-vars */
import firstStyles from './pictures/first.module.scss';
import secondStyles from './pictures/second.module.scss';
import thirdStyles from './pictures/third.module.scss';
import fourthStyles from './pictures/fourth.module.scss';
import fifthStyles from './pictures/fifth.module.scss';
import sixthStyles from './pictures/sixth.module.scss';
import seventhStyles from './pictures/seventh.module.scss';
import eightStyles from './pictures/eight.module.scss';
import ninthStyles from './pictures/ninth.module.scss';
import tenthStyles from './pictures/tenth.module.scss';
import eleventhStyles from './pictures/eleventh.module.scss';
import * as radios from './pictures/radiosState';

const dataToMapEvaluation = [
  {
    title: 'right_leg_anterior_reach',
    number: 1,
    state: 0,
    side: 'right',
    radios: radios.first,
    className: firstStyles,
  },
  {
    title: 'left_leg_anterior_reach',
    number: 2,
    state: 0,
    side: 'left',
    radios: radios.second,
    className: secondStyles,
  },
  {
    title: 'right_leg_squat',
    number: 3,
    state: 1,
    side: 'right',
    radios: radios.third,
    className: thirdStyles,
  },
  {
    title: 'left_leg_squat',
    number: 4,
    state: 1,
    side: 'left',
    radios: radios.fourth,
    className: fourthStyles,
  },
  {
    title: 'two_leg_squat',
    number: 5,
    state: 2,
    side: 'both',
    radios: radios.fifth,
    className: fifthStyles,
  },
  {
    title: 'right_lunge',
    number: 6,
    state: 3,
    side: 'right',
    radios: radios.sixth,
    className: sixthStyles,
  },
  {
    title: 'left_lunge',
    number: 7,
    state: 3,
    side: 'left',
    radios: radios.seventh,
    className: seventhStyles,
  },
  {
    title: 'push_up',
    number: 8,
    state: 4,
    side: 'both',
    radios: radios.eigth,
    className: eightStyles,
  },
  {
    title: 'recline_pull',
    number: 9,
    state: 5,
    side: 'both',
    radios: radios.ninth,
    className: ninthStyles,
  },
  {
    title: 'rotation_with_pivot',
    number: 10,
    state: 6,
    side: 'both',
    radios: radios.tenth,
    className: tenthStyles,
  },
  {
    title: 'rotation_without_pivot',
    number: 11,
    state: 7,
    side: 'both',
    radios: radios.eleventh,
    className: eleventhStyles,
  },
];

export default dataToMapEvaluation;
