import React from 'react';
import propTypes from 'prop-types';
import { Flex, Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

const Options = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Flex align="center" justify="flex-end" w="90%" mb="1rem">
      <Button rightIcon={<AddIcon />} colorScheme="ptRed" onClick={() => onClick('', 'add')}>
        {t('add_exercise')}
      </Button>
    </Flex>
  );
};

Options.propTypes = {
  onClick: propTypes.func.isRequired,
};

export default Options;
