/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import {
  Button,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  Input, InputGroup, InputRightElement,
  Flex,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  func, bool, shape, string, node, number,
} from 'prop-types';

import useLang from 'hooks/useLang';
import { inputStyle } from 'theme/commonStyles';

import { updateData } from '../Custom/customReducer';

const SetOverlay = ({
  onClose, isOpen, position: { rowIdx, columnIdx }, dispatch, final, save,
}) => {
  const cellValue = useSelector(({ cell }) => cell);
  const [loadType, setLoadType] = useState(cellValue.type || 'kg');
  const [load, setLoad] = useState(cellValue.load);
  const [reps, setReps] = useState(cellValue.reps);
  const initialRef = useRef();
  const { t } = useLang();

  const handleLoad = ({ target }) => setLoad(target.value);
  const handleReps = ({ target }) => setReps(target.value);
  const handleChangeType = () => setLoadType(loadType === 'kg' ? 'lb' : 'kg');
  const handleClick = (e) => {
    e.preventDefault();
    console.log('cell value', cellValue);
    const info = `${load}${loadType}/${reps}`;
    dispatch(updateData(info, rowIdx, columnIdx));
    save();
    setLoad('');
    setReps('');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef} finalFocusRef={final}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('set_info')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form>
            <InputGroup>
              <Input
                onChange={handleLoad}
                placeholder="load"
                ref={initialRef}
                type="number"
                value={load}
                {...inputStyle}
              />
              <InputRightElement>
                <Button onClick={handleChangeType}>{loadType}</Button>
              </InputRightElement>
            </InputGroup>
            <Input
              onChange={handleReps}
              placeholder="reps"
              type="number"
              value={reps}
              {...inputStyle}
            />
            <Flex justify="end" mt="1rem">
              <Button colorScheme="ptRed" onClick={handleClick} type="submit">
                {t('set_btn')}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

SetOverlay.defaultProps = {
  final: null,
};

SetOverlay.propTypes = {
  save: func.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  position: shape({
    rowIdx: number.isRequired,
    columnIdx: string.isRequired,
  }).isRequired,
  final: node,
};

export default SetOverlay;
