import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button, ButtonGroup, Flex, useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ReactLogo } from 'assets/IHP_Running_Man_Logo_2021.svg';

import { useAuth } from 'utils/authContext';
import { deleteInfo } from 'reducers/user.reducer';
import { logout } from 'reducers/trainer.reducer';

import styles from './LogoNav.module.scss';
import Menu from '../common/Menu';

const LogoNav = () => {
  const user = useSelector((state) => state.user);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const auth = useAuth();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onLogout = () => {
    dispatch(deleteInfo());
    dispatch(logout());
    auth.signout(() => console.log('out'));
    localStorage.removeItem('userAuth');
  };
  return (
    <>
      <Flex
        align="center"
        backgroundColor="white"
        borderBottom="2px solid #444444"
        h="80px"
        justify="center"
      >
        <Flex align="center" width={['80%', '60%']} justify="space-between">
          <Flex className={styles.logo}>
            <ReactLogo />
          </Flex>

          <ButtonGroup as="nav" spacing={0} isAttached size="sm">
            <Button variant="ghost" as={Link} to="/" className={styles.btn}>
              {t('home')}
            </Button>

            {
            user.token && (
              <button className={styles.btnMenu} onClick={onOpen} type="button">
                <HamburgerIcon />
              </button>
            )
            }
            {
              (user.token && user.role !== 'admin') && (
                <Button variant="ghost" as={Link} to="/user/profile" className={styles.btn}>
                  {t('profile')}
                </Button>
              )
            }

            {
            user.token
              ? (
                <Button
                  as={Link}
                  className={`${styles.btn} ${styles.create}`}
                  colorScheme="ptRed"
                  onClick={onLogout}
                  to="/login"
                  variant="solid"
                >
                  {t('logout')}
                </Button>
              )
              : (
                <Button variant="ghost" as={Link} to="/login" className={styles.btn}>
                  {t('login')}
                </Button>
              )
          }
            {
          !user.token && (
            <Button
              as={Link}
              className={`${styles.btn} ${styles.create}`}
              colorScheme="ptRed"
              to="/signup"
              variant="solid"
            >
              { t('sign_up') }
            </Button>
          )
        }
          </ButtonGroup>
        </Flex>
      </Flex>
      <Menu onClose={onClose} isOpen={isOpen} />
    </>
  );
};

export default LogoNav;
