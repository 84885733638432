import React from 'react';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { func, number } from 'prop-types';

const CustomNumberInput = ({
  value, min, max, change,
}) => (
  <NumberInput defaultValue={value} min={min} max={max} maxW={50} size="xs" onChange={change}>
    <NumberInputField />
    <NumberInputStepper>
      <NumberIncrementStepper />
      <NumberDecrementStepper />
    </NumberInputStepper>
  </NumberInput>
);

CustomNumberInput.defaultProps = {
  min: 0,
  max: 100,
  value: 0,
};

CustomNumberInput.propTypes = {
  min: number,
  max: number,
  value: number,
  change: func.isRequired,
};

export default CustomNumberInput;
