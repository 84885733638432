import React from 'react';
import {
  Input, VStack, Text,
} from '@chakra-ui/react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const inputStyle = {
  backgroundColor: '#ececec',
  border: '1px solid #d6d6d6',
  borderRadius: '5px',
  color: '#444',
  fontWeight: 400,
  focusBorderColor: '#b42519',
  letterSpacing: '1px',
  marginBottom: '1rem',
  padding: '0.5rem',
  width: '100%',
  transition: 'border 2s cubic-bezier(0.075, 0.82, 0.165, 1)',
};

const RoutineInfo = ({
  setName, setDate, name, date,
}) => {
  const { t } = useTranslation();
  return (
    <form style={{ display: 'flex', justifyContent: 'space-around', margin: '1rem 0' }}>
      <VStack>
        <Text as="label" htmlFor="name">{t('routine_name')}</Text>
        <Input
          name="name"
          onChange={({ target }) => setName(target.value)}
          placeholder={t('routine_name')}
          value={name}
          {...inputStyle}
        />
      </VStack>
      <VStack w="45%">
        <Text as="label" htmlFor="date">{t('start_date')}</Text>
        <Input
          name="date"
          onChange={({ target }) => setDate(target.value)}
          placeholder="1996/06/28"
          value={date}
          type="date"
          {...inputStyle}
        />
      </VStack>
    </form>
  );
};

RoutineInfo.propTypes = {
  setDate: propTypes.func.isRequired,
  setName: propTypes.func.isRequired,
  date: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
};

export default RoutineInfo;
