import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_PROD}/preprogrammed`,
});

const createPreprogrammed = async (data) => {
  try {
    const token = localStorage.getItem('userAuth');
    const res = await api.post(
      '/',
      data,
      { headers: { authorization: token } },
    );
    console.log('response from service', res);
    return res.data.preProgrammedWorkout;
  } catch (error) {
    return error.message;
  }
};

const updatePreprogrammed = async (data, id) => {
  try {
    const token = localStorage.getItem('userAuth');
    const res = await api.put(
      `/${id}`,
      data,
      { headers: { authorization: token } },
    );
    return res.data.preProgrammedWorkout;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getPreprogrammed = async () => {
  try {
    const token = localStorage.getItem('userAuth');
    const res = await api.get(
      '/',
      { headers: { authorization: token } },
    );
    return res.data.preProgrammedWorkouts;
  } catch (error) {
    return error.message;
  }
};

const deletePreprogrammed = async (id) => {
  try {
    const token = localStorage.getItem('userAuth');
    const res = await api.delete(
      `/${id}`,
      { headers: { authorization: token } },
    );
    return res.data;
  } catch (error) {
    return error.message;
  }
};

export {
  getPreprogrammed,
  createPreprogrammed,
  deletePreprogrammed,
  updatePreprogrammed,
};
