import React from 'react';
import { string } from 'prop-types';
import { Flex } from '@chakra-ui/react';

const NoteLine = ({ note }) => (
  <Flex justifyContent="center" borderTop="1px solid red" mt="1rem">
    Note:
    {' '}
    {note}
  </Flex>
);

NoteLine.propTypes = {
  note: string.isRequired,
};

export default NoteLine;
