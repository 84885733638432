// const baseURL = process.env.REACT_APP_API_URL;
const baseURL = `${process.env.REACT_APP_API_URL_PROD}`;
// const baseURL = 'http://localhost:8100/api';

const fetchSinToken = (endpoint, data, method = 'GET') => {
  const url = `${baseURL}/${endpoint}`; // localhost:4000/api/----

  if (method === 'GET') {
    return fetch(url);
  }
  return fetch(url, {
    method,
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

const fetchConToken = (endpoint, data, method = 'GET') => {
  const url = `${baseURL}/${endpoint}`; // localhost:4000/api/----
  const token = localStorage.getItem('userAuth') || '';
  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        authorization: token,
      },
    });
  }
  return fetch(url, {
    method,
    headers: {
      'Content-type': 'application/json',
      authorization: token,
    },
    body: JSON.stringify(data),
  });
};

export { fetchSinToken, fetchConToken };
