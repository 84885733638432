import React from 'react';
import { bigContainerStyle } from 'theme/commonStyles';

const Customer = () => (
  <div style={bigContainerStyle}>
    Customer
  </div>
);

export default Customer;
