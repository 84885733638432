import React, { useState } from 'react';
import {
  Heading, Flex, IconButton, Grid, GridItem, useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';
import { shape, func } from 'prop-types';

import useDelete from 'hooks/useDelete';
import useLang from 'hooks/useLang';
import { deleteTemplate } from 'services/template';

import DeleteAlert from 'components/common/DeleteAlert';
import Popup from './Popup';

const gridStyle = {
  color: '#ededed',
  padding: '.5rem 1rem',
  templateColumns: '80% 1fr',
  templateRows: 'repeat(3, fr)',
  mt: '.5rem',
  fontSize: 12,
  background: '#252625',
  borderRadius: '10px',
};

const TemplateList = ({ list, handleClickEdit }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [templateId, setTemplateID] = useState('');
  const [templateElement, setTemplateElement] = useState({});
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { t, lang } = useLang();

  const handleClose = () => setDeleteOpen(false);
  const handleClick = (id) => {
    setTemplateID(id);
    setDeleteOpen(true);
  };
  return (
    <>
      <Flex as="section" direction="column" w={['100%', undefined, '80%']}>
        <Heading alignSelf="center">{t('template_workout')}</Heading>
        {
          list.map((item) => (
            <React.Fragment key={item._id}>
              <Grid
                {...gridStyle}
                onClick={() => {
                  console.log('item', item);
                  setTemplateElement(item);
                  onOpen();
                }}
              >
                <GridItem as="p">
                  {item.name[lang]}
                </GridItem>
                <GridItem rowSpan={3} display="flex" alignItems="center" justifyContent="center">
                  <IconButton
                    icon={<CloseIcon />}
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick(item._id);
                    }}
                    colorScheme="ptRed"
                  />
                </GridItem>
                <GridItem as="p">
                  {t('created')}
                  {' '}
                  {dayjs(item.created_at).format('MM-DD-YYYY')}
                </GridItem>
                <GridItem as="p">
                  {t('program_duration')}
                  {' '}
                  {item.sessions.length}
                </GridItem>
              </Grid>
            </React.Fragment>
          ))
        }
        {
          deleteOpen && (
            <DeleteAlert
              open={deleteOpen}
              close={handleClose}
              id={templateId}
              title={t('delete_template')}
              func={() => useDelete('templates', deleteTemplate)}
            />
          )
        }
        {
          isOpen && (
            <Popup
              onClose={onClose}
              isOpen={isOpen}
              title={t('program_preview', { name: templateElement.name[lang] })}
              mode="template"
              item={templateElement}
              handleClickEdit={handleClickEdit}
            />
          )
        }
      </Flex>
    </>
  );
};

TemplateList.propTypes = {
  list: shape([]).isRequired,
  handleClickEdit: func.isRequired,
};

export default TemplateList;
