import React from 'react';
import CalendarScreen from 'components/calendar/CalendarScreen';

const Month = () => (
  <>
    <CalendarScreen category="month" />
  </>
);

export default Month;
