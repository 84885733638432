const columns = [
  {
    Header: { en: 'Exercise', es: 'Ejercicio' },
    accessor: 'exercise',
  },
  {
    Header: { en: 'Sets', es: 'Sets' },
    accessor: 'sets',
  },
  {
    Header: { en: 'Reps', es: 'Reps' },
    accessor: 'reps',
  },
  {
    Header: { en: 'Option', es: 'Opción' },
    accessor: 'opt',
  },
  {
    Header: { en: 'id', es: 'id' },
    accessor: 'id',
  },
];

const data = [
  {
    exercise: { en: 'Choose Exercise', es: 'Elegir ejercicio' },
    sets: '1',
    reps: '1',
    opt: 'delete',
    id: '',
  },
];

const initialState = {
  columns,
  data,
};

const getRows = (row, lang) => (row.exercises.map((item) => ({
  exercise: item.id_exercise.name[lang],
  sets: String(item.sets.length),
  reps: String(item.sets[0].reps),
  opt: 'delete',
  id: item.id_exercise._id,
})));

const getPreModel = (preprogrammed, lang) => ({
  columns,
  data: getRows(preprogrammed, lang),
});

const preCategoryReducer = (state, action) => {
  const {
    type, rowIdx, columnIdx, value, exerciseIdx, preprogrammed, lang,
  } = action;
  switch (type) {
    case 'GET_PRE': return getPreModel(preprogrammed, lang);
    case 'ADD_ROW': return {
      ...state,
      data: [
        ...state.data, {
          ...data[0],
          // exercise: 'Choose Exercise',
          // sets: '1',
          // reps: '1',
          // opt: 'delete',
        },
      ],
    };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: state.data.map((row, index) => {
          if (index === rowIdx) {
            return {
              ...state.data[rowIdx],
              [columnIdx]: exerciseIdx ? `${value}*${exerciseIdx}` : value,
              [columnIdx]: value,
            };
          }
          return row;
        }),
      };
    case 'DELETE_ROW':
      return {
        ...state,
        data: state.data.filter((_, index) => index !== +value),
      };
    default: return state;
  }
};

export default preCategoryReducer;

const addRow = () => ({ type: 'ADD_ROW' });
const deleteRow = (value) => ({ type: 'DELETE_ROW', value });

const updateData = (value, rowIdx, columnIdx, exerciseIdx) => ({
  type: 'UPDATE_DATA', value, rowIdx, columnIdx, exerciseIdx,
});
const makePreprogrammed = (preprogrammed, lang) => ({ type: 'GET_PRE', preprogrammed, lang });

export {
  initialState,
  addRow,
  updateData,
  deleteRow,
  makePreprogrammed,
};
