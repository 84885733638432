/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/prop-types */
import React from 'react';
import propTypes from 'prop-types';
import MaterialTable from 'material-table';
import {
  Button, Badge,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { infoStyle } from 'theme/commonStyles';

import useGetAll from 'hooks/useGetAll';
import useLang from 'hooks/useLang';
import { getExercises } from 'services/exercise';
import localizationMaterialTable from 'helpers/localizationMaterialTable';
import customFilterAndSearch from 'helpers/customFilterAndSearch';
import tableIcons from 'theme/tableIcons';
import Toggle from 'components/common/Toggle';
import ErrorBoundary from 'components/common/ErrorBoundary';

import { updateData } from '../Custom/customReducer';

import Recomendation from '../Recomendation';

// const ExerciseOverlay = ({
//   onClose, isOpen, position, dispatch, final, category, save, sub,
// }) => {
//   const {
//     data, isLoading, isError, isSuccess,
//   } = useGetAll('exercises', null, getExercises);
//   const { role } = useSelector(({ user }) => user);
//   const { t, lang } = useLang();

//   const handleClick = (rowIdx, columnIdx, name, exerciseIdx) => {
//     console.log(rowIdx, columnIdx, name, exerciseIdx);
//     dispatch(updateData(name, rowIdx, columnIdx, exerciseIdx));
//     save();
//     onClose();
//   };

//   const localization = localizationMaterialTable();

//   return (
//     <Modal
//       isOpen={isOpen}
//       onClose={onClose}
//       finalFocusRef={final}
//     >
//       <ModalOverlay />
//       <ModalContent maxWidth="35rem">
//         <ModalHeader>
//           {t('select_exercise')}
//         </ModalHeader>
//         <ModalCloseButton />
//         <ModalBody>
//           {
//             isLoading && <p style={infoStyle}>{t('loading_exercises')}</p>
//           }
//           {
//             isError && <p style={infoStyle}>{t('something_wrong')}</p>
//           }
//           {
//             (data && isSuccess && role !== 'admin') && (
//               <ErrorBoundary>
//                 <Toggle Component={<Badge variant="outline">{t('favourite_exercises')}</Badge>}>
//                   <Recomendation handleClick={handleClick} position={position} />
//                 </Toggle>
//               </ErrorBoundary>
//             )
//           }
//           {
//             (data && isSuccess) && (
//               <ErrorBoundary>
//                 <MaterialTable
//                   columns={[
//                     {
//                       title: t('table_name'),
//                       field: 'name',
//                       cellStyle: { width: '80%' },
//                       customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'name'),
//                     },
//                     {
//                       title: t('table_category'),
//                       field: 'category',
//                       customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'category'),
//                     },
//                     { title: t('table_options'), field: 'options' },
//                   ]}
//                   data={[
//                     ...data
//                       .filter(({ category_id }) => {
//                         // console.log('categoryId', category_id);
//                         return (category === 'Add exercise' ? category_id : category_id.some((item) => item.name === category));
//                       })
//                       .filter((dataToFilter) => {
//                         if (sub !== undefined && sub !== 'All') {
//                           // console.log('data', dataToFilter, 'sub', sub);
//                           // console.log('datatofilter', dataToFilter.muscle_system.en.includes(sub));
//                           return dataToFilter.muscle_system.en.includes(sub)
//                             ? dataToFilter.muscle_system.en.includes(sub)
//                             : dataToFilter.action.en.split('\n').includes(sub);
//                         }
//                         return dataToFilter;
//                       })
//                       .map(({ name, category_id, _id }) => ({
//                         name: name[lang],
//                         category: category_id[0].name,
//                         options: <Button
//                           variant="link"
//                           onClick={() => handleClick(position.rowIdx, position.columnIdx, name[lang], _id)}
//                         >
//                           select
//                         </Button>,
//                       })),
//                   ]}
//                   title=""
//                   icons={tableIcons}
//                   localization={localization}
//                 />
//               </ErrorBoundary>
//             )
//           }
//         </ModalBody>
//       </ModalContent>
//     </Modal>
//   );
// };

const ExerciseOverlay = ({
  onClose, isOpen, position, dispatch, final, category, save, sub,
}) => {
  const {
    data, isLoading, isError, isSuccess,
  } = useGetAll('exercises', null, getExercises);
  const { role } = useSelector(({ user }) => user);
  const { t, lang } = useLang();

  const handleClick = (rowIdx, columnIdx, name, exerciseIdx) => {
    console.log(rowIdx, columnIdx, name, exerciseIdx);
    dispatch(updateData(name, rowIdx, columnIdx));
    dispatch(updateData(exerciseIdx, rowIdx, 'id'));
    save();
    onClose();
  };

  const localization = localizationMaterialTable();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={final}
    >
      <ModalOverlay />
      <ModalContent maxWidth="35rem">
        <ModalHeader>
          {t('select_exercise')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {
            isLoading && <p style={infoStyle}>{t('loading_exercises')}</p>
          }
          {
            isError && <p style={infoStyle}>{t('something_wrong')}</p>
          }
          {
            (data && isSuccess && role !== 'admin') && (
              <ErrorBoundary>
                <Toggle Component={<Badge variant="outline">{t('favourite_exercises')}</Badge>}>
                  <Recomendation handleClick={handleClick} position={position} />
                </Toggle>
              </ErrorBoundary>
            )
          }
          {
            (data && isSuccess) && (
              <ErrorBoundary>
                <MaterialTable
                  columns={[
                    {
                      title: t('table_name'),
                      field: 'name',
                      cellStyle: { width: '80%' },
                      customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'name'),
                    },
                    {
                      title: t('table_category'),
                      field: 'category',
                      customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'category'),
                    },
                    { title: t('table_options'), field: 'options' },
                  ]}
                  data={[
                    ...data
                      .filter(({ category_id }) => {
                        // console.log('categoryId', category_id);
                        return (category === 'All' ? category_id : category_id.some((item) => item.name === category));
                      })
                      .filter((dataToFilter) => {
                        if (sub !== undefined && sub !== 'All') {
                          return dataToFilter.muscle_system.en.includes(sub)
                            ? dataToFilter.muscle_system.en.includes(sub)
                            : dataToFilter.action.en.split('\n').includes(sub);
                        }
                        return dataToFilter;
                      })
                      .map(({ name, category_id, _id }) => ({
                        name: name[lang],
                        category: category_id[0].name,
                        options: <Button
                          variant="link"
                          onClick={() => handleClick(position.rowIdx, position.columnIdx, name[lang], _id)}
                        >
                          {t('table_select')}
                        </Button>,
                      })),
                  ]}
                  title=""
                  icons={tableIcons}
                  localization={localization}
                />
              </ErrorBoundary>
            )
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ExerciseOverlay.defaultProps = {
  final: null,
  category: 'All',
  sub: 'All',
};

ExerciseOverlay.propTypes = {
  save: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  dispatch: propTypes.func.isRequired,
  position: propTypes.shape({
    rowIdx: propTypes.number.isRequired,
    columnIdx: propTypes.string.isRequired,
  }).isRequired,
  final: propTypes.node,
  category: propTypes.string,
  sub: propTypes.string,
};

export default ExerciseOverlay;
