/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from 'react-query';

const useDelete = (word, func) => {
  const queryClient = useQueryClient();
  return useMutation((id) => func(id), {
    onMutate: async (updatedData) => {
      await queryClient.cancelMutations(word);

      const previous = queryClient.getQueryData(word);
      return { previous };
    },
    onError: (context) => {
      queryClient.setQueryData(word, context.previous);
    },
    onSettled: () => {
      queryClient.invalidateQueries(word);
    },
  });
};

export default useDelete;
