/* eslint-disable max-len */
import React, { useState } from 'react';
import { Input, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import useGetAll from 'hooks/useGetAll';
import { getPreprogrammed } from 'services/preProgrammed';
import { getTemplates } from 'services/template';

import { inputStyle, bigContainerStyle } from 'theme/commonStyles';

import DisplayMessage from 'components/common/DisplayMessage';
import PreprogrammedList from './PreprogrammedList';
import TemplateList from './TemplateList';
import Paginate from '../common/Paginate';

const Programs = () => {
  const [redirection, setRedirection] = useState(false);
  const [mode, setMode] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(undefined);
  const [word, setWord] = useState('');
  const { data: templateRes, isLoading: isTemplateLoading, isError: templateError } = useGetAll('templates', null, getTemplates);
  const { data: preRes, isLoading: isPreLoading, isError: preprogrammedError } = useGetAll('preprogrammed', null, getPreprogrammed);
  const { t } = useTranslation();
  const regex = new RegExp(word, 'gi');

  const handleClickEdit = (type, data) => {
    setDataToEdit(data);
    setMode(type);
    setRedirection(true);
  };

  if (redirection) {
    const stateToSend = {
      mode,
      data: dataToEdit,
    };
    const config = {
      pathname: '/user/create',
      state: stateToSend,
    };
    return <Redirect to={config} />;
  }

  if (templateError || preprogrammedError) return <DisplayMessage message={t('something_wrong')} />;
  return (
    <main style={bigContainerStyle}>
      {
        (isPreLoading && isTemplateLoading) && <DisplayMessage message={t('loading_both')} loading />
      }
      {
        (templateRes && preRes) && (
          <>
            <Input onChange={({ target }) => setWord(target.value)} value={word} w="50%" {...inputStyle} placeholder={t('search')} />
            <Stack
              direction={['column', 'row']}
              spacing={8}
              w="90%"
            >
              {/* {
                templateRes.forEach((item) => {
                  console.log('item', item.name, 'regex', regex);
                  console.log('regex', regex.test(item.name));
                })
              } */}
              <Paginate
                list={templateRes}
                Component={TemplateList}
                regex={regex}
                extraFeatures={{ handleClickEdit }}
              />
              <Paginate
                list={preRes}
                Component={PreprogrammedList}
                regex={regex}
                extraFeatures={{ handleClickEdit }}
              />
              {/* <TemplateList list={templateRes.filter((template) => regex.test(template.name))} /> */}
              {/* <PreprogrammedList list={preRes.filter((pre) => regex.test(pre.workout_name))} /> */}
            </Stack>
          </>
        )
      }
    </main>
  );
};

export default Programs;
