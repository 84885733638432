/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {
  Input, Grid,
} from '@chakra-ui/react';

import useLang from 'hooks/useLang';
import { inputStyle } from 'theme/commonStyles';

import { string, func } from 'prop-types';

const ProgramName = ({
  enName, esName, setName,
}) => {
  const { t } = useLang();
  return (
    <Grid w="80%" templateColumns="1fr 1fr" gap={2}>
      <label htmlFor="english_name">
        {t('en_program_name')}
        <Input
          {...inputStyle}
          value={enName}
          onChange={({ target }) => setName((prev) => ({ ...prev, en: target.value }))}
          id="english_name"
        />
      </label>

      <label htmlFor="spanish_name">
        {t('es_program_name')}
        <Input
          {...inputStyle}
          value={esName}
          onChange={({ target }) => setName((prev) => ({ ...prev, es: target.value }))}
          id="spanish_name"
        />
      </label>

    </Grid>
  );
};

ProgramName.propTypes = {
  enName: string.isRequired,
  esName: string.isRequired,
  setName: func.isRequired,
};

export default ProgramName;
