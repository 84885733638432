/* eslint-disable no-undef */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import {
  Button,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  Input, InputGroup, InputRightElement,
  Flex,
} from '@chakra-ui/react';
// import { useDispatch } from 'react-redux';
// import { updateCustomData } from 'reducers/customWorkout.reducer';
import { useSelector, useDispatch } from 'react-redux';
import {
  string, number, shape, bool, func, node,
} from 'prop-types';

import { inputStyle } from 'theme/commonStyles';

import CustomNumberInput from 'components/common/NumberInput';
import { updateData } from '../createPreprogrammed/preCategoryReducer';
// import { updateData } from '../Custom/customReducer';

const SetOverlay = ({
  onClose, isOpen, position: { rowIdx, columnIdx }, dispatch, final, save, defaultValue,
}) => {
  const [value, setValue] = useState(defaultValue);
  const initialRef = useRef();
  const handleValue = (newValue) => setValue(newValue);
  const handleClick = (e) => {
    e.preventDefault();
    dispatch(updateData(value, rowIdx, columnIdx));
    save();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef} finalFocusRef={final}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Set the exercise&apos;s load and reps
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form>
            <CustomNumberInput
              value={value}
              ref={initialRef}
              min={1}
              max={20}
              change={handleValue}
              {...inputStyle}
            />
            {/* <Input
              onChange={handleValue}
              // placeholder="load"
              ref={initialRef}
              type="number"
              value={value}
              {...inputStyle}
            /> */}
            <Button colorScheme="ptRed" onClick={handleClick} type="submit" float="right">
              Set
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

SetOverlay.defaultProps = {
  final: null,
};

SetOverlay.propTypes = {
  save: func.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  position: shape({
    rowIdx: number.isRequired,
    columnIdx: string.isRequired,
  }).isRequired,
  final: node,
  defaultValue: string.isRequired,
};

export default SetOverlay;
