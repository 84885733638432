/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react';
import {
  Button, IconButton,
  Table, Thead, Tbody, Th, Tr, Td,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useTable } from 'react-table';
import propTypes from 'prop-types';

import useLang from 'hooks/useLang';

// const TableDisplay = React.forwardRef(({
//   columns, data, handleOpen, position, handleDelete,
// }, ref) => {
//   const { lang } = useLang();
//   const tableInstance = useTable({ columns, data });
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow,
//     initialState,
//     // setHiddenColumns,
//   } = tableInstance;
//   const checkPosition = (cA, rA, cB, rB) => ((cA === cB && rA === rB) ? ref : null);
//   let color = false;
//   initialState.hiddenColumns = ['categoryName'];
//   return (
//     <Table {...getTableProps()} size="sm" colorScheme="blackAlpha">
//       <Thead>
//         {
//           headerGroups.map((headerGroup) => (
//             <Tr {...headerGroup.getHeaderGroupProps()}>
//               {
//                 headerGroup.headers.map((column) => (
//                   <Th {...column.getHeaderProps()}>
//                     {column.render(() => column.Header[lang])}
//                   </Th>
//                 ))
//               }
//             </Tr>
//           ))
//         }
//       </Thead>
//       <Tbody {...getTableBodyProps()}>
//         {
//           rows.map((row, idx) => {
//             // automatically switch the color of the table each 3 row
//             // console.log('row', row);
//             if (idx % 3 === 0) color = !color;
//             prepareRow(row);
//             return (
//               <Tr
//                 {...row.getRowProps()}
//                 backgroundColor={color ? 'red.100' : 'none'}
//               >
//                 {
//                   row.cells.map((cell) => (
//                     <Td {...cell.getCellProps()}>
//                       {cell.render(({ row: cellRow, column, value }) => {
//                         // console.log('cell', cell);
//                         if (cell.column.Header === 'Sub') return <p>{cell.value}</p>;
//                         if (value === 'delete') {
//                           return (
//                             <IconButton
//                               size={['sm', 'md']}
//                               onClick={() => handleDelete(cellRow.id)}
//                               icon={<DeleteIcon />}
//                               aria-label="delete row"
//                             />
//                           );
//                         }
//                         return (
//                           <Button
//                             onClick={() => handleOpen(cellRow.index, column.id, value, row.original?.sub)}
//                             color="gray.700"
//                             variant="link"
//                             size="sm"
//                             ref={checkPosition(column.id, cellRow.index, position.columnIdx, position.rowIdx)}
//                           >
//                             {value[lang] ? value[lang] : value.split('*')[0].substring(0, 20)}
//                           </Button>
//                         );
//                       })}
//                     </Td>
//                   ))
//                 }
//               </Tr>
//             );
//           })
//         }
//       </Tbody>
//     </Table>
//   );
// });

const returnCellTableElement = (cell, handler, lang) => {
  const { value, column, row } = cell;
  // console.log('cell', cell);
  const isTranslated = value[lang] || value;
  switch (column.id) {
    case 'opt': return (
      <IconButton
        size={['sm', 'md']}
        onClick={() => handler.delete(row.id)}
        icon={<DeleteIcon />}
        aria-label="delete row"
      />
    );
    case 'sub': return (
      <p>{isTranslated}</p>
    );
    default: return (
      <Button
        variant="link"
        color="gray.700"
        onClick={() => handler.open(row.index, column.id, value, row.original?.category, row.original?.sub)}
      >
        {isTranslated.substring(0, 20)}
      </Button>
    );
  }
};

const TableDisplay = React.forwardRef(({
  columns, data, handleOpen, position, handleDelete,
}, ref) => {
  // memoized the passed data and columns
  // const memoData = useMemo(() => data, []);
  // console.log('tableData ', data, ' memoData ', memoData);
  const memoColumns = useMemo(() => columns, []);
  // console.log('tableColumns ', columns, ' memoColumns ', memoColumns);

  // create the table Instance
  const tableInstance = useTable({ columns: memoColumns, data });
  // getting methods from tableInstance
  const {
    initialState,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;
  // get the current lang
  const { lang } = useLang();
  let color = false;

  // create an obj with the func to be used
  const handler = {
    open: handleOpen,
    delete: handleDelete,
  };

  // set the hidden columns
  initialState.hiddenColumns = ['id', 'sub', 'category'];
  return (
    // apply the table props
    <Table {...getTableProps()} size="sm">
      <Thead>
        {
          // loop over the header in each row
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <Tr {...headerGroup.getHeaderGroupProps()}>
              { // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                  <Th {...column.getHeaderProps()}>
                    {// Render the header, based on the lang
                      column.render(() => column.Header[lang])
                    }
                  </Th>
                ))
              }
            </Tr>
          ))
        }
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {// Loop over the table rows
          rows.map((row, idx) => {
            // switch table's color each 3 row
            if (idx % 3 === 0) color = !color;
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <Tr {...row.getRowProps()} backgroundColor={color ? 'red.100' : 'none'}>
                {// Loop over the rows cells
                  row.cells.map((cell) => (
                    // Apply the cell props
                    <Td {...cell.getCellProps()}>
                      {// Render the cell contents
                        cell.render((obj) => returnCellTableElement(obj, handler, lang))
                      }
                    </Td>
                  ))
                }
              </Tr>
            );
          })
        }
      </Tbody>
    </Table>
  );
});

TableDisplay.propTypes = {
  columns: propTypes.shape([]).isRequired,
  data: propTypes.shape([]).isRequired,
  handleOpen: propTypes.func.isRequired,
  handleDelete: propTypes.func.isRequired,
  position: propTypes.shape({
    columnIdx: propTypes.string.isRequired,
    rowIdx: propTypes.number.isRequired,
  }).isRequired,
};

export default TableDisplay;
