/* eslint-disable no-unused-vars */
import React, {
  useState, useRef, useReducer, useEffect,
} from 'react';
import {
  Button, Flex, useDisclosure, Heading,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { shape, number } from 'prop-types';

import { updateDay } from 'reducers/weekReducer.reducer';
import useLang from 'hooks/useLang';

import TableDisplay from 'components/routines/TableDisplay';
import ExerciseOverlay from 'components/routines/workout/Features/ExerciseOverlay';
import SetOverlay from '../Features/SetOverlay';
import preCategoryReducer, {
  initialState, addRow, deleteRow, makePreprogrammed,
} from './preCategoryReducer';

const CreatePreprogrammed = ({ actual, date, data }) => {
  const [state, dispatch] = useReducer(preCategoryReducer, initialState);
  const [sessionSaved, toggleSession] = useReducer((prev) => !prev, false);
  const [position, setPosition] = useState({ rowIdx: 0, columnIdx: 0 });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sessionDispatch = useDispatch();
  const { t, lang } = useLang();
  const finalRef = useRef();

  const defaultValue = state.data[position.rowIdx][position.columnIdx];

  const handleOpen = (rowIdx, columnIdx) => {
    setPosition({ rowIdx, columnIdx });
    onOpen();
  };
  const handleDelete = (idx) => {
    dispatch(deleteRow(idx));
    toggleSession();
  };
  const handleAdd = () => {
    dispatch(addRow());
    toggleSession();
  };
  const handleSave = () => sessionDispatch(updateDay(state.data, date.week, date.day));

  useEffect(() => {
    if (data) {
      console.log('data', data);
      dispatch(makePreprogrammed(data, lang));
      toggleSession();
    }
  }, [data]);

  useEffect(() => {
    handleSave();
  }, [sessionSaved]);

  return (
    <>
      <div style={{ display: (actual.day === date.day && actual.week === date.week) ? 'block' : 'none' }}>
        <Flex justifyContent="space-around" mb="1rem">
          <Heading>
            {t('workout_title', { day: date.day, week: date.week })}
          </Heading>

          <Button onClick={handleAdd} colorScheme="ptRed">
            {t('add_row')}
          </Button>
        </Flex>
        <form>
          <TableDisplay
            columns={state.columns}
            data={state.data}
            handleOpen={handleOpen}
            handleDelete={handleDelete}
            position={position}
            ref={finalRef}
          />
        </form>
      </div>
      {
      (position.columnIdx !== 'exercise' && isOpen) ? (
        <SetOverlay
          isOpen={isOpen}
          onClose={onClose}
          position={position}
          dispatch={dispatch}
          final={finalRef}
          save={toggleSession}
          defaultValue={defaultValue}
        />
      )
        : (
          <ExerciseOverlay
            isOpen={isOpen}
            onClose={onClose}
            position={position}
            final={finalRef}
            dispatch={dispatch}
            save={toggleSession}
          />
        )
    }
    </>
  );
};

CreatePreprogrammed.defaultProps = {
  data: undefined,
};

CreatePreprogrammed.propTypes = {
  date: shape({
    day: number.isRequired,
    week: number.isRequired,
  }).isRequired,
  actual: number.isRequired,
  data: shape({}),
};

export default CreatePreprogrammed;
