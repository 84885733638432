/* eslint-disable consistent-return */
const getRoutineExercise = (sessions) => {
  console.log('session to check emptyness', sessions);
  let empty = false;
  sessions.forEach(({ exercises }) => {
    if (exercises.some(({ exercise }) => exercise === 'Add exercise')) empty = true;
  });
  return empty;
};

const getRoutineCategory = (sessions) => {
  const categoryList = ['Traditional', 'Functional', 'Strength', 'Rehab', 'Metabolic', 'SAQ'];
  let empty = false;
  console.log('category session', sessions);
  sessions.forEach(({ exercises }) => {
    if (exercises.some(({ category }) => categoryList.indexOf(category) !== -1)) empty = true;
  });
  return empty;
};

const isRoutineEmpty = (session, mode) => (mode === 'template'
  ? getRoutineCategory(session)
  : getRoutineExercise(session));

// ------------------------------------------------------------------------------------
// const getProgramExercise = (sessions) => {
//   let empty = false;
//   if (sessions.some((session) => Object.keys(session).length === 1)) return empty;
//   // if (Object.keys(sessions[0]).length === 1) return empty;
//   console.log(sessions);
//   sessions.forEach(({ exercises }) => {
//     if (exercises.some(({ id_exercise }) => id_exercise === undefined)) empty = true;
//   });
//   return empty;
// };

// const getProgramCategory = (sessions) => {
//   let empty = false;
//   if (sessions.some((session) => Object.keys(session).length === 1)) return empty;
//   // if (Object.keys(sessions[0]).length === 1) return empty;
//   sessions.forEach(({ categoryList }) => {
//     if (categoryList.some((category) => !category)) empty = true;
//   });
//   return empty;
// };

const getProgramCategory = (data) => {
  let empty = false;
  data.forEach((week) => {
    console.log('week to check', week);
    week.days.forEach((day) => {
      console.log('day', day);
      const fullDaysChecked = day.categoryList.every(({ category }) => category.en !== 'Choose category' || category.es !== 'Elegir categoria');
      if (!fullDaysChecked) empty = true;
    });
  });
  return empty;
};
const getProgramExercise = (data) => {
  let empty = false;
  data.forEach((week) => {
    console.log('week to check', week);
    week.days.forEach((day) => {
      const fullDaysChecked = day.exercises.every(({ exercise }) => exercise !== 'Choose Exercise');
      if (!fullDaysChecked) empty = true;
    });
  });
  return empty;
};

const isProgramEmpty = (data, mode) => (mode === 'template'
  ? getProgramCategory(data)
  : getProgramExercise(data));

const isEmpty = (data) => {
  const checkFullness = { true: 0, false: 0 };
  // loop over the weeks
  data.forEach((week) => {
    // loop over the days
    week.days.forEach((day) => {
      // check if its a template or preprogrammed
      const isTemplate = Boolean(day?.categoryList.length);
      const prop = isTemplate ? 'categoryList' : 'exercises';
      // check that every row has the id prop full
      const isFull = day[prop].every(({ id }) => id);
      console.log('isfull', isFull);
      if (isFull) checkFullness.true += 1;
      else checkFullness.false += 1;
      // console.log('week ', week.number, ' day ', day.number, ' isFull ', full);
    });
  });
  return Boolean(checkFullness.false === 0);
};

export {
  isRoutineEmpty,
  isProgramEmpty,
  isEmpty,
};
