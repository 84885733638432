import React, { useState } from 'react';
import {
  Heading, Flex, IconButton, Grid, GridItem, useDisclosure,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';
import { shape, func } from 'prop-types';

import useDelete from 'hooks/useDelete';
import useLang from 'hooks/useLang';
import { deletePreprogrammed } from 'services/preProgrammed';

import DeleteAlert from 'components/common/DeleteAlert';
import Popup from './Popup';

const gridStyle = {
  color: '#ededed',
  padding: '.5rem 1rem',
  templateColumns: '80% 1fr',
  templateRows: 'repeat(3, fr)',
  mt: '.5rem',
  fontSize: 12,
  background: '#252625',
  borderRadius: '10px',
};

const PreprogrammedList = ({ list, handleClickEdit }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [preprogrammedId, setPreprogrammedID] = useState('');
  const [preprogrammedElement, setPreprogrammedElement] = useState({});
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { t, lang } = useLang();
  // const lang = localStorage.getItem('i18nextLng');

  const handleClose = () => setDeleteOpen(false);
  const handleClick = (id) => {
    setPreprogrammedID(id);
    setDeleteOpen(true);
  };

  return (
    <Flex as="section" direction="column" w={['100%', undefined, '80%']}>
      <Heading alignSelf="center">{t('preprogrammed_workout')}</Heading>
      {
        list.map((item) => (
          <Grid
            key={item._id}
            {...gridStyle}
            onClick={() => {
              setPreprogrammedElement(item);
              onOpen();
            }}
          >
            <GridItem as="p">
              {item.workout_name[lang]}
            </GridItem>
            <GridItem rowSpan={3} display="flex" alignItems="center" justifyContent="center">
              <IconButton
                icon={<CloseIcon />}
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(item._id);
                }}
                colorScheme="ptRed"
              />
            </GridItem>
            <GridItem as="p">
              {t('created')}
              {' '}
              {dayjs(item.created_at).format('MM-DD-YYYY')}
            </GridItem>
            <GridItem as="p">
              {t('program_duration')}
              {' '}
              {item.sessions.length}
            </GridItem>
          </Grid>
        ))
      }
      {
        deleteOpen && (
          <DeleteAlert
            open={deleteOpen}
            close={handleClose}
            id={preprogrammedId}
            title={t('delete_preprogrammed')}
            func={() => useDelete('preprogrammed', deletePreprogrammed)}
          />
        )
      }
      {
        isOpen && (
          <Popup
            onClose={onClose}
            isOpen={isOpen}
            title={t('program_preview', { name: preprogrammedElement.workout_name[lang] })}
            mode="preprogrammed"
            item={preprogrammedElement}
            lang={lang}
            handleClickEdit={handleClickEdit}
          />
        )
      }
    </Flex>
  );
};

PreprogrammedList.propTypes = {
  list: shape([]).isRequired,
  handleClickEdit: func.isRequired,
};

export default PreprogrammedList;
