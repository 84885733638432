/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
// template func
import { addWeek, addDay } from 'reducers/weekReducer.reducer';

const setTemplateBasicvalues = (sets, reps) => Array.from(Array(5)).reduce((final, curr, idx) => {
  const setProp = `set${idx + 1}`;
  const value = idx + 1 <= sets ? `load/${reps}` : 'load/reps';
  const objUpdated = {
    ...final,
    [setProp]: value,
  };
  return objUpdated;
}, {});

const getCategoriesFromList = (list) => list.map((item) => ({
  category: item.category.name,
  sub: item?.sub,
  ...setTemplateBasicvalues(item.sets, item.reps),
}));

//--------------------------------------------------------------------------
// For routines

// const returnPredefineTemplate = (item) => item.sessions.reduce((final, curr) => {
//   console.log('in return predefine', item);

//   const weekSelected = final.find((week) => week.number === curr.week);
//   if (weekSelected !== undefined) {
//     const daySelected = weekSelected.days.find((day) => day.number === curr.day);
//     if (daySelected !== undefined) {
//       const dayUpdated = ({ ...daySelected, categoryList: getCategoriesFromList(curr.categoryList) });
//       return final
//         .map((week) => ((week.number === curr.week)
//           ? ({ ...week, days: week.days.map((day) => (day.number === curr.day ? dayUpdated : day)) })
//           : week));
//     }
//     const dayUpdated = { number: curr.day, categoryList: getCategoriesFromList(curr.categoryList) };
//     return final
//       .map((week) => ((week.number === curr.week)
//         ? ({ ...week, days: week.days.concat(dayUpdated) })
//         : week));
//   }
//   return final
//     .concat({
//       number: curr.week,
//       days: [
//         {
//           number: curr.day,
//           categoryList: getCategoriesFromList(curr.categoryList),
//         },
//       ],
//     });
// }, [{ number: 1, days: [{ number: 1, categoryList: [] }] }]);

const returnPredefineTemplate = (item) => item.sessions.reduce((final, curr) => {
  // console.log('in return predefine', item);

  const weekSelected = final.find((week) => week.number === curr.week);
  if (weekSelected !== undefined) {
    const daySelected = weekSelected.days.find((day) => day.number === curr.day);
    if (daySelected !== undefined) {
      const dayUpdated = ({ ...daySelected, categoryList: curr.categoryList });
      return final
        .map((week) => ((week.number === curr.week)
          ? ({ ...week, days: week.days.map((day) => (day.number === curr.day ? dayUpdated : day)) })
          : week));
    }
    const dayUpdated = { number: curr.day, categoryList: curr.categoryList };
    return final
      .map((week) => ((week.number === curr.week)
        ? ({ ...week, days: week.days.concat(dayUpdated) })
        : week));
  }
  return final
    .concat({
      number: curr.week,
      days: [
        {
          number: curr.day,
          categoryList: curr.categoryList,
        },
      ],
    });
}, [{ number: 1, days: [{ number: 1, categoryList: [] }] }]);

const returnPredefinePreprogrammed = (item) => item.sessions.reduce((final, curr) => {
  // console.log('in return predefine', item);

  const weekSelected = final.find((week) => week.number === curr.week);
  if (weekSelected !== undefined) {
    const daySelected = weekSelected.days.find((day) => day.number === curr.day);
    if (daySelected !== undefined) {
      const dayUpdated = ({ ...daySelected, exercises: curr.exercises, notes: curr.notes?.en });
      return final
        .map((week) => ((week.number === curr.week)
          ? ({ ...week, days: week.days.map((day) => (day.number === curr.day ? dayUpdated : day)) })
          : week));
    }
    const dayUpdated = { number: curr.day, exercises: curr.exercises, notes: curr.notes?.en };
    return final
      .map((week) => ((week.number === curr.week)
        ? ({ ...week, days: week.days.concat(dayUpdated) })
        : week));
  }
  return final
    .concat({
      number: curr.week,
      days: [
        {
          number: curr.day,
          exercises: curr.exercises,
          notes: curr.notes?.en,
        },
      ],
    });
}, [{ number: 1, days: [{ number: 1, exercises: [], notes: '' }] }]);

// -------------------------------------------------
// For programs
const returnPredefineTemplateProgram = (item) => item.sessions.reduce((final, curr) => {
  const weekSelected = final.find((week) => week.number === curr.week);
  if (weekSelected !== undefined) {
    const daySelected = weekSelected.days.find((day) => day.number === curr.day);
    if (daySelected !== undefined) {
      const dayUpdated = ({ ...daySelected, categoryList: curr.categoryList });
      return final
        .map((week) => ((week.number === curr.week)
          ? ({ ...week, days: week.days.map((day) => (day.number === curr.day ? dayUpdated : day)) })
          : week));
    }
    const dayUpdated = { number: curr.day, categoryList: curr.categoryList };
    return final
      .map((week) => ((week.number === curr.week)
        ? ({ ...week, days: week.days.concat(dayUpdated) })
        : week));
  }
  return final
    .concat({
      number: curr.week,
      days: [
        {
          number: curr.day,
          categoryList: curr.categoryList,
        },
      ],
    });
}, [{ number: 1, days: [{ number: 1, categoryList: [] }] }]);

const createWeekDayStructure = (data, dispatch) => {
  // used to ensure the comparation of the existence of the week's in each iteration
  const weeklist = [1];
  // set the basic week/day structure
  data.sessions.forEach((session) => {
    if (session.day === 1 && session.week === 1) return;
    // if it doesnt found the number of the actual session iterated in week list, dispatch a new week
    if (!weeklist.some((week) => week === session.week)) {
      dispatch(addWeek());
      weeklist.push(session.week);
    }
    // each week already comes with a day as defaul, so it check if the session day is one to avoid duplication
    if (session.day !== 1) dispatch(addDay(session.week));
  });
};

export {
  returnPredefineTemplate,
  returnPredefinePreprogrammed,
  returnPredefineTemplateProgram,
  createWeekDayStructure,
};
