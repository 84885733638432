import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Clients from 'components/clients';
import Profile from 'components/Profile';
import Planning from 'components/planning';
import Routines from 'components/routines';
import Evaluation from 'components/evaluation';
import Exercises from 'components/exercises';
import Customer from 'components/customer';
import Trainers from 'components/trainers';
import Create from 'components/create';
import Programs from 'components/programs';
import Feedback from 'components/Feedback';

const User = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/planning`} component={Planning} />
      <Route exact path={`${path}/clients`} component={Clients} />
      <Route exact path={`${path}/profile`} component={Profile} />
      <Route exact path={`${path}/routines`} component={Routines} />
      <Route exact path={`${path}/exercises`} component={Exercises} />
      <Route exact path={`${path}/evaluation`} component={Evaluation} />
      <Route exact path={`${path}/customer`} component={Customer} />
      <Route exact path={`${path}/trainers`} component={Trainers} />
      <Route exact path={`${path}/create`} component={Create} />
      <Route exact path={`${path}/programs`} component={Programs} />
      <Route exact path={`${path}/feedback`} component={Feedback} />
    </Switch>
  );
};

export default User;
