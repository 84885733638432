const Input = {
  baseStyle: {
    borderRadius: '5px',
    fontWeight: 400,
    letterSpacing: '1px',
    transition: 'border 2s cubic-bezier(0.075, 0.82, 0.165, 1)',
  },
  variants: {
    solid: {
      backgroundColor: '#ececec',
      // border: '1px solid #d6d6d6',
      color: '#444',
      focusBorderColor: '#b42519',
    },
  },
  defaultProps: {
    variant: 'solid',
  },
};

export default Input;
