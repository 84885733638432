/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-escape */
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input } from '@chakra-ui/react';
import propTypes from 'prop-types';

import { inputStyle } from 'theme/commonStyles';

import { signup } from 'services/user';
import notificationDisplay from 'utils/notificationDisplay';
import { loaded, loading } from 'reducers/loader.reducer';
import PasswordInput from 'components/common/PasswordInput';

import styles from './Basic.module.scss';

const Basic = ({ move, set, info }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onSubmit = async (data) => {
    if (data.password !== data.confirm) return notificationDisplay(dispatch, 'Passwords entered do not match', 'error');

    const dataToSend = {
      email: data.email,
      password: data.password,
      role: 'trainer',
    };
    dispatch(loading());

    try {
      const basicResponse = await signup(dataToSend);

      if (basicResponse.data) throw new Error(basicResponse.data.error || basicResponse.data);

      dataToSend.id_user = basicResponse.user._id;
      const user_data = { id_user: dataToSend.id_user, date_valid: Date.now() };
      localStorage.setItem('user_data', JSON.stringify(user_data));
      set(dataToSend);
      move((prev) => prev + 1);
    } catch (exception) {
      notificationDisplay(dispatch, exception.message, 'error');
    }
    dispatch(loaded());
  };
  return (
    <section className={styles.root}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.sides}>
        <h4 className={styles.titleRight}>{t('create_account')}</h4>
        <p className={styles.sub}>{t('register_info')}</p>

        <Controller
          control={control}
          defaultValue={info.email || ''}
          name="email"
          rules={{ required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g }}
          render={({ field }) => (
            <Input
              placeholder={t('email')}
              {...field}
              {...inputStyle}
              mb="0"
            />
          )}
        />
        {errors.email?.type === 'required' && <span className={styles.message}>{t('error_required')}</span>}
        {errors.email?.type === 'pattern' && <span className={styles.message}>{t('error_email')}</span>}

        <Controller
          control={control}
          defaultValue={info.password || ''}
          name="password"
          rules={{ required: true, minLength: 4, maxLength: 8 }}
          render={({ field }) => (
            <PasswordInput field={field} placeholder={t('password')} />
          )}
        />
        {errors.password?.type === 'required' && <span className={styles.message}>{t('error_required')}</span>}
        {errors.password?.type === 'minLength' && <span className={styles.message}>{t('error_min', { num: 4 })}</span>}
        {errors.password?.type === 'maxLength' && <span className={styles.message}>{t('error_max', { num: 8 })}</span>}

        <Controller
          control={control}
          defaultValue=""
          name="confirm"
          rule={{ required: true }}
          render={({ field }) => (
            <PasswordInput field={field} placeholder={t('confirm_password')} />
          )}
        />
        {errors.confirm?.type === 'required' && <span className={styles.message}>{t('error_required')}</span>}

        <button type="submit" className={styles.submit}>
          <span>{t('sign_up')}</span>
        </button>
      </form>

      <article className={styles.sides}>
        <h4 className={styles.titleLeft}>{t('welcome_back')}</h4>
        <p>{t('welcome_info')}</p>
        <Link to="/login" className={styles.sign}>
          <span>{t('sign_in')}</span>
        </Link>
      </article>
    </section>
  );
};

Basic.propTypes = {
  move: propTypes.func.isRequired,
  set: propTypes.func.isRequired,
  info: propTypes.shape({
    email: propTypes.string.isRequired,
    password: propTypes.string.isRequired,
  }).isRequired,
};

export default Basic;
