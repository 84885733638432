/* eslint-disable react/no-children-prop */
import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import Login from './login';
import Principal from './principal';
import Signup from './signup';
import ChangePass from './changePass';

const Main = () => {
  const { path } = useRouteMatch();
  return (
    <main>
      <Switch>
        <Route exact path={path} component={Principal} />
        <Route path={`${path}login`} component={Login} />
        <Route path={`${path}signup`} component={Signup} />
        <Route path={`${path}changepass`} component={ChangePass} />
      </Switch>
      <Box height={[200, undefined, undefined, 300]} backgroundColor="ptYellow.500" />
    </main>
  );
};

export default Main;
