/* eslint-disable no-unused-vars */
import React, {
  useState, useRef, useReducer, useEffect,
} from 'react';
import {
  Button, Flex, useDisclosure, Heading,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { shape, number, func } from 'prop-types';

import { updateDay } from 'reducers/weekReducer.reducer';

import TableDisplay from 'components/routines/TableDisplay';
import useLang from 'hooks/useLang';
import templateCategoryReducer, {
  initialState, addRow, deleteRow, makeTemplate,
} from './templateCategoryReducer';
import CategoryOverlay from '../CategoryOverlay';
import SetOverlay from '../Features/SetOverlay';

const CreateTemplate = ({
  date, actual, data,
}) => {
  const [state, dispatch] = useReducer(templateCategoryReducer, initialState);
  const [sessionSaved, toggleSession] = useReducer((prev) => !prev, false);
  const [position, setPosition] = useState({ rowIdx: 0, columnIdx: 0 });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t, lang } = useLang();
  const sessionDispatch = useDispatch();
  const finalRef = useRef();

  const defaultValue = state?.data[position.rowIdx][position.columnIdx];

  const handleOpen = (rowIdx, columnIdx) => {
    setPosition({ rowIdx, columnIdx });
    onOpen();
  };
  const handleDelete = (idx) => {
    dispatch(deleteRow(idx));
    toggleSession();
  };
  const handleAdd = () => {
    dispatch(addRow());
    toggleSession();
  };
  const handleSave = () => sessionDispatch(updateDay(state.data, date.week, date.day, 'categoryList'));

  useEffect(() => {
    if (data) {
      console.log('data', data);
      dispatch(makeTemplate(data));
      toggleSession();
    }
  }, [data]);

  useEffect(() => {
    handleSave();
  }, [sessionSaved]);

  return (
    <>
      <div style={{ display: (actual.day === date.day && actual.week === date.week) ? 'block' : 'none' }}>
        <Flex justifyContent="space-around" mb="1rem">
          <Heading>
            {t('workout_title', { day: date.day, week: date.week })}
          </Heading>

          <Button onClick={handleAdd} colorScheme="ptRed">
            {t('add_row')}
          </Button>
        </Flex>
        <form>
          <TableDisplay
            columns={state.columns}
            data={state.data}
            handleOpen={handleOpen}
            handleDelete={handleDelete}
            position={position}
            ref={finalRef}
          />
        </form>
      </div>
      {
        (position.columnIdx !== 'categoryName' && isOpen) ? (
          <SetOverlay
            isOpen={isOpen}
            onClose={onClose}
            position={position}
            dispatch={dispatch}
            final={finalRef}
            save={toggleSession}
            defaultValue={defaultValue}
          />
        )
          : (
            <CategoryOverlay
              isOpen={isOpen}
              onClose={onClose}
              position={position}
              final={finalRef}
              dispatch={dispatch}
              save={toggleSession}
            />
          )
      }

    </>
  );
};

CreateTemplate.defaultProps = {
  data: undefined,
};

CreateTemplate.propTypes = {
  date: shape({
    week: number.isRequired,
    day: number.isRequired,
  }).isRequired,
  actual: shape({
    week: number.isRequired,
    day: number.isRequired,
  }).isRequired,
  data: shape({}),
};

export default CreateTemplate;
