import React, { useState } from 'react';
import propTypes from 'prop-types';
import {
  Input, InputRightElement, Button, InputGroup,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

import { passwordStyle } from 'theme/commonStyles';

const PasswordInput = ({ field, placeholder }) => {
  const [show, setShow] = useState(false);
  return (
    <InputGroup>
      <Input
        placeholder={placeholder}
        type={show ? 'text' : 'password'}
        {...passwordStyle}
        {...field}
      />
      <InputRightElement marginTop="0.5rem" width="3rem">
        <Button onClick={() => setShow(!show)} size="xs" colorScheme="ptRed" mx="0.2rem">
          {show ? <ViewOffIcon /> : <ViewIcon />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

PasswordInput.propTypes = {
  field: propTypes.shape({}).isRequired,
  placeholder: propTypes.string.isRequired,
};

export default PasswordInput;
