/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useGetAll from 'hooks/useGetAll';
import useDelete from 'hooks/useDelete';
import { getClientRoutines, deleteRoutine } from 'services/customWorkout';

import { infoStyle } from 'theme/commonStyles';

import DeleteAlert from 'components/common/DeleteAlert';
import RoutineDisplay from './RoutineDisplay';

const Routine = ({ idClient }) => {
  const [handleOpen, setHandleOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(undefined);
  const { data, isLoading, isError } = useGetAll('routines', idClient, getClientRoutines);
  const { t } = useTranslation();

  console.log(data);
  const handleDelete = (id) => {
    setHandleOpen(true);
    setIdToDelete(id);
  };

  const handleClose = () => setHandleOpen(false);
  if (isLoading) return <p style={infoStyle}>{t('loading_routine')}</p>;
  if (isError) return <p style={infoStyle}>{t('something_wrong')}</p>;
  if (!data.length) return <p style={infoStyle}>{t('no_routine')}</p>;

  return (
    <>
      {
        data
          .sort((A, B) => new Date(B.created_at) - new Date(A.created_at))
          .map((routine) => (
            <React.Fragment key={routine._id}>
              <RoutineDisplay handleDelete={handleDelete} routine={routine} />
              {
                handleOpen && (
                  <DeleteAlert
                    open={handleOpen}
                    close={handleClose}
                    id={idToDelete}
                    title={t('delete_routine')}
                    func={() => useDelete('routines', deleteRoutine)}
                  />
                )
              }
            </React.Fragment>
          ))
      }
    </>
  );
};

Routine.propTypes = {
  idClient: propTypes.string.isRequired,
};

export default Routine;
