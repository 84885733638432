import React from 'react';
import { Spinner } from '@chakra-ui/react';
import propTypes from 'prop-types';

const style = {
  border: '2px solid #b42519',
  borderRadius: 10,
  backgroundColor: '#252625',
  fontSize: 30,
  fontWeight: 700,
  minWidth: 300,
  padding: '1rem 1.5rem',
  color: '#ececec',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const DisplayMessage = ({ message, loading }) => (
  <div style={style}>
    <p>{message}</p>
    {loading && <Spinner size="xl" />}
  </div>
);

DisplayMessage.defaultProps = {
  loading: false,
};

DisplayMessage.propTypes = {
  message: propTypes.string.isRequired,
  loading: propTypes.bool,
};

export default DisplayMessage;
