/* eslint-disable no-unused-vars */
import dayjs from 'dayjs';

const getInstructions = (instructions) => instructions.map((instruction) => instruction.name);

const transformClientData = (data, trainerId) => ({
  name: data.name,
  email: data.email,
  phone: data.phone,
  birthdate: dayjs(data.birth_date).format('MM/DD/YYYY'),
  id_trainer: trainerId,
  gender: data.gender,
  speciality: data.speciality,
  address: {
    street1: data.street1,
    street2: data.street2 || '',
    city: data.city,
    state: data.state,
    zip: data.zip,
  },
});

const transformHelpData = (data, images, lang = 'en') => ({
  title: {
    [lang === 'en' ? 'es' : 'en']: '',
    [lang]: data.title,
  },
  description: {
    [lang === 'en' ? 'es' : 'en']: '',
    [lang]: data.description,
  },
  category: data.category,
  url: data.url,
  images,
});

const transformEditProfileData = (data, name, pic = '') => ({
  name,
  pic,
  speciality: data.speciality,
  phone: data.phone,
  job_description: data.job,
  address: {
    state: data.state,
    city: data.city,
    zip: data.zip,
    street1: data.street1,
    street2: data.street2 || '',
  },
});

const transformAddProfile = (data, id_user, pic = '') => ({
  pic,
  id_user,
  name: data.name,
  speciality: data.speciality,
  phone: data.phone,
  job_description: data.job,
  address: {
    state: data.state,
    city: data.city,
    zip: data.zip,
    street1: data.street1,
    street2: data.street2 || '',
  },
});

const transformToAddExercise = (data, lang = 'en', images, trainerId) => ({
  // name: {
  //   [lang === 'en' ? 'es' : 'en']: '',
  //   [lang]: data.name,
  // },
  name: {
    en: data.enName,
    es: data.esName,
  },
  instructions: {
    [lang === 'en' ? 'es' : 'en']: '',
    [lang]: getInstructions(data.instructions),
  },
  action: {
    [lang === 'en' ? 'es' : 'en']: '',
    [lang]: data.action,
  },
  muscle_system: {
    [lang === 'en' ? 'es' : 'en']: '',
    [lang]: data.muscle_system,
  },
  equipment: {
    [lang === 'en' ? 'es' : 'en']: '',
    [lang]: data.equipment,
  },
  abbreviations: {
    en: '',
    es: '',
  },
  category_id: data.category_id,
  trainerId,
  images,
});

const transformToEditExercise = (data, oldData, images, lang) => ({
  name: {
    en: data.enName,
    es: data.esName,
  },
  equipment: {
    ...oldData.equipment,
    [lang]: data.equipment,
  },
  muscle_system: {
    ...oldData.muscle_system,
    [lang]: data.muscle_system,
  },
  action: {
    ...oldData.action,
    [lang]: data.action,
  },
  abbreviations: oldData.abbreviations,
  instructions: {
    ...oldData.instructions,
    [lang]: getInstructions(data.instructions),
  },
  category_id: [...data.category_id],
  weakness: +data.weakness,
  images,
});

const transformData = (data, mode, lang = 'en', ...extraInfo) => {
  switch (mode) {
    case 'client': return ({
      name: data.name,
      email: data.email,
      phone: data.phone,
      birthdate: dayjs(data.birth_date).format('MM/DD/YYYY'),
      id_trainer: extraInfo,
      gender: data.gender,
      speciality: data.speciality,
      address: {
        street1: data.street1,
        street2: data.street2 || '',
        city: data.city,
        state: data.state,
        zip: data.zip,
      },
    });
    case 'help': return ({
      title: {
        [lang === 'en' ? 'es' : 'en']: '',
        [lang]: data.title,
      },
      description: {
        [lang === 'en' ? 'es' : 'en']: '',
        [lang]: data.description,
      },
      category: data.category,
      images: extraInfo[0],
      url: data.url,
    });
    case 'edit': return ({
      name: extraInfo[0],
      pic: extraInfo[1] || '',
      speciality: data.speciality,
      phone: data.phone,
      job_description: data.job,
      address: {
        state: data.state,
        city: data.city,
        zip: data.zip,
        street1: data.street1,
        street2: data.street2 || '',
      },
    });
    case 'signup': return ({
      name: data.name,
      pic: extraInfo[1] || '',
      speciality: data.speciality,
      phone: data.phone,
      id_user: extraInfo[0],
      job_description: data.job,
      address: {
        state: data.state,
        city: data.city,
        zip: data.zip,
        street1: data.street1,
        street2: data.street2 || '',
      },
    });
    case 'editExercise': return ({
      name: {
        ...extraInfo[0].name,
        [lang]: data.name,
      },
      equipment: {
        ...extraInfo[0].equipment,
        [lang]: data.equipment,
      },
      muscle_system: {
        ...extraInfo[0].muscle_system,
        [lang]: data.muscle_system,
      },
      action: {
        ...extraInfo[0].action,
        [lang]: data.action,
      },
      abbreviations: extraInfo[0].abbreviations,
      instructions: {
        ...extraInfo[0].instructions,
        [lang]: getInstructions(data.instructions),
      },
      category_id: [...data.category_id],
      images: extraInfo[1],
    });
    case 'addExercise': return ({
      name: {
        [lang === 'en' ? 'es' : 'en']: '',
        [lang]: data.name,
      },
      instructions: {
        [lang === 'en' ? 'es' : 'en']: '',
        [lang]: getInstructions(data.instructions),
      },
      action: {
        [lang === 'en' ? 'es' : 'en']: '',
        [lang]: data.action,
      },
      muscle_system: {
        [lang === 'en' ? 'es' : 'en']: '',
        [lang]: data.muscle_system,
      },
      equipment: {
        [lang === 'en' ? 'es' : 'en']: '',
        [lang]: data.equipment,
      },
      abbreviations: {
        en: '',
        es: '',
      },
      category_id: data.category_id,
      images: extraInfo[0],
    });
    default: return data;
  }
};

export default transformData;

export {
  transformToAddExercise,
  transformClientData,
  transformHelpData,
  transformEditProfileData,
  transformAddProfile,
  transformToEditExercise,
};
