import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Article from './Article';
import styles from './Info.module.scss';
import Item from './Item';

// hardcoded data for testing
const blogs = [
  {
    title: 'The Basic of functional Training',
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur soluta debitis commodi fugit molestiae, eius dicta repellat quaerat tempore quisquam ipsam ratione sunt veritatis nobis earum alias! Aspernatur, praesentium excepturi.',
    author: 'JC Santaca',
    date: 'June 12, 1996',
  },
  {
    title: 'The Basic of Stability Ball training',
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur soluta debitis commodi fugit molestiae, eius dicta repellat quaerat tempore quisquam ipsam ratione sunt veritatis nobis earum alias! Aspernatur, praesentium excepturi.',
    author: 'G Sammons',
    date: 'August 12, 2001',
  },
];

// hardcoded data for testing
const events = [
  {
    title: 'Introducing to boxing training - FREE',
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur soluta debitis commodi fugit molestiae, eius dicta repellat quaerat tempore quisquam ipsam ratione sunt veritatis nobis earum alias! Aspernatur, praesentium excepturi.',
    author: 'Instructor Joe Santana',
    date: '',
  },
  {
    title: 'Introducing to MMA Training',
    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur soluta debitis commodi fugit molestiae, eius dicta repellat quaerat tempore quisquam ipsam ratione sunt veritatis nobis earum alias! Aspernatur, praesentium excepturi.',
    author: 'Instructor JC Santana and Rio Santana',
    date: '',
  },
];
const info = {
  title: '',
  text: 'The institute of Human Performance 1950 NW Boca Raton Boulevard Boca Raton, FL 33432',
};

const Info = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.root}>
      <Article title={t('recent_blogs')}>
        {blogs.map((blog) => (
          <Item {...blog} />
        ))}
      </Article>
      <Article title="AUGUST 12, 2001">
        {events.map((event) => (
          <Item {...event} />
        ))}
      </Article>
      <Article title={t('contact_info')}>
        <Item {...info}>
          <p className={styles.text}>
            <span>Tel:</span>
            {' '}
            (561) 620-9556
          </p>
          <p className={styles.text}>
            <span>Email:</span>
            {' '}
            info@perfecttrainer.com
          </p>
          <Flex
            align="center"
            color="#b8b8b8"
            marginTop="1rem"
            fontSize="25px"
          >
            <i className="fab fa-facebook-f" />
            <i className="fab fa-instagram" style={{ marginLeft: '0.5rem' }} />
            <i className="fab fa-youtube" style={{ marginLeft: '0.5rem' }} />
            <i className="fab fa-twitter" style={{ marginLeft: '0.5rem' }} />
          </Flex>
        </Item>
      </Article>
    </section>
  );
};

export default Info;
