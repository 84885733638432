/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useState, useReducer, useEffect } from 'react';
import {
  Button, ButtonGroup, Input, IconButton, Heading, Grid,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { addWeek, addDay, resetWeeks } from 'reducers/weekReducer.reducer';
import { loaded, loading } from 'reducers/loader.reducer';
import { createTemplates, updateTemplates } from 'services/template';
import { createPreprogrammed, updatePreprogrammed } from 'services/preProgrammed';
import notificationDisplay from 'utils/notificationDisplay';
import { createWeekDayStructure, returnPredefinePreprogrammed, returnPredefineTemplateProgram } from 'utils/workout.utils';
import { isEmpty, isProgramEmpty } from 'utils/checkEmptyness';
import { getSession } from 'utils/programCreation';

import { bigContainerStyle } from 'theme/commonStyles';
import ReturnBtn from 'components/common/ReturnBtn';
import DateList from 'components/common/DateList';

import CancelBtn from 'components/common/CancelBtn';
import CreateTemplate from './createTemplate';
import CreatePreprogrammed from './createPreprogrammed';

import Type from './Type';
import ProgramName from './Features/ProgramName';

// const getTemplateModel = (data) => {
//   const finalData = [];

//   data.forEach((week) => {
//     week.days.forEach((day) => {
//       finalData.push({
//         week: week.number,
//         day: day.number,
//         categoryList: day.categoryList.map((item) => ({
//           // category: `${item.category.split('*')[1]}-${item.category.split('-')[1]}`,
//           category: item.category.split('*')[1],
//           sub: item.category.split('-')[1].split('*')[0],
//           sets: item.sets,
//           reps: item.reps,
//           load: 0,
//           typeWeight: 'kg',
//         })),
//       });
//     });
//   });

//   return finalData;
// };

// const getPreprogrammedModel = (data) => {
//   const finalData = [];

//   data.forEach((week) => {
//     week.days.forEach((day) => {
//       finalData.push({
//         week: week.number,
//         day: day.number,
//         exercises: day.exercises
//           .map(({ sets, reps, exercise }) => setBasicvalues(exercise, 'id_exercise', +sets, +reps)),
//       });
//     });
//   });

//   return finalData;
// };

const Create = () => {
  const { location } = useHistory();
  const [mode, setMode] = useState(location.state ? location.state.mode : 'main');
  const [preSelected, setPreSelected] = useState([]);
  const [name, setName] = useState({ en: '', es: '' });
  // const [enName, setEnName] = useState('');
  // const [esName, setEsName] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [actualWeek, setActualWeek] = useState(1);
  const [actualDay, setActualDay] = useState(1);

  const weekData = useSelector(({ weekList }) => weekList);

  const handleAddWeek = () => {
    dispatch(addWeek());
    setActualWeek(actualWeek + 1);
    setActualDay(1);
    console.log('week');
  };

  const handleSelectWeek = (week, days) => {
    setActualWeek(week);
    setActualDay(1);
  };

  // days func
  const handleAddDay = () => {
    dispatch(addDay(actualWeek));
    setActualDay(actualDay + 1);
  };

  const handleSelectDay = (day) => {
    setActualDay(day);
  };

  const handleSaveEdit = async () => {
    dispatch(loading());
    const dataToSend = {
      name,
      sessions: getSession(weekData, mode),
    };

    console.log('weekData', weekData);
    console.log('dataToSend', dataToSend);

    let response = '';
    try {
      if (mode === 'template') response = await updateTemplates(dataToSend, location.state.data._id);

      if (mode === 'preprogrammed') {
        dataToSend.workout_name = name;
        dataToSend.preProgrammed = true;
        response = await updatePreprogrammed(dataToSend, location.state.data._id);
      }
      if (response.data) throw new Error(t('error_save'));

      notificationDisplay(dispatch, t('data_updated'), 'success');
      history.push('/user/programs');
    } catch (error) {
      notificationDisplay(dispatch, error.message, 'error');
    }
    dispatch(loaded());
  };

  const handleSave = async () => {
    if (!name.en) return notificationDisplay(dispatch, t('en_name_required'), 'error');
    if (mode === 'preprogrammed' && !name.es) return notificationDisplay(dispatch, t('es_name_required'), 'error');
    // dispatch(loading());
    const dataToSend = {
      name,
      sessions: getSession(weekData, mode),
    };

    console.log('dataToSend', dataToSend);
    return; // testing delete afterwards
    let response = '';
    try {
      if (mode === 'template') response = await createTemplates(dataToSend);

      if (mode === 'preprogrammed') {
        dataToSend.workout_name = name;
        dataToSend.preProgrammed = true;
        response = await createPreprogrammed(dataToSend);
      }
      if (response.data) throw new Error(t('error_save'));

      notificationDisplay(dispatch, t('data_creation'), 'success');
      history.push('/user/programs');
    } catch (error) {
      notificationDisplay(dispatch, error.message, 'error');
    }
    dispatch(loaded());
  };
  const handleCancel = () => history.push('/user/programs');

  useEffect(() => {
    if (mode === 'main') {
      setActualDay(1);
      setActualWeek(1);
      dispatch(resetWeeks());
      setName({ en: '', es: '' });
    }
    if (location.state) dispatch(resetWeeks());
  }, [mode]);

  useEffect(() => {
    if (location.state) {
      const prop = mode === 'template' ? 'name' : 'workout_name';
      setMode(location.state.mode);
      console.log('locatation state', location.state.data);
      createWeekDayStructure(location.state.data, dispatch);
      console.log(location.state.data[prop]);

      setName({
        en: location.state.data[prop].en,
        es: location.state.data[prop].es,
      });

      const finalData = mode === 'template'
        ? returnPredefineTemplateProgram(location.state.data)
        : returnPredefinePreprogrammed(location.state.data);
      setPreSelected(finalData);
      console.log('preSelected', preSelected);
    }
  }, []);

  return (
    <main style={bigContainerStyle}>
      {
        mode === 'main' && <Type setMode={setMode} />
      }
      {
        mode !== 'main' && (
          <>
            {
              !location.state
                ? <ReturnBtn setStep={setMode} initialNumber="main" />
                : <CancelBtn cancel={handleCancel} />
            }
            <ProgramName
              enName={name.en}
              esName={name.es}
              setName={setName}
            />
            <DateList
              list={weekData}
              add={handleAddWeek}
              select={handleSelectWeek}
              type={t('list_week')}
              actual={{
                day: actualDay,
                week: actualWeek,
              }}
            />
            <DateList
              list={weekData[actualWeek - 1].days}
              add={handleAddDay}
              select={handleSelectDay}
              type={t('list_day')}
              actual={{
                day: actualDay,
                week: actualWeek,
              }}
            />
            {
              mode === 'template' && weekData.map((week, weekIdx) => (
                <>
                  {
                    week.days.map((day, dayIdx) => (
                      <CreateTemplate
                        actual={{ day: actualDay, week: actualWeek }}
                        date={{ day: day.number, week: week.number }}
                        data={preSelected[weekIdx]?.days[dayIdx]}
                      />
                    ))
                  }
                </>
              ))

            }
            {
              mode === 'preprogrammed' && weekData.map((week, weekIdx) => (
                <>
                  {
                    week.days.map((day, dayIdx) => (
                      <CreatePreprogrammed
                        actual={{ day: actualDay, week: actualWeek }}
                        date={{ day: day.number, week: week.number }}
                        data={preSelected[weekIdx]?.days[dayIdx]}

                      />
                    ))
                  }
                </>
              ))
            }
            {
              (!location.state && isEmpty(weekData)) && (
                <Button onClick={handleSave} colorScheme="ptRed" mt="1rem">
                  {t('save_program')}
                </Button>
              )
            }
            {
              (location.state && isEmpty(weekData)) && (
                <Button onClick={handleSaveEdit} colorScheme="ptRed" mt="1rem">
                  {t('save_edit')}
                </Button>
              )
            }
          </>
        )
      }
    </main>
  );
};

export default Create;
