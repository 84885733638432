import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useLang = () => {
  const { t } = useTranslation();
  const language = localStorage.getItem('i18nextLng');
  const [lang, setLang] = useState(language);

  useEffect(() => {
    setLang(language);
  }, [t]);

  return { lang, t };
};

export default useLang;
