/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ReturnBtn from 'components/common/ReturnBtn';
import SearchClient from 'components/common/SearchClient';
import Type from './Type';
import Workout from './workout';
import Report from './Report';

const Selector = () => {
  const { location } = useHistory();
  const [step, setStep] = useState(location.state ? location.state.step : 1);
  const [workType, setWorkType] = useState(location.state ? location.state.workType : '');
  const [client, setClient] = useState(location.state?.client ? location.state.client : '');
  const [report, setReport] = useState({});
  const [editRoutine, setEditRoutine] = useState(!!location.state?.edit);

  return (
    <>
      {
        step !== 1 && <ReturnBtn setStep={setStep} />
      }
      {step === 1 && <Type setValue={setWorkType} setStep={setStep} />}
      {step === 2 && <SearchClient setClient={setClient} setStep={setStep} />}
      {step === 3 && (
        <Workout
          client={client}
          workout={workType}
          setReport={setReport}
          setStep={setStep}
          sessionClone={location.state?.routine}
          edit={editRoutine}
        />
      )}
      {step === 4 && <Report report={report} />}
    </>
  );
};

export default Selector;
