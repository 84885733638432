const types = {
  uiOpenModal: '[ui] Open modal',
  uiCloseModal: '[ui] Close modal',

  eventSetActive: '[event] Set Active',
  eventLogout: '[event] Logout event',

  eventStartAddNew: '[event] Start Add New',
  eventAddNew: '[event] Add New',
  eventClearActiveEvent: '[event] Clear Active event',
  eventSetSlotEmpty: '[event] eventSetSlotEmpty',
  eventUpdated: '[event] Event updated',
  eventDeleted: '[event] Event deleted',
  eventLoaded: '[event] Event loaded',
};
export default types;
