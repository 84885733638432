/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Heading, IconButton, useDisclosure } from '@chakra-ui/react';
import { ViewIcon, DeleteIcon, CalendarIcon } from '@chakra-ui/icons';

import useDelete from 'hooks/useDelete';
import useGetAll from 'hooks/useGetAll';
import { deleteClient, getTrainerClients } from 'services/clients';
import localizationMaterialTable from 'helpers/localizationMaterialTable';
import customFilterAndSearch from 'helpers/customFilterAndSearch';

import DisplayMessage from 'components/common/DisplayMessage';
import MaterialTable from 'material-table';
import tableIcons from 'theme/tableIcons';
import DeleteAlert from 'components/common/DeleteAlert';
import Popup from './Popup';

import styles from './Board.module.scss';

const ClientBoard = () => {
  const [mode, setMode] = useState('info');
  const [clientInfo, setClientInfo] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const { id_user } = useSelector(({ user }) => user);
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { t } = useTranslation();
  const { data, isLoading, isError } = useGetAll('clients', id_user, getTrainerClients);

  const handleClose = () => setDialogOpen(false);
  const handleOpen = (client) => {
    setClientInfo(client);
    setDialogOpen(true);
  };
  const onOpenPop = (client, name) => {
    setMode(name);
    setClientInfo(client);
    onOpen();
  };
  const switchMode = (client, type) => {
    setMode(type);
    setClientInfo(client);
  };

  const localization = localizationMaterialTable();

  if (isLoading) return <DisplayMessage message={t('loading_clients')} loading />;
  if (isError) return <DisplayMessage message={t('something_wrong')} />;

  const columns = [
    {
      title: t('fullname'),
      headerStyle: { fontSize: '16px', fontWeight: '600' },
      field: 'name',
      customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'name'),
      render: (rowData) => <h6 style={{ fontSize: '14px' }}>{rowData.name}</h6> },
    {
      title: t('table_options'),
      cellStyle: { width: '40%' },
      headerStyle: { fontSize: '16px', fontWeight: '600' },
      render: (rowData) => (
        <>
          <IconButton
            fontSize="20px"
            icon={<ViewIcon />}
            onClick={() => onOpenPop(rowData, 'info')}
            variant="link"
          />
          <IconButton
            fontSize="20px"
            icon={<DeleteIcon />}
            onClick={() => handleOpen(rowData)}
            variant="link"
          />
          <IconButton
            fontSize="20px"
            icon={<CalendarIcon />}
            onClick={() => onOpenPop(rowData, 'routine')}
            variant="link"
          />
        </>
      ),
    },
  ];

  return (
    <>
      <section className={styles.root}>
        <Heading>{t('clients')}</Heading>
        <MaterialTable
          style={{ width: '100%' }}
          columns={columns}
          data={data}
          icons={tableIcons}
          title=""
          localization={localization}
        />

      </section>
      {
        isOpen && (
          <Popup
            isOpen={isOpen}
            onClose={onClose}
            other={clientInfo}
            mode={mode}
            title={clientInfo.name}
            switchMode={switchMode}
          />
        )
      }

      {
        dialogOpen && (
          <DeleteAlert
            open={dialogOpen}
            close={handleClose}
            id={clientInfo._id}
            title={t('delete_client')}
            func={() => useDelete('clients', deleteClient)}
          />
        )
      }
    </>
  );
};

export default ClientBoard;
