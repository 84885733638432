import React from 'react';
import {
  AlertDialog, AlertDialogBody, AlertDialogFooter,
  AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DeleteAlert = ({ open, close, id }) => {
  const { t } = useTranslation();
  return (
    <AlertDialog isOpen={open} onClose={close}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{t('delete_client')}</AlertDialogHeader>
          <AlertDialogBody>
            {t('delete_client_confirmation')}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={close}>
              {t('cancel')}
            </Button>
            <Button colorScheme="red" onClick={() => console.log('deleting', id)} ml={3}>
              {t('delete')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

DeleteAlert.propTypes = {
  open: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  id: propTypes.string.isRequired,
};

export default DeleteAlert;
