import React from 'react';
import propTypes from 'prop-types';

const CalendarEvent = ({ event }) => {
  const { title } = event;
  return (
    <div>
      <strong>{title}</strong>
    </div>
  );
};

CalendarEvent.propTypes = {
  event: propTypes.func.isRequired,
};

export default CalendarEvent;
