import React from 'react';
import IconsNav from './IconsNav';
import LogoNav from './LogoNav';

// eslint-disable-next-line no-unused-vars
const stikness = {
  position: 'sticky',
  top: 0,
  zIndex: '101',
};

const Header = () => (
  <header>
    <IconsNav />
    <LogoNav />
  </header>
);

export default Header;
