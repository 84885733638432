import React from 'react';

import {
  Button, ButtonGroup,
  AlertDialog, AlertDialogBody, AlertDialogFooter,
  AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, AlertDialogCloseButton,
} from '@chakra-ui/react';

const Alert = ({
  // eslint-disable-next-line react/prop-types
  onClose, isOpen, t, setProgram,
}) => (
  <AlertDialog onClose={onClose} isOpen={isOpen}>
    <AlertDialogOverlay />
    <AlertDialogContent>
      <AlertDialogHeader>
        {t('create_program')}
      </AlertDialogHeader>
      <AlertDialogCloseButton />
      <AlertDialogBody>
        {t('program_selection_info')}
      </AlertDialogBody>
      <AlertDialogFooter display="flex" justifyContent="space-around">
        <ButtonGroup colorScheme="ptRed">
          <Button onClick={() => setProgram('functional')}>{t('functional_program')}</Button>
          <Button onClick={() => setProgram('hybrid')}>{t('hybrid_program')}</Button>
        </ButtonGroup>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
);

export default Alert;
