import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_PROD}/trainer/`,
});

const getTrainer = async (id) => {
  try {
    const token = window.localStorage.getItem('userAuth');
    const response = await api.get(`/${id}`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const getTrainerByUserId = async (idUser) => {
  try {
    const token = window.localStorage.getItem('userAuth');
    const response = await api.get(`/user/${idUser}`, { headers: { authorization: token } });
    return response.data.trainer;
  } catch (error) {
    return error.response;
  }
};

const createTrainer = async (data) => {
  try {
    const response = await api.post('/', data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
const updateTrainer = async (id, data) => {
  try {
    const token = window.localStorage.getItem('userAuth');
    const response = await api.put(`/${id}`, data, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const getAllTrainers = async () => {
  try {
    const token = localStorage.getItem('userAuth');
    const response = await api.get('/', { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const deleteTrainer = async (idUser) => {
  try {
    const token = window.localStorage.getItem('userAuth');
    const response = await api.delete(`/${idUser}`, { headers: { authorization: token } });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export {
  createTrainer,
  deleteTrainer,
  getTrainer,
  getTrainerByUserId,
  updateTrainer,
  getAllTrainers,
};
