import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, useDisclosure } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import Popup from './Popup';
import styles from './Search.module.scss';

const ClientSearch = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.root}>
        <Button
          className={styles.btn}
          colorScheme="ptRed"
          onClick={() => onOpen()}
          rightIcon={<AddIcon />}
        >
          {t('new_client')}
        </Button>
      </div>
      <Popup onClose={onClose} isOpen={isOpen} title={t('add_client')} mode="add" />
    </>
  );
};
export default ClientSearch;
