import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, useDisclosure } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import propTypes from 'prop-types';

import Popup from './Popup';
import styles from './Search.module.scss';

const Search = ({ category }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.root}>
        <Button
          className={styles.btn}
          colorScheme="ptRed"
          onClick={onOpen}
          rightIcon={<AddIcon />}
        >
          {t('add_word')}
        </Button>
      </div>
      <Popup onClose={onClose} isOpen={isOpen} category={category} title={t('add_word')} mode="add" />
    </>
  );
};
Search.propTypes = {
  category: propTypes.string.isRequired,
};
export default Search;
