import React from 'react';
import CalendarScreen from 'components/calendar/CalendarScreen';

const Week = () => (
  <>
    <CalendarScreen category="week" />
  </>
);

export default Week;
