import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_PROD}/customWorkout/`,
});

const createRoutine = async (data) => {
  try {
    const token = window.localStorage.getItem('userAuth');
    const response = await api.post('/', data, { headers: { authorization: token } });
    return response.data;
  } catch (exception) {
    return exception.response;
  }
};

const updateRoutine = async (id, data) => {
  try {
    const token = window.localStorage.getItem('userAuth');
    const response = await api.put(`/${id}`, data, { headers: { authorization: token } });
    return response.data;
  } catch (exception) {
    return exception.response;
  }
};

const updateCompletedDay = async (sessionId) => {
  try {
    const token = window.localStorage.getItem('userAuth');
    const response = await api.put(`completed/${sessionId}`, { headers: { authorization: token } });
    return response.data;
  } catch (exception) {
    console.log(exception.response);
    throw new Error(exception.response);
  }
};

const getRoutine = async (id) => {
  try {
    const token = window.localStorage.getItem('userAuth');
    const response = await api.get(`/${id}`, { headers: { authorization: token } });
    return response.data.customWorkout;
  } catch (exception) {
    return exception.response;
  }
};

const getClientRoutines = async (id) => {
  try {
    const token = window.localStorage.getItem('userAuth');
    const response = await api.get(`/client/${id}`, { headers: { authorization: token } });
    return response.data.customWorkoutsByClientId;
  } catch (exception) {
    return exception.response;
  }
};

const deleteRoutine = async (id) => {
  try {
    const token = window.localStorage.getItem('userAuth');
    const response = await api.delete(`/${id}`, { headers: { authorization: token } });
    return response.data;
  } catch (exception) {
    return exception.response;
  }
};

export {
  createRoutine,
  updateRoutine,
  getRoutine,
  getClientRoutines,
  deleteRoutine,
  updateCompletedDay,
};
