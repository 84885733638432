/* eslint-disable consistent-return */
/* eslint-disable no-alert */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons';
import { Redirect } from 'react-router-dom';
import { login } from 'reducers/trainer.reducer';

import useGetAll from 'hooks/useGetAll';
import { getTrainerByUserId } from 'services/trainer';
import notificationDisplay from 'utils/notificationDisplay';

import Info from './Info';
import Edit from './Edit';
import styles from './Profile.module.scss';

const Profile = () => {
  const [edit, setEdit] = useState(false);
  const { id_user } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const {
    data, isError, isLoading, isSuccess,
  } = useGetAll('', id_user, getTrainerByUserId);
  if (data) dispatch(login(data));
  if (isSuccess && data === undefined) {
    const config = {
      pathname: '/signup',
      state: {
        id_user,
        step: 2,
      },
    };
    notificationDisplay(dispatch, 'Please complete your trainer data', 'error');
    return <Redirect to={config} />;
  }

  return (
    <main className={styles.root}>
      <section className={styles.container}>
        <div className={styles.options}>
          <IconButton icon={edit ? <CloseIcon /> : <EditIcon />} aria-label="edit" onClick={() => setEdit(!edit)} />
        </div>
        {
            isLoading && <p style={{ padding: '2rem 0' }}>Loading Profile Data</p>
          }
        {
            isError && <p style={{ padding: '2rem 0' }}>Something went wrong</p>
          }
        {
          (isSuccess && edit) && <Edit setEdit={setEdit} />
          }
        {
          (isSuccess && !edit) && <Info data={data} />
          }
      </section>
    </main>
  );
};

export default Profile;
