const notificationReducer = (state = '', action) => {
  const { type, message, status } = action;
  switch (type) {
    case 'NOTIFICATION':
      return { message, status };
    case 'RESET':
      return '';
    default:
      return state;
  }
};

const errorMessage = (data) => ({ type: 'NOTIFICATION', message: data, status: 'error' });
const successMessage = (data) => ({ type: 'NOTIFICATION', message: data, status: 'success' });
const resetMessage = () => ({ type: 'RESET' });

export default notificationReducer;

export { errorMessage, resetMessage, successMessage };
