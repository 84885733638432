/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-lone-blocks */
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AddIcon, ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Input,
  IconButton,
  Select,
} from '@chakra-ui/react';
import propTypes from 'prop-types';
import { transformAddProfile } from 'utils/transformData';
import styles from './Data.module.scss';
import CustomInput from '../CustomInput';
import notificationDisplay from '../../../utils/notificationDisplay';
import { createTrainer } from '../../../services/trainer';
import { loaded, loading } from '../../../reducers/loader.reducer';
// import transformData from '../../../utils/transformData';
import useUploadFileS3 from '../../../hooks/useUploadFileS3';
import TextInput from '../../common/TextInput';

const Data = ({
  move, info, set,
}) => {
  const user_data = localStorage.getItem('user_data');
  console.log({ user_data });
  if (user_data) {
    // eslint-disable-next-line no-param-reassign
    info.basic.id_user = JSON.parse(user_data).id_user;
  }
  const [secondCity, setSecondCity] = useState(false);
  const {
    handleSubmit, control, register, formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const uploadFileS3 = useUploadFileS3();

  const onPrev = () => move((prev) => prev - 1);
  const onSubmit = async (data) => {
    dispatch(loading());
    try {
      let response = '';
      if (data.avatar[0]) {
        response = await uploadFileS3.mutateAsync(data.avatar[0], 'imagen-de-prueba');
      }
      console.log('trainerData', transformAddProfile(data, 'signup', undefined, info.basic.id_user, response.location));
      // const dataResponse = await createTrainer(transformData(data, 'signup', undefined, info.basic.id_user, response.location));
      const dataResponse = await createTrainer(transformAddProfile(data, info.basic.id_user, response.location));

      if (dataResponse.data) throw new Error(dataResponse.data.error.message || dataResponse.data);
      set(dataResponse);
      move((prev) => prev + 1);
    } catch (exception) {
      notificationDisplay(dispatch, exception.message, 'error');
    }
    dispatch(loaded());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
      <fieldset className={styles.avatarContainer}>
        <div className={styles.avatarWrapper}>
          <img className={`${styles.profilePic} pic`} src="" alt="" />
          <div className={styles.uploadButton} onClick={() => document.querySelector('.file').click()} role="button" tabIndex={0}>
            <i className={`fa fa-arrow-circle-up ${styles.arrow}`} aria-hidden="true" />
          </div>
          <input
            accept="image/*"
            className={`${styles.fileUpload} file`}
            type="file"
            {...register('avatar')}
          />
        </div>
      </fieldset>
      <fieldset className={styles.sides}>
        <TextInput
          control={control}
          def={info.name || ''}
          name="name"
          place={t('fullname')}
          rules={{ maxLength: 50, pattern: /^[a-zA-Z]+(([ -][a-zA-Z ])?[a-zA-Z]*)*$/g }}
        />
        {errors.name?.type === 'maxLength' && <span className={styles.message}>{t('error_max', { num: 50 })}</span>}
        {errors.name?.type === 'pattern' && <span className={styles.message}>{t('error_character_word')}</span>}

        <Controller
          control={control}
          defaultValue={info.age || '15'}
          name="age"
          render={({ field }) => (
            <CustomInput label={t('age')} required>
              <NumberInput
                backgroundColor="#ececec"
                focusBorderColor="ptRed.500"
                max={70}
                min={12}
                mb="1rem"
                {...field}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </CustomInput>
          )}
        />

        <Controller
          control={control}
          defaultValue={info.gender || ''}
          name="gender"
          render={({ field }) => (
            <CustomInput label={t('gender')} required>
              <Select placeholder={t('select_gender')} {...field} backgroundColor="#ececec" mb="1rem" focusBorderColor="ptRed.500">
                <option value="male">{t('opt_male')}</option>
                <option value="female">{t('opt_female')}</option>
              </Select>
            </CustomInput>
          )}
        />

        <TextInput
          control={control}
          def={info.phone || ''}
          name="phone"
          place={t('phonenumber')}
          rules={{ maxLength: 20, pattern: /[0-9]+/ }}
        />
        {errors.phone?.type === 'maxLength' && <span className={styles.message}>{t('error_max', { num: 20 })}</span>}
        {errors.phone?.type === 'pattern' && <span className={styles.message}>{t('error_character_number')}</span>}

        <TextInput
          control={control}
          def={info.speciality || ''}
          name="speciality"
          place={t('profile_speciality')}
        />
      </fieldset>

      <fieldset className={styles.sides}>
        <Controller
          control={control}
          defaultValue={info.street1 || ''}
          name="street1"
          rules={{ maxLength: 300 }}
          render={({ field }) => (
            <CustomInput label={t('principal_address')} required>
              <div role="group" className={styles.addressBtn}>
                <Input
                  backgroundColor="#ececec"
                  focusBorderColor="ptRed.500"
                  id="street1"
                  mb="1rem"
                  placeholder={t('principal_address')}
                  {...field}
                />
                <IconButton icon={<AddIcon />} onClick={() => setSecondCity(!secondCity)} />
              </div>
              {errors.street1?.type === 'maxLength' && <span className={styles.message}>{t('error_max', { num: 300 })}</span>}
            </CustomInput>
          )}
        />
        {secondCity && (
          <TextInput
            control={control}
            def={info.street2 || ''}
            name="street2"
            place={t('secondary_address')}
          />
        )}

        <TextInput
          control={control}
          def={info.city || ''}
          name="city"
          place={t('city')}
          rules={{ maxLength: 100 }}
        />
        {errors.city?.type === 'maxLength' && <span className={styles.message}>{t('error_max', { num: 100 })}</span>}

        <TextInput
          control={control}
          def={info.state || ''}
          name="state"
          place={t('state')}
          rules={{ maxLength: 100 }}
        />
        {errors.state?.type === 'maxLength' && <span className={styles.message}>{t('error_max', { num: 100 })}</span>}

        <TextInput
          control={control}
          def={info.zip || ''}
          name="zip"
          place={t('zip')}
          rules={{ pattern: /[0-9]+/ }}
        />
        {errors.zip?.type === 'pattern' && <span className={styles.message}>{t('error_character_number')}</span>}
      </fieldset>
      <TextInput
        control={control}
        def={info.job || ''}
        name="job"
        place={t('job_description')}
        type="textarea"
        required={false}
      />
      <ButtonGroup className={styles.btnGroup} isAttached>
        <Button
          className={styles.btn}
          colorScheme="ptRed"
          leftIcon={<ArrowBackIcon />}
          onClick={onPrev}
        >
          {t('prev')}
        </Button>
        <Button
          className={styles.btn}
          colorScheme="ptRed"
          rightIcon={<ArrowForwardIcon />}
          type="submit"
        >
          {t('continue')}
        </Button>
      </ButtonGroup>
    </form>
  );
};

Data.propTypes = {
  move: propTypes.func.isRequired,
  isCanceled: propTypes.bool.isRequired,
  set: propTypes.func.isRequired,
  info: propTypes.shape({
    age: propTypes.string.isRequired,
    city: propTypes.string.isRequired,
    date: propTypes.string.isRequired,
    gender: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    job: propTypes.string.isRequired,
    phone: propTypes.string.isRequired,
    street1: propTypes.string.isRequired,
    street2: propTypes.string.isRequired,
    state: propTypes.string.isRequired,
    zip: propTypes.string.isRequired,
    speciality: propTypes.string.isRequired,
    basic: propTypes.shape({
      id_user: propTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Data;
