import React from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalCloseButton, ModalBody, Badge, Box, ModalFooter, Button,
} from '@chakra-ui/react';
import propTypes from 'prop-types';

const Popup = ({
  onClose, isOpen, title, item, mode, lang, handleClickEdit,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent background="#252625" color="#ededed" maxWidth="40rem">
      <ModalHeader>
        {title}
      </ModalHeader>
      <ModalCloseButton style={{ color: '#ececec' }} />
      {/* <ModalBody display="flex" flexWrap="wrap" justifyContent="space-around"> */}
      <ModalBody display="grid" gridTemplateColumns="1fr 1fr" gridAutoRows>
        {
          item.sessions.map(({
            week, day, categoryList, exercises,
          }) => (
            <Box p="0.5rem">
              <Badge>{`W${week} - Day ${day}`}</Badge>
              <ol>
                {
                  mode === 'template' && categoryList.map(({ category, sub }) => (
                    <li>
                      {category.name}
                      -
                      {sub || 'All'}
                    </li>
                  ))
                }
                {
                  mode === 'preprogrammed' && exercises.map((exercise) => (
                    <li>{exercise.id_exercise.name[lang]}</li>
                  ))
                }
              </ol>
            </Box>
          ))
        }
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="ptRed" onClick={() => handleClickEdit(mode, item)}>
          edit
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

Popup.defaultProps = {
  lang: '',
};

Popup.propTypes = {
  handleClickEdit: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  title: propTypes.string.isRequired,
  mode: propTypes.string.isRequired,
  item: propTypes.shape({
    workout_name: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    sessions: propTypes.shape([]),
  }).isRequired,
  lang: propTypes.string,
};

export default Popup;
