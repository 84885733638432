import React from 'react';
import { bigContainerStyle } from 'theme/commonStyles';
import Container from './Container';

const Evaluation = () => (
  <main style={bigContainerStyle}>
    <Container />
  </main>
);

export default Evaluation;
