/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import {
  Button, Heading, VStack, Text,
} from '@chakra-ui/react';
import propTypes from 'prop-types';

import {
  generateReport,
  generateBasicReport,
  generateMediumReport,
  generateFullReport,
} from 'utils/pdfGenerators';

const Report = ({ report }) => {
  const lang = localStorage.getItem('i18nextLng');
  const handleClick = ({ target }) => {
    console.log('report', report);
    switch (target.id) {
      case 'basic': return generateBasicReport(report, lang);
      case 'medium': return generateMediumReport(report, lang);
      case 'complete': return generateFullReport(report, lang);
      case 'report': return generateReport(report, lang);
      default: return '';
    }
  };

  return (
    <div>
      <VStack>
        <Heading textAlign="center" mb="1rem">{report.workout_name[lang]}</Heading>
        <Text>Select the PDF to download</Text>
        <Button
          onClick={handleClick}
          id="basic"
          colorScheme="ptRed"
          padding="1.5rem 0"
          w={[200, undefined, 300]}
        >
          Level 1
        </Button>

        <Button
          onClick={handleClick}
          id="report"
          colorScheme="ptRed"
          padding="1.5rem 0"
          w={[200, undefined, 300]}
        >
          Level 2
        </Button>

        {/* <Button
          onClick={handleClick}
          id="medium"
          colorScheme="ptRed"
          padding="1.5rem 0"
          w={[200, undefined, 300]}
        >
          Medium Report
        </Button>
        <Button
          onClick={handleClick}
          id="complete"
          colorScheme="ptRed"
          padding="1.5rem 0"
          w={[200, undefined, 300]}
        >
          Complete Report
        </Button> */}
      </VStack>
    </div>
  );
};

Report.propTypes = {
  report: propTypes.shape({
    workout_name: propTypes.string.isRequired,
  }).isRequired,
};

export default Report;
