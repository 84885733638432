/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
/* import propTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomInput from '../CustomInput';
import notificationDisplay from '../../../utils/notificationDisplay';
import { loaded, loading } from '../../../reducers/loader.reducer'; */
import { Button, ButtonGroup } from '@chakra-ui/react';
import styles from './Membership.module.scss';
import { createCheckoutSession, createPortalSession } from '../../../services/membership';

const ProductDisplay = ({ itemTag, setItemTag, move }) => {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { priceId, sessionId } = event.target;

    if (event.target.priceId.value === 'free') move(4);

    if (priceId !== undefined && sessionId === undefined) {
      const checkOutUrl = await createCheckoutSession(priceId.value);
      window.location.replace(checkOutUrl);
    }
  };
  return (
    <section>
      <div className={styles.h1Container}>
        <h1>Selecciona el plan que necesitas</h1>
      </div>
      <div className={styles.root}>
        <div className={styles.rootItemBox}>
          <div className={styles.itemBox}>
            <div className="description">
              <h3>Starter plan</h3>
              <h5>Free</h5>
              <p style={{ marginTop: '1rem' }}>
                <ul>
                  <li>
                    <span>Caraterísticas</span>
                  </li>
                  <li>
                    <span>Caraterísticas</span>
                  </li>
                  <li>
                    <span>Caraterísticas</span>
                  </li>
                </ul>
              </p>
              <Button onClick={() => setItemTag('free')}>Select</Button>
            </div>
          </div>
        </div>
        <div className={styles.rootItemBox}>
          <div className={styles.itemBox}>
            <div className="description">
              <h3>Monthly plan</h3>
              <h5>$4.99 / month</h5>
              <p style={{ marginTop: '1rem' }}>
                <ul>
                  <li>
                    <span>Caraterísticas</span>
                  </li>
                  <li>
                    <span>Caraterísticas</span>
                  </li>
                  <li>
                    <span>Caraterísticas</span>
                  </li>
                </ul>
              </p>
              <Button onClick={() => setItemTag('price_1KDyi1KmGJILZIAROPzYWkmp')}>Select</Button>
            </div>
          </div>
        </div>
        <div className={styles.rootItemBox}>
          <div className={styles.itemBox}>
            <div className="description">
              <h3>Yearly plan</h3>
              <h5>$49.99 / Year</h5>
              <p style={{ marginTop: '1rem' }}>
                <ul>
                  <li>
                    <span>Caraterísticas</span>
                  </li>
                  <li>
                    <span>Caraterísticas</span>
                  </li>
                  <li>
                    <span>Caraterísticas</span>
                  </li>
                </ul>
              </p>
              <Button onClick={() => setItemTag('price_1KDykJKmGJILZIARf8pVMjFa')}>Select</Button>
            </div>
          </div>
        </div>
      </div>
      <form className={styles.form} onSubmit={handleSubmit}>
        {/* Add a hidden field with the lookup_key or priceId of your Price */}
        <input type="hidden" name="priceId" value={itemTag} />
        <ButtonGroup className={styles.btnGroup}>
          <Button className={styles.btnCheckOut} id="checkout-and-portal-button" type="submit">
            Checkout
          </Button>
          <Button className={styles.btn} onClick={() => move(4)}>
            Try for free
          </Button>
        </ButtonGroup>
      </form>
    </section>
  );
};

const SuccessDisplay = async ({ sessionId }) => {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { sessionIdInput } = event.target;

    if (sessionId !== undefined) {
      try {
        const portalUrl = await createPortalSession(sessionIdInput.value);
        window.location.replace(portalUrl);
      } catch (err) {
        alert(err);
      }
    }
  };

  return (
    <section>
      <div className="product Box-root">
        <Logo />
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <input type="hidden" id="session-id" name="sessionId" value={sessionId} />
        <ButtonGroup className={styles.btnGroup}>
          <Button className={styles.btn} id="checkout-and-portal-button" type="submit">
            Manage your billing information
          </Button>
        </ButtonGroup>
      </form>
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function Membership({ move }) {
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [itemTag, setItemTag] = useState('');

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

  if (!success && message === '') {
    return (
      <>
        <ProductDisplay itemTag={itemTag} setItemTag={setItemTag} move={move} />
      </>
    );
  } if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  }
  return <Message message={message} />;
}

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14px" height="16px" viewBox="0 0 14 16" version="1.1">
    <defs />
    <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="0-Default" transform="translate(-121.000000, -40.000000)" fill="#E184DF">
        <path
          d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
          id="Pilcrow"
        />
      </g>
    </g>
  </svg>
);
