import React from 'react';
import {
  AlertDialog, AlertDialogBody, AlertDialogFooter,
  AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';

import { infoStyle } from 'theme/commonStyles';

const DeleteAlert = ({
  open, close, id, title, func,
}) => {
  const { t } = useTranslation();
  const {
    mutate, isLoading, isSuccess, isError,
  } = func();

  const onDelete = () => mutate(id);

  if (isSuccess) close();
  return (
    <AlertDialog isOpen={open} onClose={close}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          {
            isLoading && <AlertDialogBody style={infoStyle}>{t('deleting')}</AlertDialogBody>
          }
          {
            isError && <AlertDialogBody style={infoStyle}>{t('something_wrong')}</AlertDialogBody>
          }
          {
            isSuccess && <AlertDialogBody style={infoStyle}>{t('succesful_delete')}</AlertDialogBody>
          }
          {(!isLoading && !isSuccess) && (
          <>
            <AlertDialogBody>
              {t('delete_confirmation')}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={close}>
                {t('cancel')}
              </Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                {t('delete')}
              </Button>
            </AlertDialogFooter>
          </>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

DeleteAlert.propTypes = {
  open: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  id: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  func: propTypes.func.isRequired,
};

export default DeleteAlert;
