import React, { useState } from 'react';
import { Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';

import Carousel from 'components/common/Carousel';

const tagStyle = {
  color: '#d9ad42',
  fontWeight: 700,
  marginRight: '0.5rem',
};

const Info = ({ exercise }) => {
  const [carouselStep, setCarouselStep] = useState(0);
  const lang = window.localStorage.getItem('i18nextLng');
  const { t } = useTranslation();

  const handleCarouselClick = (movement) => {
    setCarouselStep((prev) => (movement === 'prev' ? prev - 1 : prev + 1));
  };
  // eslint-disable-next-line react/prop-types
  console.log(exercise.name[lang], '-', exercise._id, '-', exercise.category_id[0].name);

  return (
    <>
      <Carousel step={carouselStep} images={exercise.images} click={handleCarouselClick} />
      <p style={{ marginTop: '0.5rem' }}>
        <span style={tagStyle}>{t('categories')}</span>
        {
        exercise.category_id.map(({ _id, name }) => <Badge colorScheme="yellow" mr="0.5rem" key={_id}>{name}</Badge>)
      }
      </p>
      <p>
        <span style={tagStyle}>{t('abbrevations')}</span>
        {
          typeof exercise.abbreviations[lang] !== 'undefined' && exercise.abbreviations[lang]
            .map((abbr) => <Badge colorScheme="gray" mr="0.5rem" key={abbr}>{abbr}</Badge>)
          }
      </p>
      <p>
        <span style={tagStyle}>{t('muscle_system')}</span>
        {
        exercise.muscle_system[lang].map((muscle) => <Badge colorScheme="gray" mr="0.5rem" key={muscle}>{muscle}</Badge>)
      }
      </p>
      <p>
        <span style={tagStyle}>{t('equipment')}</span>
        {
        exercise.equipment[lang].map((eq) => <Badge colorScheme="gray" key={eq}>{eq}</Badge>)
      }
      </p>
      <p style={tagStyle}>{t('instructions')}</p>
      <ol style={{ fontSize: '0.8rem', padding: '0.5rem 1rem 0.5rem 2rem' }}>
        {
        exercise.instructions[lang]
          .filter((instruction) => instruction !== '')
          .map((instruction) => <li key={instruction}>{instruction}</li>)
      }
      </ol>
    </>
  );
};
Info.propTypes = {
  exercise: propTypes.shape({
    abbreviations: propTypes.shape([]).isRequired,
    category_id: propTypes.shape([].isRequired),
    muscle_system: propTypes.shape([].isRequired),
    equipment: propTypes.shape([].isRequired),
    instructions: propTypes.shape([].isRequired),
    images: propTypes.shape([]).isRequired,
  }).isRequired,
};

export default Info;
