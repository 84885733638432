const first = [
  {
    name: 'front-1',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The front view of the single-leg anterior reach showed: your right knee translated inward demonstrating a weakness in your right glute, right hamstrings and right para-spinals.',
      es: 'La vista frontal del alcance anterior a una pierna (derecha) mostró: su rodilla derecha trasladó hacia adentro, demostrando una debilidad en su glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo derecho', 'Isquiotibiales derechos', 'Paraespinales derechos'],
    },
  },
  {
    name: 'front-2',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The front view of the single-leg anterior reach showed: your right knee translated outward demonstrating a weakness in your right glute, right hamstrings and right para-spinals.',
      es: 'La vista frontal del alcance anterior a pierna (Derecha) mostró: su rodilla derecha trasladó hacia afuera, demostrando una debilidad en su glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo derecho', 'Isquiotibiales derechos', 'Paraespinales derechos'],
    },
  },
  {
    name: 'side-1',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The side view of the single leg anterior reach showed: your right knee translated forward demonstrating a weakness in your right calf,  right glute, right hamstrings, and right para-spinals.',
      es: 'La vista lateral del Alcance Anterior a una Pierna (derecha) mostró: su rodilla derecha se movió hacia adelante demostrando una debilidad en su pantorrilla derecha, glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right calf', 'right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Pantorrilla derecha', 'Glúteo derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'side-2',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The side view of the single leg anterior reach showed:  your posterior chain rounded excessively demonstrating a weakness in your right glute, right hamstrings, and right para-spinals.',
      es: 'La vista lateral del Alcance Anterior a una Pierna (derecha) mostró: el core y la cadena posterior se redondeó excesivamente, demostrando una debilidad en su glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right calf', 'right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Pantorrilla derecha', 'Glúteo derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'side-3',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The side view of the single leg anterior reach showed: your right  heel lifted off the ground demonstrating a weakness in your right calf,  right glute, right hamstrings, and right para-spinals.',
      es: 'La vista lateral del  Alcance Anterior a una Pierna (derecha) mostró: El talón derecho se levantó del suelo, lo que demuestra una debilidad en la pantorrilla derecha, el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right calf', 'right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Pantorrilla derecha', 'Glúteo derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'back-1',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The back view of the single leg anterior reach showed: your right hip shifted to the right or your left hip elevated demonstrating a weakness in your right glute, right hamstrings and right para-spinals.',
      es: 'La vista posterior del Alcance Anterior a una Pierna (derecha) mostró: la cadera derecha se desplazó hacia la derecha o la cadera izquierda se elevó, lo que demuestra una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'back-2',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The back view of the single leg anterior reach showed: your right hip shifted to the right or your left hip dropped demonstrating a weakness in your right glute, right hamstrings and right para-spinals.',
      es: 'La vista posterior del Alcance Anterior a una Pierna (derecha) mostró:  la cadera derecha se desplazó hacia la derecha o la cadera izquierda descendió, lo que demuestra una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'back-3',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The back view of the single leg anterior reach showed: your left foot moved to the left demonstrating a weakness in your  right glute, right hamstrings and right para-spinals.',
      es: 'La vista posterior del Alcance Anterior a una Pierna (derecha) mostró: su pie izquierdo se movió hacia la izquierda demostrando una debilidad en su glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'back-4',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The back view of the single leg anterior reach showed: your left foot moved to the right demonstrating a weakness in your right glute, right hamstrings and right para-spinals.',
      es: 'La vista posterior del Alcance Anterior a una Pierna (derecha) mostró: su pie izquierdo se movió hacia la derecha demostrando una debilidad en su glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
];

const second = [
  {
    name: 'front-1',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The front view of the left-leg, single-leg anterior reach showed: your left knee translated inward demonstrating a weakness in your left glute, left hamstrings and left para-spinals.',
      es: 'La vista frontal del Alcance Anterior a una Pierna (Izquierda) mostró: su rodilla izquierda se trasladó hacia adentro, demostrando una debilidad en su glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['Left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo derecho', 'Isquiotibiales derecho', 'Paraespinales derechos'],
    },
  },
  {
    name: 'front-2',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The front view of the left-leg, single-leg anterior reach showed: your left knee translated outward demonstrating a weakness in your left glute, left hamstrings and left para-spinals.',
      es: 'La vista frontal del Alcance Anterior a una Pierna (Izquierda) mostró: su rodilla izquierda se trasladó hacia afuera, demostrando una debilidad en su glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['Left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo derecho', 'Isquiotibiales derecho', 'Paraespinales derechos'],
    },
  },
  {
    name: 'side-1',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The side view of the single leg anterior reach showed: your left knee translated forward demonstrating a weakness in your left calf,  left glute, left hamstrings and right para-spinals.',
      es: 'La vista lateral del Alcance Anterior a una Pierna (Izquierda) mostró: su rodilla izquierda se movió hacia adelante, demostrando una debilidad en su pantorrilla izquierda, glúteo izquierdo isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left calf', 'left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Pantorrilla Izquierda', 'Glúteo Izquierdo', 'Isquiotibiales Izquierdos', 'Paraespinales Izquierdos'],
    },
  },
  {
    name: 'side-2',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The side view of the single leg anterior reach showed:  your posterior chain rounded excessively demonstrating a weakness in your right glute, right hamstrings, and right para-spinals.',
      es: 'La vista lateral del  Alcance Anterior a una Pierna (Izquierda) mostró: el core y la cadena posterior se redondeó excesivamente, demostrando una debilidad en su glúteo izquierdo, isquiotibiales izquierdo y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left calf', 'left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Pantorrilla Izquierda', 'Glúteo Izquierdo', 'Isquiotibiales Izquierdos', 'Paraespinales Izquierdos'],
    },
  },
  {
    name: 'side-3',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The side view of the single leg anterior reach showed: your  left heel lifted off the ground demonstrating a weakness in your left calf,  left glute, left hamstrings, and left para-spinals.',
      es: 'La vista lateral del Alcance Anterior a una Pierna (Izquierda) mostró: su talón izquierdo se levantó del suelo, lo que demuestra una debilidad en la pantorrilla izquierda, glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left calf', 'left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Pantorrilla Izquierda', 'Glúteo Izquierdo', 'Isquiotibiales Izquierdos', 'Paraespinales Izquierdos'],
    },
  },
  {
    name: 'back-1',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The back view of the single leg anterior reach showed: your left hip shifted to the left or your right hip elevated demonstrating a weakness in your left glute, left hamstrings and left para-spinals.',
      es: 'La vista posterior del Alcance Anterior a una Pierna (Izquierda) mostró: la cadera izquierda se desplazó hacia la izquierda o la cadera derecha se elevó, lo que demuestra una debilidad en el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo Izquierdo', 'Isquiotibiales Izquierdos', 'Paraespinales Izquierdos'],
    },
  },
  {
    name: 'back-2',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The back view of the single leg anterior reach showed: your left hip shifted to the left or your right hip dropped demonstrating a weakness in your left glute, left hamstrings and left para-spinals.',
      es: 'La vista posterior del Alcance Anterior a una Pierna (Izquierda) mostró: la cadera izquierda se desplazó hacia la izquierda o la cadera derecha descendió, lo que demuestra una debilidad en el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo Izquierdo', 'Isquiotibiales Izquierdos', 'Paraespinales Izquierdos'],
    },
  },
  {
    name: 'back-3',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The back view of the single leg anterior reach showed: your right foot moved to the right demonstrating a weakness in your left glute, left hamstrings and left para-spinals.',
      es: 'La vista posterior del Alcance Anterior a una Pierna (Izquierda) mostró: su pie derecho se movió hacia la derecha demostrando una debilidad en su glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo Izquierdo', 'Isquiotibiales Izquierdos', 'Paraespinales Izquierdos'],
    },
  },
  {
    name: 'back-4',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The back view of the single leg anterior reach showed: your right foot moved to the left demonstrating a weakness in your left glute, left hamstrings and left para-spinals.',
      es: 'La vista posterior del Alcance Anterior a una Pierna (Izquierda) mostró: su pie derecho se movió hacia la izquierda demostrando una debilidad en su glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo Izquierdo', 'Isquiotibiales Izquierdos', 'Paraespinales Izquierdos'],
    },
  },
];

const third = [
  {
    name: 'front-1',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The front view of the right-leg, single-leg squat showed: your right knee translated outward demonstrating a weakness in your right glute, right hamstrings, and right para-spinals.',
      es: 'La vista frontal de la Sentadilla a una Pierna sobre la pierna derecha mostró: su rodilla derecha trasladó hacia afuera, demostrando una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo derecho', 'Isquiotibiales derechos', 'Paraespinales derechos'],
    },
  },
  {
    name: 'front-2',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The front view of the right-leg, single-leg squat showed: your right knee translated inward demonstrating a weakness in your right glute, right hamstrings, and right para-spinals.',
      es: 'La vista frontal de la Sentadilla a una Pierna sobre la pierna derecha mostró: la rodilla derecha se trasladó hacia adentro, demostrando una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo derecho', 'Isquiotibiales derechos', 'Paraespinales derechos'],
    },
  },
  {
    name: 'side-1',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The side view of the single leg squat showed: your right knee translated forward demonstrating a weakness in your right calf,  right glute, right hamstrings, and right para-spinals.',
      es: 'La vista lateral de la Sentadilla a una Pierna sobre la pierna derecha mostró: la rodilla derecha se movió hacia adelante, demostrando una debilidad en la pantorrilla derecha, glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right calf', 'right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Pantorrilla derecha', 'Glúteo derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'side-2',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The side view of the single leg squat showed: your posterior chain rounded excessively demonstrating a weakness in your right glute, right hamstrings, and right para-spinals.',
      es: 'La vista lateral de la Sentadilla a una Pierna sobre la pierna derecha mostró:  el core y la cadena posterior se redondeó excesivamente, demostrando una debilidad en la pantorrilla derecha, el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right calf', 'right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Pantorrilla derecha', 'Glúteo derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'side-3',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The side view of the single leg squat showed: your  right heel lifted off the ground demonstrating a weakness in your right calf,  right glute, right hamstrings and right para-spinals.',
      es: 'La vista lateral de la Sentadilla a una Pierna sobre la pierna derecha mostró: el talón derecho se levantó del suelo, lo que demuestra una debilidad en la pantorrilla derecha, el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right calf', 'right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Pantorrilla derecha', 'Glúteo derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'back-1',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The back view of the single leg anterior reach showed: your right hip shifted to the right or your left hip elevated demonstrating a weakness in your right glutes, right hamstrings and right para-spinals.',
      es: 'La vista posterior de la Sentadilla a una Pierna sobre la pierna derecha mostró: la cadera derecha se desplazó hacia la derecha o la cadera izquierda se elevó, lo que demuestra una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'back-2',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The back view of the single leg anterior reach showed: your right hip shifted to the right or your left hip elevated demonstrating a weakness in your  right glutes, right hamstrings and right para-spinals.',
      es: 'La vista posterior de la Sentadilla a una Pierna sobre la pierna derecha mostró:  la cadera derecha se desplazó hacia la derecha o la cadera izquierda descendió, lo que demuestra una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'back-3',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The back view of the single leg anterior reach showed: your left foot moved to the left demonstrating a weakness in your  right glutes, right hamstrings and right para-spinals.',
      es: 'La vista posterior de la Sentadilla a una Pierna sobre la pierna derecha mostró: su pie izquierdo se movió hacia la izquierda demostrando una debilidad en su glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'back-4',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The back view of the single leg anterior reach showed: your left foot moved to the right demonstrating a weakness in your right glutes, right hamstrings and right para-spinals.',
      es: 'La vista posterior de la Sentadilla a una Pierna sobre la pierna derecha mostró: su pie izquierdo se movió hacia la derecha demostrando una debilidad en su glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
];
const fourth = [
  {
    name: 'front-1',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The front view of the left-leg, single-leg squat showed: your left knee translated inward demonstrating a weakness in your left glute, left hamstrings, and left para-spinals.',
      es: 'La vista frontal de la Sentadilla a una Pierna sobre la pierna izquierda mostró: su rodilla izquierda se trasladó hacia adentro, demostrando una debilidad en su glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'front-2',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The front view of the left-leg, single-leg squat showed: your left knee translated outward demonstrating a weakness in your left glute, left hamstrings, and left  para-spinals.',
      es: 'La vista frontal de la Sentadilla a una Pierna sobre la pierna izquierda mostró: su rodilla izquierda se trasladó hacia afuera, demostrando una debilidad en su glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'side-1',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The side view of the single leg squat showed: your left knee translated forward demonstrating a weakness in your left calf,  left glute, left hamstrings, and left para-spinals.',
      es: 'La vista lateral de la Sentadilla a una Pierna sobre la pierna izquierda mostró: su rodilla izquierda se movió hacia adelante, demostrando una debilidad en su pantorrilla izquierda, glúteo izquierdo isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left calf', 'left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Pantorrilla izquierda', 'Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'side-2',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The side view of the single leg squat showed:  your posterior chain rounded excessively demonstrating a weakness in your left glute, left hamstrings, and left para-spinals.',
      es: 'La vista lateral de la Sentadilla a una Pierna sobre la pierna izquierda mostró: el core y la cadena posterior se redondeó excesivamente, demostrando una debilidad en la pantorrilla izquierda, el glúteo izquierdo, isquiotibiales izquierdo y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left calf', 'left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Pantorrilla izquierda', 'Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'side-3',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The side view of the single leg squat showed: your  left heel lifted off the ground demonstrating a weakness in your left calf,  left glute, left hamstrings, and left para-spinals.',
      es: 'La vista lateral de la Sentadilla a una Pierna sobre la pierna izquierda mostró: su talón izquierdo se levantó del suelo, lo que demuestra una debilidad en la pantorrilla izquierda, glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left calf', 'left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Pantorrilla izquierda', 'Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'back-1',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The back view of the single leg squat showed: your left hip shifted to the left or your right hip elevated demonstrating a weakness in your  left glutes, left hamstrings and left para-spinals.',
      es: 'La vista posterior de la Sentadilla a una Pierna sobre la pierna izquierda mostró: su cadera izquierda se desplazó hacia la izquierda o su cadera derecha se elevó, lo que demuestra una debilidad en el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'back-2',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The back view of the single leg squat showed: your left hip shifted to the left or your right hip dropped demonstrating a weakness in your  left glutes, left hamstrings and left para-spinals.',
      es: 'La vista posterior de la Sentadilla a una Pierna sobre la pierna izquierda mostró: su cadera izquierda se desplazó hacia la izquierda o su cadera derecha descendió, lo que demuestra una debilidad en el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'back-3',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The back view of the single leg squat showed: your right foot moved to the right demonstrating a weakness in your  left glutes, left hamstrings and left para-spinals.',
      es: 'La vista posterior de la Sentadilla a una Pierna sobre la pierna izquierda mostró: su pie derecho se movió hacia la derecha demostrando una debilidad en su glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'back-4',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The back view of the single leg squat showed: your right foot moved to the left demonstrating a weakness in your  left glutes, left hamstrings and left para-spinals.',
      es: 'La vista posterior de la Sentadilla a una Pierna sobre la pierna izquierda mostró: su pie derecho se movió hacia la izquierda demostrando una debilidad en su glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
];

const fifth = [
  {
    name: 'front-1',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the 2-Leg Squat showed: your right knee goes out, demonstrating a weakness in your right glute, right hamstrings and right para-spinals.',
      es: 'La vista frontal de la Sentadilla a 2 Piernas mostró: La rodilla derecha sale (Rodillas derecha en Varo), demostrando una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['glutes', 'hamstrings', 'para-spinals'],
      es: ['Glúteos', 'Isquiotibiales', 'Paraespinales'],
    },
  },
  {
    name: 'front-2',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the 2-Leg Squat showed: your right knee goes in, demonstrating a weakness in your right glute, right hamstrings and right para-spinals.',
      es: 'La vista frontal de la Sentadilla a 2 Piernas mostró: La rodilla derecha entra (Rodillas derecha en Valgo), demostrando una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['glutes', 'hamstrings', 'para-spinals'],
      es: ['Glúteos', 'Isquiotibiales', 'Paraespinales'],
    },
  },
  {
    name: 'front-3',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the 2-Leg Squat showed:  your left knee goes in, demonstrating a weakness in your left glute, left hamstrings and left para-spinals.',
      es: ' La vista frontal de la Sentadilla a 2 Piernas mostró: La rodilla izquierda entra (Rodillas izquierda en Valgo), demostrando una debilidad en el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['glutes', 'hamstrings', 'para-spinals'],
      es: ['Glúteos', 'Isquiotibiales', 'Paraespinales'],
    },
  },
  {
    name: 'front-4',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the 2-Leg Squat showed: your left knee goes out, demonstrating a weakness in your left glute, left hamstrings and left  para-spinals.',
      es: ' La vista frontal de la Sentadilla a 2 Piernas mostró: La rodilla izquierda sale (Rodillas izquierda en Varo), demostrando una debilidad en el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['glutes', 'hamstrings', 'para-spinals'],
      es: ['Glúteos', 'Isquiotibiales', 'Paraespinales'],
    },
  },
  {
    name: 'side-1',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The side view of the 2 Leg Squat showed: your knees go forward, demonstrating a weakness in your calves, glutes, hamstrings and para-spinals.',
      es: 'La vista lateral de la Sentadilla a 2 Piernas mostró: Las rodillas se adelantan, demostrando una debilidad en las pantorrillas, glúteos, isquiotibiales y paraespinales.',
    },
    default_muscle: {
      en: ['calves', 'glutes', 'hamstrings', 'para-spinals'],
      es: ['Pantorrillas', 'Glúteos', 'Isquiotibiales', 'Paraespinales'],
    },
  },
  {
    name: 'side-2',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The side view of the 2 Leg Squat showed: your lower back and hips rounded, demonstrating a weakness in your glutes, hamstrings and para-spinals.',
      es: 'La vista lateral de la Sentadilla a 2 Piernas mostró: la espalda baja y caderas se redondean, demostrando una debilidad en los glúteos, isquiotibiales y paraespinales.',
    },
    default_muscle: {
      en: ['calves', 'glutes', 'hamstrings', 'para-spinals'],
      es: ['Pantorrillas', 'Glúteos', 'Isquiotibiales', 'Paraespinales'],
    },
  },
  {
    name: 'side-3',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The side view of the 2 Leg Squat showed: your heels go up, demonstrating a weakness in your calves,  glutes, hamstrings, and para-spinals.',
      es: 'La vista lateral de la Sentadilla a 2 Piernas mostró: Los talones suben, demostrando una debilidad en las pantorrillas, glúteos, isquiotibiales y paraespinales.',
    },
    default_muscle: {
      en: ['calves', 'glutes', 'hamstrings', 'para-spinals'],
      es: ['Pantorrillas', 'Glúteos', 'Isquiotibiales', 'Paraespinales'],
    },
  },
  {
    name: 'back-1',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The back view of the 2-Leg Squat showed: your hips move to the left demonstrating an asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat.',
      es: 'La vista posterior de la Sentadilla a 2 Pierna mostró: las caderas se desplazan hacia la izquierda demostrando una debilidad asimétrica del core posterior confirmada en el Alcance Anterior a 1 Pierna y la Sentadilla a 1 Pierna.',
    },
    default_muscle: {
      en: ['Asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat'],
      es: ['Debilidad asimétrica del Core posterior confirmada en el Alcance Anterior a 1 Pierna y Sentadilla a 1Pierna'],
    },
  },
  {
    name: 'back-2',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The back view of the 2-Leg Squat showed: your right hip drops and/or your left hip goes up demonstrating an asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat.',
      es: 'La vista posterior de la Sentadilla a 2 Pierna mostró: la cadera derecha desciende y/o la cadera izquierda sube demostrando una debilidad asimétrica del core posterior confirmada en el Alcance Anterior a 1 Pierna y la Sentadilla a 1 Pierna',
    },
    default_muscle: {
      en: ['Asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat'],
      es: ['Debilidad asimétrica del Core posterior confirmada en el Alcance Anterior a 1 Pierna y Sentadilla a 1Pierna'],
    },
  },
  {
    name: 'back-3',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The back view of the 2-Leg Squat showed: your left hip drops and your right hip goes up demonstrating an asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat.',
      es: 'La vista posterior de la Sentadilla a 2 Pierna mostró: la cadera izquierda desciende y/o la cadera derecha sube demostrando una debilidad asimétrica del core posterior confirmada en el Alcance Anterior a 1 Pierna y la Sentadilla a 1 Pierna.',
    },
    default_muscle: {
      en: ['Asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat'],
      es: ['Debilidad asimétrica del Core posterior confirmada en el Alcance Anterior a 1 Pierna y Sentadilla a 1Pierna'],
    },
  },
  {
    name: 'back-4',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The back view of the 2-Leg Squat showed: your left hip drops and your right hip goes up demonstrating an asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat.',
      es: 'La vista posterior de la Sentadilla a 2 Pierna mostró: la cadera izquierda desciende y/o la cadera derecha sube demostrando una debilidad asimétrica del core posterior confirmada en el Alcance Anterior a 1 Pierna y la Sentadilla a 1 Pierna.',
    },
    default_muscle: {
      en: ['Asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat'],
      es: ['Debilidad asimétrica del Core posterior confirmada en el Alcance Anterior a 1 Pierna y Sentadilla a 1Pierna'],
    },
  },
  {
    name: 'back-5',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The back view of the 2-Leg Squat showed: your hips move to the right demonstrating an asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat',
      es: 'La vista posterior de la Sentadilla a 2 Pierna mostró: las caderas se desplazan hacia la derecha demostrando una debilidad asimétrica del core posterior confirmada en el Alcance Anterior a 1 Pierna y la Sentadilla a 1 Pierna.',
    },
    default_muscle: {
      en: ['Asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat'],
      es: ['Debilidad asimétrica del Core posterior confirmada en el Alcance Anterior a 1 Pierna y Sentadilla a 1Pierna'],
    },
  },
  {
    name: 'back-6',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The back view of the 2-Leg Squat showed: your right hip drops and/or your left hip goes up demonstrating an asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat.',
      es: 'La vista posterior de la Sentadilla a 2 Pierna mostró: la cadera derecha desciende y/o la cadera izquierda sube demostrando una debilidad asimétrica del core posterior confirmada en el Alcance Anterior a 1 Pierna y la Sentadilla a 1 Pierna.',
    },
    default_muscle: {
      en: ['Asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat'],
      es: ['Debilidad asimétrica del Core posterior confirmada en el Alcance Anterior a 1 Pierna y Sentadilla a 1Pierna'],
    },
  },
];

const sixth = [
  {
    name: 'front-1',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The front view of the right-leg lunge showed: your right knee translated outward demonstrating weakness in your right glute, right hamstrings, and right para-spinals.',
      es: 'La vista frontal de la Estocada con la pierna derecha mostró: la rodilla derecha se trasladó hacia afuera (rodilla derecha en varo), demostrando una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'front-2',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The front view of the right-leg lunge showed: your right knee translated inward demonstrating weakness in your right glute, right hamstrings, and right para-spinals.',
      es: 'La vista frontal de la Estocada con la pierna derecha mostró: la rodilla derecha se trasladó hacia adentro (rodilla derecha en valgo), demostrando una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'side-1',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The side view of the right-leg lunge showed: your right knee goes forward demonstrating a weakness in your right calf,  right glute, right hamstrings and right para-spinals.',
      es: 'La vista lateral de la Estocada con la pierna derecha mostró: la rodilla derecha se trasladó hacia adelante, lo que demuestra una debilidad en la pantorrilla derecha, el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right calf', 'right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Pantorrilla derecha', 'Glúteo derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'side-2',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The side view of the right-leg lunge showed: your left shoulder moves in front of the left hip, demonstrating a weaksness in  your left hip flexors  and anterior core.',
      es: 'La vista lateral de la Estocada con la pierna derecha mostró: El hombro izquierdo se ubicó por delante de la cadera izquierda,  lo que demuestra una debilidad en los flexores de la cadera izquierda y el core anterior.',
    },
    default_muscle: {
      en: ['right calf', 'right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Pantorrilla derecha', 'Glúteo derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'side-3',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The side view of the right-leg lunge showed: your right right heel goes up demonstrating a weakness in your right calf,  right glute, right hamstrings and right para-spinals.',
      es: 'La vista lateral de la Estocada con la pierna derecha mostró: el talón derecho se levantó del suelo, lo que demuestra una debilidad en la pantorrilla derecha, el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right calf', 'right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Pantorrilla derecha', 'Glúteo derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'side-4',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The side view of the right-leg lunge showed: your left knee moves infront of left hip demonstrating a weaksness in  your left hip flexors  and anterior core.',
      es: 'La vista lateral de la Estocada con la pierna derecha mostró: La rodilla izquierda se ubicó por delante de la cadera izquierda, lo que demuestra una debilidad en los flexores de la cadera izquierda y el core anterior.',
    },
    default_muscle: {
      en: ['right calf', 'right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Pantorrilla derecha', 'Glúteo derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'side-5',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The side view of the right-leg lunge showed: your left hip flexes and moves behind the left shoulder or knee demonstrating a weaksness in  your left hip flexors  and anterior core.',
      es: 'La vista lateral de la Estocada con la pierna derecha mostró: La cadera izquierda se flexionó y se ubicó detrás del hombro izquierdo o la rodilla izquierda, lo que demuestra una debilidad en los flexores de la cadera izquierda y el core anterior.',
    },
    default_muscle: {
      en: ['right calf', 'right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Pantorrilla derecha', 'Glúteo derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'back-1',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The back view of the right-leg lunge showed: your left heel turns left demonstrating weakness in your right glutes, right hamstrings, and right para-spinals.',
      es: 'La vista posterior de la Estocada con la pierna derecha mostró: El talón izquierdo se inclinó a la izquierda, lo que demuestra una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'back-2',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The back view of the right-leg lunge showed: your left hip goes up demonstrating weakness in your right glute, right hamstrings, and right para-spinals.',
      es: 'La vista posterior de la Estocada con la pierna derecha mostró: La cadera izquierda subió, lo que demuestra una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'back-3',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The back view of the right-leg lunge showed: your left hip goes down demonstrating weakness in your right glute, right hamstrings, and right para-spinals.',
      es: 'La vista posterior de la Estocada con la pierna derecha mostró: La cadera izquierda descendió, lo que demuestra una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
  {
    name: 'back-4',
    checked: false,
    side: 'right',
    defaultMsg: {
      en: 'The back view of the right-leg lunge showed: your left heel turns right demonstrating weakness in your right glute, right hamstrings, and right para-spinals.',
      es: 'La vista posterior de la Estocada con la pierna derecha mostró: El talón izquierdo se inclinó a la derecha, lo que demuestra una debilidad en el glúteo derecho, isquiotibiales derechos y paraespinales derechos.',
    },
    default_muscle: {
      en: ['right glute', 'right hamstrings', 'right para-spinals'],
      es: ['Glúteo Derecho', 'Isquiotibiales Derechos', 'Paraespinales Derechos'],
    },
  },
];

const seventh = [
  {
    name: 'front-1',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The front view of the left-leg lunge showed: your left knee translated inward demonstrating weakness in your left glute, left hamstrings, and left para-spinals.',
      es: 'La vista frontal de la Estocada con la pierna izquierda mostró: la rodilla izquierda se trasladó hacia adentro (rodilla izquierda en valgo), demostrando una debilidad en el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'front-2',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The front view of the left-leg lunge showed: your left knee translated outward demonstrating weakness in your left glute, left hamstrings, and left  para-spinals.',
      es: 'La vista frontal de la Estocada con la pierna izquierda mostró: la rodilla izquierda se trasladó hacia afuera (rodilla izquierda en varo), demostrando una debilidad en el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'side-1',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The side view of the left-leg lunge showed: your right hip flexes and moves behind the right shoulder or right knee demonstrating weakness in your right hip flexors  and anterior core.',
      es: 'La vista lateral de la Estocada con la pierna izquierda mostró: La cadera derecha se flexionó y se ubicó detrás del hombro derecho o la rodilla derecha, lo que demuestra una debilidad en los flexores de la cadera derecha y el core anterior.',
    },
    default_muscle: {
      en: ['left calf', 'left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Pantorrilla izquierda', 'Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'side-2',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The side view of the left-leg lunge showed: your right shoulder moves in front or right hip demonstrating weakness in your right hip flexors  and anterior core.',
      es: 'La vista lateral de la Estocada con la pierna izquierda mostró: El hombro derecho se ubicó por delante de la cadera derecha, lo que demuestra una debilidad en los flexores de la cadera derecha y el core anterior.',
    },
    default_muscle: {
      en: ['left calf', 'left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Pantorrilla izquierda', 'Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'side-3',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The side view of the left-leg lunge showed: your  left heel goes up demonstrating weakness in your left calf,  left glute, left hamstrings and left para-spinals.',
      es: 'La vista lateral de la Estocada con la pierna izquierda mostró: el talón izquierdo se levantó del suelo, lo que demuestra una debilidad en la pantorrilla izquierda, el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left calf', 'left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Pantorrilla izquierda', 'Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'side-4',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The side view of the left-leg lunge showed: your right knee moves in front of the right hip demonstrating weakness in your right hip flexors  and anterior core.',
      es: 'La vista lateral de la Estocada con la pierna izquierda mostró: La rodilla derecha se ubicó por delante de la cadera derecha, lo que demuestra una debilidad en los flexores de la cadera derecha y el core anterior.',
    },
    default_muscle: {
      en: ['left calf', 'left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Pantorrilla izquierda', 'Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'side-5',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: '',
      es: '',
    },
    default_muscle: {
      en: ['left calf', 'left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Pantorrilla izquierda', 'Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'back-1',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The back view of the left-leg lunge showed: your right heel turns left demonstrating weakness in your  left glute, left hamstrings, and left para-spinals.',
      es: 'La vista posterior de la Estocada con la pierna izquierda mostró: El talón derecho se inclinó a la izquierda, lo que demuestra una debilidad en el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'back-2',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The back view of the left-leg lunge showed: your right hip goes up demonstrating weakness in your left glute, left hamstrings, and left para-spinals.',
      es: 'La vista posterior de la Estocada con la pierna izquierda mostró: La cadera derecha subió, lo que demuestra una debilidad en el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'back-3',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The back view of the left-leg lunge showed: your right hip goes down demonstrating weakness in your  left glute, left hamstrings, and left para-spinals.',
      es: 'La vista posterior de la Estocada con la pierna izquierda mostró: La cadera derecha descendió, lo que demuestra una debilidad en el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
  {
    name: 'back-4',
    checked: false,
    side: 'left',
    defaultMsg: {
      en: 'The back view of the left-leg lunge showed: your right heel turns right demonstrating weakness in your  left glute, left hamstrings, and left para-spinals.',
      es: 'La vista posterior de la Estocada con la pierna izquierda mostró: El talón derecho se inclinó a la derecha, lo que demuestra una debilidad en el glúteo izquierdo, isquiotibiales izquierdos y paraespinales izquierdos.',
    },
    default_muscle: {
      en: ['left glute', 'left hamstrings', 'left para-spinals'],
      es: ['Glúteo izquierdo', 'Isquiotibiales izquierdos', 'Paraespinales izquierdos'],
    },
  },
];

const eigth = [
  {
    name: 'front-1',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the push-up showed: your hips tilt right or left (unilateral core collapse) demonstrating weakness in your hip flexors and anterior core.',
      es: 'La vista frontal de la Flex/Ext de Brazos mostró: inclinación de cadera hacia la deracha o izquierda (colapso del core unilateral), demostrando una debilidad en los flexores de la cadera y el core anterior.',
    },
    default_muscle: {
      en: ['Hip flexors and anterior core'],
      es: ['Flexores de la Cadera y Core anterior'],
    },
  },
  {
    name: 'front-2',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the push-up showed: your scapulae go up (scapular winging) demonstrating weakness in your shoulder stabilizers during the push.',
      es: 'La vista frontal de la Flex/Ext de Brazos mostró: Las escápulas sobresalieron (escápulas aladas) demostrando una debilidad en los estabilizadores de los hombros durante el empuje.',
    },
    default_muscle: {
      en: ['Shoulder stabilizers during push'],
      es: ['Estabilizadores de los hombros durante el empuje'],
    },
  },
  {
    name: 'side-1',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The side view of the push-up showed: your scapulae go up (scapular winging) demonstrating weakness in your shoulder stabilizers during the push.',
      es: 'La vista lateral de la Flex/Ext de Brazos mostró: Las escápulas sobresalieron (escápulas aladas) demostrando una debilidad en los estabilizadores de los hombros durante el empuje.',
    },
    default_muscle: {
      en: ['Shoulder stabilizers during push'],
      es: ['Estabilizadores de los hombros durante el empuje'],
    },
  },
  {
    name: 'side-2',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The side view of the  push-up showed: your hips go up (hip flexion) demonstrating a weakness in your hip flexors  and anterior core.',
      es: 'La vista lateral de la Flex/Ext de Brazos mostró: las caderas subieron (flexión de caderas), lo que demuestra una debilidad en los flexores de la cadera y el core anterior.',
    },
    default_muscle: {
      en: ['Hip flexors', 'anterior core'],
      es: ['Flexores de la Cadera', 'Core anterior'],
    },
  },
  {
    name: 'side-3',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The side view of the  push-up showed: your knees go down (knee flexion) demonstrating a weakness in your hip flexors  and anterior core.',
      es: 'La vista lateral de la Flex/Ext de Brazos mostró: las rodillas bajaron (flexión de rodilas), lo que demuestra una debilidad en los flexores de la cadera y el core anterior.',
    },
    default_muscle: {
      en: ['Hip flexors', 'anterior core'],
      es: ['Flexores de la Cadera', 'Core anterior'],
    },
  },
  {
    name: 'side-4',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The side view of the  push-up showed: your hips go down (core collapse) demonstrating a weakness in your hip flexors and anterior core.',
      es: 'La vista lateral de la Flex/Ext de Brazos mostró: el core descendió (colapso del core), lo que demuestra una debilidad en los flexores de la cadera y el core anterior.',
    },
    default_muscle: {
      en: ['Hip flexors', 'anterior core'],
      es: ['Flexores de la Cadera', 'Core anterior'],
    },
  },
  {
    name: 'back-1',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The back view of the push-up showed: your hips tilt right or left (unilateral core collapse) demonstrating weakness in your hip flexors and anterior core.',
      es: 'La vista posterior de la Flex/Ext de Brazos mostró: inclinación de cadera hacia la deracha o izquierda (colapso del core unilateral), demostrando una debilidad en los flexores de la cadera y el core anterior.',
    },
    default_muscle: {
      en: ['Hip flexors', 'anterior core'],
      es: ['Flexores de la Cadera', 'Core anterior'],
    },
  },
  {
    name: 'back-2',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The back view of the  push-up showed: your heels turn right or left demonstrating weakness in your hip flexors and anterior core.',
      es: 'La vista posterior de la Flex/Ext de Brazos mostró: los talones se inclinaron a la derecha o la izquierda (pierden la alineación vertical), demostrando una debilidad en los flexores de la cadera y el core anterior.',
    },
    default_muscle: {
      en: ['Hip flexors', 'anterior core'],
      es: ['Flexores de la Cadera', 'Core anterior'],
    },
  },
];
const ninth = [
  {
    name: 'front-1',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the reclined pull showed: your hips tilt right or left (unilateral core collapse) demonstrating asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat',
      es: 'La vista frontal del Remo Reclinado mostró: inclinación de cadera hacia la deracha o izquierda (colapso del core unilateral), demostrando una debilidad asimétrica del core.',
    },
    default_muscle: {
      en: ['shoulder stabilizers during pull'],
      es: ['Estabilizadores de los hombro al halar'],
    },
  },
  {
    name: 'front-2',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the reclined pull showed: your hips tilt right or left (unilateral core collapse) demonstrating asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat',
      es: 'La vista frontal del Remo Reclinado mostró: inclinación de cadera hacia la deracha o izquierda (colapso del core unilateral), demostrando una debilidad asimétrica del core.',
    },
    default_muscle: {
      en: ['Asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat'],
      es: ['Debilidad asimétrica del core posterior confirmada en el Alcance Anterior en 1 Pierna y  Sentadilla a 1 Pierna'],
    },
  },
  {
    name: 'side-1',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The side view of the reclined pull showed: your upper back rounds (scapular protraction) demonstrating a weakness in your shoulder stabilizers during pull and upperback extensors. ',
      es: 'La vista lateral del Remo Reclinado mostró: La espalda alta redondeada (protracción escapular), demostrando una debilidad en los estabilizadores de los hombros al halar y los extensores de la espalda alta.',
    },
    default_muscle: {
      en: ['Shoulder stabilizers during pull', 'upperback extensors'],
      es: ['Estabilizadores de los hombro al halar', 'extensores de la espalda alta'],
    },
  },
  {
    name: 'side-2',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The side view of the reclined pull showed: your hips go down (hip flexion) demonstrating a weakness in your hip flexors, anterior core, as well as your posterior core.',
      es: 'La vista lateral del Remo Reclinado mostró: Las caderas cayeron (flexión de cadera), demostrando una debilidad en los flexores de la cadera,  el core anterior, así como del core posterior.',
    },
    default_muscle: {
      en: ['hip flexors', 'anterior core', 'posterior core'],
      es: ['Flexores de la cadera', 'core anterior', 'core posterior'],
    },
  },
  {
    name: 'side-3',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The side view of the reclined pull showed: your knees go forward (knee flexion) demonstrating a weakness in your hip flexors, anterior core, as well as your posterior core.',
      es: 'La vista lateral del Remo Reclinado mostró: Las rodillas se adelantaron (flexión de rodillas), demostrando una debilidad en los flexores de la cadera,  el core anterior, así como del core posterior.',
    },
    default_muscle: {
      en: ['hip flexors', 'anterior core', 'posterior core'],
      es: ['Flexores de la cadera', 'core anterior', 'core posterior'],
    },
  },
  {
    name: 'back-1',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The back view of the  push-up showed: your left hip goes down demonstrating weakness in your  shoulder stabilizers during pull and upperback extensors.',
      es: 'La vista posterior del Remo Reclinado mostró: La espalda redondeada (protracción escapular), demostrando una debilidad en los estabilizadores de los hombros al halar y los extensores de la espalda alta.',
    },
    default_muscle: {
      en: ['Shoulder stabilizers during pull', 'upperback extensors'],
      es: ['Estabilizadores de los hombros al halar', 'extensores de la espalda alta'],
    },
  },
  {
    name: 'back-2',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The back view of the reclined pull showed: your hips tilt right or left (unilateral core collapse) demonstrating asymmetrical posterior core weakness confirmed in the 1-Leg',
      es: 'La vista posterior del Remo Reclinado mostró: Inclinación de la cadera hacia la derecha o izquierda (colapso del core unilateral), demostrando una debilidad asimétrica del core posterior.',
    },
    default_muscle: {
      en: ['Asymmetrical posterior core weakness confirmed in the 1-Leg Anterior Reach and the 1-Leg Squat'],
      es: ['Debilidad asimétrica del core posterior confirmada en el Alcance Anterior en 1 Pierna y  Sentadilla a 1 Pierna'],
    },
  },
];
const tenth = [
  {
    name: 'front-1',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the right rotation with left foot pivot showed: your right knee rotates out , demonstrating a weakness in your right rotations with your left foot pivoting.',
      es: 'La vista frontal de las Rotaciones hacia la Derecha con Pivote del pie izquierdo mostró: La rodilla derecha rotó hacia afuera, demostrando una debilidad el glúteo derecho.',
    },
    default_muscle: {
      en: ['Right Glute'],
      es: ['Glúteo Derecho'],
    },
  },
  {
    name: 'front-2',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the right rotation with left foot pivot showed: your right foot rotates out , demonstrating a weakness in your right rotations with your left foot pivoting.',
      es: 'La vista frontal de las Rotaciones hacia la Derecha con Pivote del pie izquierdo mostró: El pie derecho rotó hacia afuera, demostrando una debilidad el glúteo derecho.',
    },
    default_muscle: {
      en: ['Right Glute'],
      es: ['Glúteo Derecho'],
    },
  },
  {
    name: 'front-3',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the left rotation with right foot pivots showed: your left knee rotates out , demonstrating a weakness in your left rotations with left foot pivoting.',
      es: 'La vista frontal de las  Rotaciones hacia la izquierda con Pivote del pie derecho mostró: La rodilla derecha rotó hacia afuera, demostrando una debilidad el glúteo izquierdo.',
    },
    default_muscle: {
      en: ['Left Glute'],
      es: ['Glúteo Izquierdo'],
    },
  },
  {
    name: 'front-4',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the left rotation with right foot pivots showed: your left foot rotates out , demonstrating a weakness in your left rotations with your right foot pivoting.',
      es: 'La vista frontal de las  Rotaciones hacia la izquierda con Pivote del pie derecho mostró: El pie derecho rotó hacia afuera, demostrando una debilidad el glúteo izquierdo.',
    },
    default_muscle: {
      en: ['Left Glute'],
      es: ['Glúteo Izquierdo'],
    },
  },
];
const eleventh = [
  {
    name: 'front-1',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the rotations without pivot showed: your hips shake , demonstrating a weakness in your  core stiffness.',
      es: 'La vista frontal de las Rotaciones sin Pivote mostró: Las caderas se sacudieron, demostrando una debilidad en todo el core.',
    },
    default_muscle: {
      en: ['Entire Core'],
      es: ['Todo el core'],
    },
  },
  {
    name: 'front-2',
    checked: false,
    side: 'both',
    defaultMsg: {
      en: 'The front view of the rotations without pivot showed: your legs shake , demonstrating a weakness in your  core stiffness.',
      es: 'La vista frontal de las Rotaciones sin Pivote mostró: Las piernas se movieron, demostrando una debilidad en todo el core.',
    },
    default_muscle: {
      en: ['Entire Core'],
      es: ['Todo el core'],
    },
  },
];

const changeRadioCheckState = (radios, target) => radios
  .map((radio) => (radio.name === target.id
    ? ({ ...radio, checked: !radio.checked })
    : radio));

export {
  first,
  second,
  third,
  fourth,
  fifth,
  sixth,
  seventh,
  eigth,
  ninth,
  tenth,
  eleventh,
  changeRadioCheckState,
};
