import React, { useState } from 'react';
import {
  Flex, Button, Menu, MenuButton, MenuList, MenuItem,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import i18next from 'i18next';
import languages from 'utils/languages';
import { Link } from 'react-router-dom';
import styles from './IconsNav.module.scss';

const IconsNav = () => {
  const [flag, setFlag] = useState('us');
  const onLanguageChange = (code) => {
    setFlag(code === 'en' ? 'us' : code);
    document.querySelector('html').lang = code;
    i18next.changeLanguage(code);
  };
  return (
    <Flex justify="center" className={styles.root}>
      <Flex align="center" width={['80%', '60%']} justify="space-between">
        <Menu>
          <MenuButton as={Button} colorScheme="ptRed" fontSize="20px" rightIcon={<ChevronDownIcon />}>
            <span className={`flag-icon flag-icon-${flag}`} />
            {' '}
            <i className="fas fa-globe" />
          </MenuButton>
          <MenuList zIndex="101">
            {
            languages.map(({ code, name, countryCode }) => (
              <MenuItem key={code} color="ptText.500" onClick={() => onLanguageChange(code)}>
                <span className={`flag-icon flag-icon-${countryCode}`} />
                <span>
                  {name}
                </span>
              </MenuItem>
            ))
          }
          </MenuList>
        </Menu>
        <Flex className={styles.iconsContainer} as="nav">
          <div className={styles.icons}>
            <i className="fab fa-facebook-f" />
          </div>
          <div className={styles.icons}>
            <i className="fab fa-instagram" />
          </div>
          <div className={styles.icons}>
            <i className="fab fa-youtube" />
          </div>
          <div className={styles.icons}>
            <i className="fab fa-twitter" />
          </div>
          <Link to="/help" className={styles.icons}>
            <i className="far fa-question-circle" />
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default IconsNav;
