const getRoutineSetValues = (value) => {
  const regex = /(?:kg|lb)/gi;
  const init = value.split('/')[0] === 'load';
  const load = init ? value.split('/')[0] : value.split(regex)[0];
  const reps = value.split('/')[1];
  const type = init ? 'kg' : value.match(regex)[0];

  return { load, reps, type };
};

export default getRoutineSetValues;
