import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_PROD}/membership/`,
  /* baseURL: 'http://localhost:8100/api/membership/', */
});

const getMembership = async (id) => {
  try {
    const response = await api.get(`/${id}`);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
const createMembership = async (data) => {
  try {
    const response = await api.post('/', data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const deleteMembership = async (idUser) => {
  try {
    const response = await api.delete(`/${idUser}`);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const createCheckoutSession = async (priceId) => {
  console.log({ priceId });
  try {
    const response = await api.post('/create-checkout-session', { priceId });
    return response.data;
  } catch (error) {
    return error.response;
  }
};
const createPortalSession = async (session_id) => {
  console.log({ session_id });
  try {
    const response = await api.post('/create-portal-session', { session_id });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export {
  getMembership,
  createMembership,
  deleteMembership,
  createPortalSession,
  createCheckoutSession,
};
