const initialState = {
  load: '',
  reps: '',
  type: 'kg',
};

const cellReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_VALUE': return action.data;
    default: return state;
  }
};

const addCellValue = (data) => ({ type: 'ADD_VALUE', data });

export default cellReducer;

export {
  addCellValue,
};
