import React from 'react';
import styles from './HomeSlider.module.css';

const HomeSlider = () => (
  <div className={styles.root}>
    <div className={styles.pic} />
  </div>
);

export default HomeSlider;
