/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import DisplayMessage from 'components/common/DisplayMessage';
import { getRecomendationByTrainerId } from 'services/recomendation';
import { useSelector } from 'react-redux';
import {
  Grid, GridItem, Button, Badge,
} from '@chakra-ui/react';
import propTypes from 'prop-types';

import useGetAll from 'hooks/useGetAll';
import useLang from 'hooks/useLang';

const getRecomendationExercises = (exercise, handleClick, position, lang) => (
  <GridItem display="flex" alignItems="center">
    <Button
      id={exercise._id}
      colorScheme="ptRed"
      variant="link"
      size="xs"
      onClick={
        () => handleClick(position.rowIdx, position.columnIdx, exercise.name[lang], exercise._id)
      }
    >
      {`${exercise.name[lang]}`}
    </Button>
  </GridItem>
);

const createRecomendation = ({ exercises }, handleClick, position, lang) => exercises
  .map(({ exerciseId }) => getRecomendationExercises(exerciseId, handleClick, position, lang));

const Recomendation = ({ handleClick, position }) => {
  const { _id } = useSelector(({ user }) => user);
  const {
    data, isLoading, isError, error,
  } = useGetAll('recomendation', _id, getRecomendationByTrainerId);
  const { lang } = useLang();
  const errorObj = error?.message ? JSON.parse(error.message) : { en: 'trying something different', es: 'Intentado algo diferent' };

  if (isLoading) return <DisplayMessage message="loading recomendation" loading />;
  if (isError) return <DisplayMessage message={errorObj[lang]} />;
  return (
    <Grid templateColumns="repeat(2, 45%)" gap={2} autoRows>
      {
        data?.map((category) => createRecomendation(category, handleClick, position, lang))
      }
    </Grid>
  );
};

Recomendation.propTypes = {
  handleClick: propTypes.func.isRequired,
  position: propTypes.shape({}).isRequired,
};

export default Recomendation;
