/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Button,
  AlertDialog, AlertDialogBody, AlertDialogFooter,
  AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
  RadioGroup, Radio,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
// import propTypes from 'prop-types';

import { infoStyle } from 'theme/commonStyles';

import useUpdateSpecial from 'hooks/useUpdateSpecial';
import { updateRoutine } from 'services/customWorkout';
import CustomNumberInput from 'components/common/NumberInput';

const simplifyExercises = (exercises) => exercises
  .map(({ id_exercise, sets }) => (
    {
      id_exercise: id_exercise._id, // pass only the id of the exercise
      sets: sets.map(({ _id, ...rest }) => rest), // delete _id from sets
    }));

const searchLastDayOfWeek = (sessions, weekIdx) => Math.max(...sessions
  .filter(({ week }) => week === weekIdx)
  .map(({ day }) => day));

const searchLastWeek = (sessions) => Math.max(...sessions.map(({ week }) => week));

const copyAcordingType = (type, sessions, exercises, week, customWeek) => {
  // set the basic structure of the data to send to the server
  const minimizedSession = sessions.map((session) => ({ ...session, exercises: simplifyExercises(session.exercises) }));
  const dataToSend = {
    week: 1,
    day: 1,
    exercises: simplifyExercises(exercises),
    notes: { en: '', es: '' },
    completed: false,
  };

  // update day/week of the obj to send to the server according to the type
  if (type === 'current') {
    dataToSend.week = week;
    dataToSend.day = searchLastDayOfWeek(sessions, week) + 1;
  }
  if (type === 'new') {
    dataToSend.week = searchLastWeek(sessions) + 1;
    dataToSend.day = 1;
  }
  if (type === 'custom') {
    dataToSend.week = customWeek;
    dataToSend.day = searchLastDayOfWeek(sessions, customWeek) + 1;
  }

  // first index is the data to send to server and second index the version of the data fro react query
  return [minimizedSession.concat(dataToSend), sessions.concat({ ...dataToSend, exercises })];
};

const CopyAlert = ({
  dialogOpen, handleClose, routine, info,
}) => {
  const [type, setType] = useState('current');
  const [copyDate, setCopyDate] = useState({ day: 1, week: 1 });
  const { t } = useTranslation();
  const routineToSend = {
    sessions: copyAcordingType(type, routine.sessions, info.exercises, info.week, +copyDate.week)[0],
  };
  const mockRoutine = {
    ...routine,
    sessions: copyAcordingType(type, routine.sessions, info.exercises, info.week, +copyDate.week)[1],
  };
  const {
    mutate, isLoading, isError, isSuccess,
  } = useUpdateSpecial('routines', routine._id, mockRoutine, updateRoutine);

  const handleCopy = () => {
    console.log('copying', routineToSend);
    console.log('copying mocked', mockRoutine);
    mutate(routineToSend);
  };
  return (
    <AlertDialog isOpen={dialogOpen} onClose={handleClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            Copy Day
          </AlertDialogHeader>
          {
            isLoading && <AlertDialogBody style={infoStyle}>Copying</AlertDialogBody>
          }
          {
            isError && <AlertDialogBody style={infoStyle}>{t('something_wrong')}</AlertDialogBody>
          }
          {
            isSuccess && <AlertDialogBody style={infoStyle}>Day copied succefully</AlertDialogBody>
          }
          {(!isLoading && !isSuccess) && (
            <>
              <AlertDialogBody>
                <p>
                  Do you want to Update the routine?
                </p>
                <RadioGroup value={type} onChange={setType}>
                  <Radio value="current">Copy on last day of current week</Radio>
                  <Radio value="new">Copy on first day of a new week</Radio>
                  <Radio value="custom">
                    <span style={{ display: 'flex' }}>
                      Copy on last day of week
                      {' '}
                      <CustomNumberInput
                        change={(value) => setCopyDate((prev) => ({ ...prev, week: value }))}
                        value={copyDate.week}
                        min={1}
                        max={searchLastWeek(routine.sessions)}
                      />
                      {/* Day:
                      <CustomNumberInput
                        change={(value) => setCopyDate((prev) => ({ ...prev, day: value }))}
                        value={copyDate.day}
                        min={1}
                        max={searchLastDayOfWeek(routine.sessions, +copyDate.week) + 1}
                      /> */}
                    </span>
                  </Radio>
                </RadioGroup>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={handleClose}>
                  {t('cancel')}
                </Button>
                <Button colorScheme="red" onClick={handleCopy} ml={3}>
                  copy
                </Button>
              </AlertDialogFooter>
            </>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

CopyAlert.propTypes = {};

export default CopyAlert;
