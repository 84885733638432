import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import styles from './Confirmation.module.scss';

const Confirmation = () => {
  const { t } = useTranslation();
  useLayoutEffect(() => {
  }, []);
  return (
    <article className={styles.root}>
      <h3 className={styles.title}>Congratulation</h3>
      <p className={styles.text}>You have succesfully registered</p>
      <p className={styles.text}>Now you can login</p>
      <Button as={Link} to="/login" className={styles.btn}>{t('login')}</Button>
    </article>
  );
};

export default Confirmation;
