const columns = [
  {
    Header: 'Exercise',
    accessor: 'exercise',
  },
  {
    Header: 'Load',
    accessor: 'load',
  },
  {
    Header: 'Sets',
    accessor: 'sets',
  },
  {
    Header: 'reps',
    accessor: 'reps',
  },
  {
    Header: 'Option',
    accessor: 'opt',
  },
];

const data = [
  {
    exercise: 'Traditional Pierna',
    load: '0',
    sets: '3',
    reps: '10',
    opt: 'delete',
    weakness: 1,
    traditional: true,
  },
  {
    exercise: 'Escapular empuje',
    load: '0',
    sets: '3',
    reps: '10',
    opt: 'delete',
    weakness: 3,

  },
  {
    exercise: 'Core anterior',
    load: '0',
    sets: '3',
    reps: '10',
    opt: 'delete',
    weakness: 2,
  },
  {
    exercise: 'Traditional empuje',
    load: '0',
    sets: '3',
    reps: '10',
    opt: 'delete',
    weakness: 3,
    traditional: true,
  },
  {
    exercise: 'Escapular tracción',
    load: '0',
    sets: '3',
    reps: '10',
    opt: 'delete',
    weakness: 4,
  },
  {
    exercise: 'Rigidez core',
    load: '0',
    sets: '3',
    reps: '10',
    opt: 'delete',
    weakness: 6,
  },
  {
    exercise: 'Traditional tracción',
    load: '0',
    sets: '3',
    reps: '10',
    opt: 'delete',
    weakness: 4,
    traditional: true,
  },
  {
    exercise: 'Escapular empuje',
    load: '0',
    sets: '3',
    reps: '10',
    opt: 'delete',
    weakness: 3,
  },
  {
    exercise: 'Rotadores externos cadera',
    load: '0',
    sets: '3',
    reps: '10',
    opt: 'delete',
    weakness: 5,
  },
];

const initialState = {
  columns,
  data,
};

const hybridReducer = (state, action) => {
  const {
    type, rowIdx, columnIdx, value, exerciseIdx,
  } = action;
  switch (type) {
    case 'INIT_FUNCTIONAL': return initialState;
    case 'DELETE_ROW':
      return {
        ...state,
        data: state.data.filter((row, index) => index !== +value),
      };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: state.data.map((row, index) => {
          if (index === rowIdx) {
            return {
              ...state.data[rowIdx],
              [columnIdx]: exerciseIdx ? `${value}*${exerciseIdx}` : value,
            };
          }
          return row;
        }),
      };
    default: return state;
  }
};

const deleteRow = (value) => ({ type: 'DELETE_ROW', value });
const updateData = (value, rowIdx, columnIdx, exerciseIdx) => ({
  type: 'UPDATE_DATA', value, rowIdx, columnIdx, exerciseIdx,
});

export default hybridReducer;

export {
  initialState,
  deleteRow,
  updateData,
};
