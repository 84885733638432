import React from 'react';
import styles from './Principal.module.css';

const Principal = () => (
  <>
    <div className={styles.container}>
      <div className={styles.pics} />
      <article className={styles.article}>
        <h4 className={styles.title}>
          PERFECT
          <br />
          TRAINER
          <sup className={styles.trade}> &trade;</sup>
        </h4>
        <p className={styles.text}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut laboriosam sapiente
          officiis odit ducimus cupiditate, quia error veniam debitis nostrum, animi cum.
          Voluptatibus repellat placeat numquam
        </p>
      </article>
      <div className={styles.pics} />
      <div className={styles.pics} />
    </div>
  </>
);

export default Principal;
