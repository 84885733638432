/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, {
  useState, useReducer, useRef, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  useDisclosure, Button, Heading, Text, Flex, Textarea,
} from '@chakra-ui/react';
import {
  shape, string, number, bool,
} from 'prop-types';

import useLang from 'hooks/useLang';
import getRoutineSetValues from 'utils/getRoutineSetValues';
import { updateDay } from 'reducers/weekReducer.reducer';
import { addCellValue } from 'reducers/cell.reducer';
import routineReducer, {
  initialState, addRow, deleteRow, predefineRoutine,
} from '../routineReducer';

import ExerciseOverlay from '../Features/ExerciseOverlay';
import SetOverlay from '../Features/SetOverlay';
import TableDisplay from '../../TableDisplay';

const Custom = ({
  data, dayDeleted, date, actual, preData,
}) => {
  const [state, dispatch] = useReducer(routineReducer, initialState);
  const [daySaved, toggleDay] = useReducer((prev) => !prev, false);
  const [hideText, toggleHide] = useReducer((prev) => !prev, true);
  const [position, setPosition] = useState({ rowIdx: null, columnIdx: null });
  const [note, setNote] = useState(preData?.notes || '');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t, lang } = useLang();
  const reduxDispatch = useDispatch();
  const finalRef = useRef();

  const handleOpen = (rowIdx, columnIdx, value) => {
    const valueGotten = value?.en || value;
    console.log('hanldeOpen value', valueGotten);
    if (columnIdx !== 'exercise') reduxDispatch(addCellValue(getRoutineSetValues(valueGotten)));
    setPosition({ rowIdx, columnIdx });
    onOpen();
  };

  const handleSave = () => {
    reduxDispatch(updateDay(state.data, date.week, date.day));
    reduxDispatch(updateDay(note, date.week, date.day, 'notes'));
  };
  const handleAdd = () => {
    dispatch(addRow());
    toggleDay();
  };
  const handleDelete = (idx) => {
    dispatch(deleteRow(idx));
    toggleDay();
  };

  useEffect(() => {
    let deleteTimer = '';
    console.log('custom data', data);
    console.log('pre data', preData);
    if (preData) dispatch(predefineRoutine(preData.exercises, lang));
    deleteTimer = setTimeout(toggleDay, 0);
    return () => clearTimeout(deleteTimer);
  }, []);

  useEffect(() => {
    handleSave();
  }, [daySaved]);

  return (
    <div style={{ display: (date.day === actual.day && date.week === actual.week) ? 'block' : 'none' }}>
      <Flex justifyContent="space-around" mb="1rem">
        <Heading>
          {t('workout_title', { day: date.day, week: date.week })}
        </Heading>

        <Button onClick={handleAdd} colorScheme="ptRed" variant="outline">
          {t('add_row')}
        </Button>
        <Button onClick={toggleHide} colorScheme="ptRed" variant="outline">
          {hideText ? t('add_note') : t('hide_note')}
        </Button>
      </Flex>

      <TableDisplay
        columns={state.columns}
        data={state.data}
        handleOpen={handleOpen}
        handleDelete={handleDelete}
        ref={finalRef}
        position={position}
      />

      {
        (position.columnIdx === 'exercise' && isOpen) && (
          <ExerciseOverlay
            isOpen={isOpen}
            onClose={onClose}
            position={position}
            dispatch={dispatch}
            final={finalRef}
            save={toggleDay}
          />
        )
      }

      {
        (position.columnIdx !== 'exercise' && isOpen) && (
          <SetOverlay
            isOpen={isOpen}
            onClose={onClose}
            position={position}
            dispatch={dispatch}
            final={finalRef}
            save={toggleDay}
          />
        )
      }
      {
        !hideText && (
          <Textarea
            placeholder="notes"
            value={note}
            onChange={({ target }) => setNote(target.value)}
            onBlur={toggleDay}
            resize="none"
          />
        )
      }

    </div>
  );
};

Custom.propTypes = {
  dayDeleted: bool.isRequired,
  date: shape({
    day: number.isRequired,
    week: number.isRequired,
  }).isRequired,
  actual: shape({
    day: number.isRequired,
    week: number.isRequired,
  }).isRequired,
  cell: shape({
    value: string.isRequired,
    row: shape({
      index: number.isRequired,
    }).isRequired,
    column: shape({
      id: number.isRequired,
    }).isRequired,
  }).isRequired,
  data: shape([]).isRequired,
  preData: shape({
    exercises: shape([]).isRequired,
    notes: string.isRequired,
  }).isRequired,
};

export default Custom;
