const columns = [
  {
    Header: 'Exercise',
    accessor: 'exercise',
  },
  {
    Header: 'Set 1',
    accessor: 'set1',
  },
  {
    Header: 'Set 2',
    accessor: 'set2',
  },
  {
    Header: 'Set 3',
    accessor: 'set3',
  },
  {
    Header: 'Set 4',
    accessor: 'set4',
  },
];

const data = [
  {
    exercise: 'Add exercise',
    set1: 'load/reps',
    set2: 'load/reps',
    set3: 'load/reps',
    set4: 'load/reps',
  },
];

const initialState = {
  columns,
  data,
};

const customReducer = (state = initialState, action) => {
  const {
    type, rowIdx, columnIdx, value, exerciseIdx,
  } = action;
  switch (type) {
    case 'ADD_CUSTOM_ROW': return {
      ...state,
      data: [
        ...state.data, {
          exercise: 'Add exercise',
          set1: 'load/reps',
          set2: 'load/reps',
          set3: 'load/reps',
          set4: 'load/reps',
        },
      ],
    };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: state.data.map((row, index) => {
          if (index === rowIdx) {
            return {
              ...state.data[rowIdx],
              [columnIdx]: exerciseIdx ? `${value}-${exerciseIdx}` : value,
            };
          }
          return row;
        }),
      };
    default: return state;
  }
};

const addCustomRow = () => ({ type: 'ADD_CUSTOM_ROW' });

const updateCustomData = (value, rowIdx, columnIdx, exerciseIdx) => ({
  type: 'UPDATE_DATA', value, rowIdx, columnIdx, exerciseIdx,
});

export default customReducer;
export {
  updateCustomData,
  addCustomRow,
};
