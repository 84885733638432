/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Input } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import notificationDisplay from 'utils/notificationDisplay';
import { loaded, loading } from 'reducers/loader.reducer';

import { inputStyle } from 'theme/commonStyles';
import styles from './changePass.module.scss';
import PasswordInput from '../../common/PasswordInput';

const baseURL = `${process.env.REACT_APP_API_URL_PROD}/`;

const MySwal = withReactContent(Swal);

const sendEmail = (email, history) => fetch((`${baseURL}email/recoverypass/${email}`), { method: 'POST' })
  .then((res) => {
    if (res.status === 200) {
      MySwal.fire({
        title: <p>Correo enviado!</p>,
        text: 'Revisa tu correo para continuar con el proceso',
        icon: 'success',
        showConfirmButton: true,
        confirmButtonColor: '#b42519',
      }).then(() => history.push('/login'));
    }
  }).catch((err) => {
    MySwal.fire({
      title: <p>Error!</p>,
      icon: 'error',
      text: err,
      showConfirmButton: true,
      confirmButtonColor: '#b42519',
    });
  });
const savePassword = async (password, token, history) => {
  const data = { token, newPassword: password };
  await fetch((`${baseURL}user/password`), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status === 200) {
        MySwal.fire({
          title: <p>Contraseña cambiada!</p>,
          showConfirmButton: true,
          confirmButtonColor: '#b42519',
        }).then(() => history.push('/login'));
      }
    }).catch((err) => {
      MySwal.fire({
        title: <p>Error!</p>,
        icon: 'error',
        text: err,
        showConfirmButton: true,
        confirmButtonColor: '#b42519',
      });
    });
};

const ChangePass = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [token, setToken] = useState(null);
  const { search } = useLocation();
  useEffect(() => {
    setToken(new URLSearchParams(search).get('token') ?? null);
  });
  const { t } = useTranslation();
  const onSubmit = async (data) => {
    dispatch(loading());
    try {
      if (token) {
        if (data.password === data.password1) {
          await savePassword(data.password, token, history);
        } else notificationDisplay(dispatch, "Passwords don't match", 'error');
      } else {
        await sendEmail(data.email, history);
      }
    } catch (exception) {
      notificationDisplay(dispatch, exception.message, 'error');
    }
    dispatch(loaded());
  };

  return (
    <section className={styles.root}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.sides}>
        {token ? (
          <>
            <h4 className={`${styles.title} ${styles.left}`}>{t('new_password')}</h4>
            <Controller
              control={control}
              defaultValue=""
              name="password"
              rules={{ required: true, minLength: 4, maxLength: 12 }}
              render={({ field }) => (
                <PasswordInput field={field} placeholder={t('password')} />
              )}
            />
            {errors.password?.type === 'required' && (
              <span className={styles.message}>{t('error_required')}</span>
            )}
            {errors.password?.type === 'minLength' && (
              <span className={styles.message}>{t('error_min', { num: 4 })}</span>
            )}
            {errors.password?.type === 'maxLength' && (
              <span className={styles.message}>{t('error_max', { num: 12 })}</span>
            )}
            <Controller
              control={control}
              defaultValue=""
              name="password1"
              rules={{ required: true, minLength: 4, maxLength: 12 }}
              render={({ field }) => (
                <PasswordInput field={field} placeholder={t('repeat_password')} />
              )}
            />
            {errors.password?.type === 'required' && (
              <span className={styles.message}>{t('error_required')}</span>
            )}
            {errors.password?.type === 'minLength' && (
              <span className={styles.message}>{t('error_min', { num: 4 })}</span>
            )}
            {errors.password?.type === 'maxLength' && (
              <span className={styles.message}>{t('error_max', { num: 12 })}</span>
            )}
          </>
        ) : (
          <>
            <h4 className={`${styles.title} ${styles.left}`}>{t('recover_pass')}</h4>
            <Controller
              control={control}
              defaultValue=""
              name="email"
              rules={{
                required: true,
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  {...inputStyle}
                  placeholder={t('email')}
                  mb="0"
                />
              )}
            />
            {errors.email?.type === 'required' && (
            <span className={styles.message}>{t('error_required')}</span>
            )}
            {errors.email?.type === 'pattern' && (
            <span className={styles.message}>{t('error_email')}</span>
            )}
          </>
        )}

        <button type="submit" className={styles.submit}>
          {token ? t('save') : t('send_email') }
        </button>
      </form>
    </section>
  );
};

export default ChangePass;
