import { useTranslation } from 'react-i18next';

const localizationMaterialTable = () => {
  const { t } = useTranslation();
  const localization = {
    pagination: {
      labelRowsSelect: t('table_rows'),
    },
    toolbar: {
      searchPlaceholder: t('search'),
    },
    body: {
      emptyDataSourceMessage: t('no_data_table'),
    },
  };
  return localization;
};

export default localizationMaterialTable;

// Documentation 2/23/2022: https://material-table.com/#/docs/features/localization
