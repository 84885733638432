import React from 'react';

import { bigContainerStyle } from 'theme/commonStyles';

import Board from './Board';
import Search from './Search';

const Clients = () => (
  <main style={bigContainerStyle}>
    <Search />
    <Board />
  </main>
);

export default Clients;
