import React, { memo } from 'react';
import { Badge } from '@chakra-ui/react';
import { string } from 'prop-types';

const InfoLine = memo(({ label, text }) => (
  <p>
    <Badge colorScheme="yellow" variant="solid" data-testid="label">
      {label}
    </Badge>
    {' '}
    {text}
  </p>
));

InfoLine.propTypes = {
  label: string.isRequired,
  text: string.isRequired,
};

export default InfoLine;
