/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es';
import propTypes from 'prop-types';
// import { Navbar } from '../ui/Navbar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import UpdateEventFab from 'components/ui/UpdateEventFab';
import messages from '../../helpers/calendar-message-es';
import CalendarEvent from './CalendarEvent';
import CalendarModal from './CalendarModal';
import { uiOpenModal } from '../../actions/ui';
import {
  eventClearActiveEvent,
  eventSetActive,
  eventSetSlotEmpty,
  eventStartLoading,
} from '../../actions/events';
import AddNewFab from '../ui/AddNewFab';
import DeleteEventFab from '../ui/DeleteEventFab';

moment.locale('es');
const localizer = momentLocalizer(moment);

const CalendarScreen = ({ category = 'all' }) => {
  let viewCategoryType = '';
  if (category !== 'all') {
    // console.log('category', category);
    localStorage.setItem('lastView', '');
    viewCategoryType = { [category]: true };
  }
  const dispatch = useDispatch();

  // TODO : Leer del store los eventos
  const { events, activeEvent } = useSelector((state) => state.calendar);
  const { id_user } = useSelector((state) => state.user);
  const [lastView, setLastView] = useState(
    localStorage.getItem('lastView') || (category === 'all' ? 'month' : category),
  );

  useEffect(() => {
    dispatch(eventStartLoading(category));
  }, [dispatch, category]);

  const onDoubleClick = () => {
    dispatch(uiOpenModal());
  };

  const onSelectEvent = (e) => {
    dispatch(eventSetActive(e));
  };
  const onSelectSlot = (e) => {
    console.log(e);
    dispatch(uiOpenModal());
    dispatch(eventClearActiveEvent());
    dispatch(eventSetSlotEmpty(e));
  };

  const onViewChange = (e) => {
    setLastView(e);
    localStorage.setItem('lastView', e);
  };
  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      // backgroundColor: id_user === event.user._id ? '#367cf7' : '#465660',
      backgroundColor: '#367cf7',
      borderRadius: '0px',
      opacity: 0.8,
      display: 'block',
      color: 'white',
    };
    return {
      style,
    };
  };
  return (
    <div className="calendar-screen">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        messages={messages}
        eventPropGetter={eventStyleGetter}
        onDoubleClickEvent={onDoubleClick}
        onSelectEvent={onSelectEvent}
        onView={onViewChange}
        onSelectSlot={onSelectSlot}
        selectable
        view={lastView}
        views={category !== 'all' ? viewCategoryType : ['month', 'day', 'week', 'agenda']}
        components={{
          event: CalendarEvent,
        }}
      />
      <AddNewFab />

      {activeEvent && <DeleteEventFab /> }
      {activeEvent && <UpdateEventFab />}
      <CalendarModal category={category} />
    </div>
  );
};

CalendarScreen.defaultProps = {
  category: 'all',
};
CalendarScreen.propTypes = {
  category: propTypes.string,
};

export default CalendarScreen;
